import React, { useState } from "react";
import KendoButton from "../../common/KendoButton";
import { SAVE_POST_PITUTRES, SAVE_POSTS } from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconComponent } from "../../common/Icon";
import { Loader } from "@progress/kendo-react-indicators";
import ProductInput from "../../common/ProductInput";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { toast } from "react-toastify";

const AddNewProductImages = ({
  showForm,
  onClose,
  getObjectForApiCall,
  fetchProductImages,
}) => {
  const { productID } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [productImage, setProductImage] = useState({
    thumbImageUrl: null,
  });

  const onImageChange = (e) => {
    if (e?.target?.files?.length > 0) {
      const file = e.target.files[0];
      setProductImage((prevState) => ({
        ...prevState,
        thumbImageUrl: file,
      }));
      setIsFormValid(true);
    }
    e.target.value = "";
  };

  const onRemoveImage = () => {
    setProductImage((prevState) => ({
      ...prevState,
      thumbImageUrl: null,
    }));
    setIsFormValid(false);
  };

  const isCheck = (thumbImageUrl) => {
    if (thumbImageUrl instanceof File) {
      return URL.createObjectURL(thumbImageUrl);
    }
    return thumbImageUrl;
  };
  const onSelectProductImage = () => {
    document.getElementById("product-image").click();
  };
  const saveProduct = async () => {
    setSubmitLoader(true);
    try {
      const fd = new FormData();
      const obj = {
        UserId: loginData.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            FormatTypeId: 1,
            PostedByUserId: loginData.userId,
            AppId: 4,
            AppRecordId: productID,
            Message: "pictures_for_products",
            ModifiedBy: loginData.userId,
          },
        ],
      };
      await PostRequestCall(SAVE_POSTS, obj, loginData?.token)
        .then(async (res) => {
          let postID = res?.data?.data?.[0]?.objData?.id ?? null;
          if (postID) {
            fd.append("Id", 0);
            fd.append("postId", postID);
            fd.append("Pictures", productImage?.thumbImageUrl);
            fd.append("ModifiedBy", loginData.userId);

            let res = await PostRequestCall(
              SAVE_POST_PITUTRES,
              fd,
              loginData?.token,
              "formData"
            );
            if (res?.data?.status === false) {
              let errorMessage = res?.data?.errorMessage
                ? res?.data?.errorMessage
                : res?.data?.error
                ? res?.data?.error
                : "Something went wrong. Please try again later.";
              toast.error(errorMessage, {
                position: "top-right",
              });
            } else {
              toast.success(`Data added successfully`, {
                position: "top-right",
              });
              onClose();
              fetchProductImages(getObjectForApiCall());
            }
          } else {
            let errorMessage = res?.data?.data?.[0]?.message
              ? res?.data?.data?.[0]?.message
              : res?.data?.error
              ? res?.data?.error
              : "Something went wrong. Please try again later.";
            toast.error(errorMessage, {
              position: "top-right",
            });
          }
        })
        .catch((error) => {
          let errorMessage = error?.data?.errorMessage
            ? error?.data?.errorMessage
            : error?.data?.error
            ? error?.data?.error
            : "Something went wrong. Please try again later.";
          toast.error(errorMessage, {
            position: "top-right",
          });
          console.log("Error while saving product :: ", error);
        });
    } catch (error) {
      setSubmitLoader(false);
      console.log("Error while saving product ::", error);
    }
  };
  const handleClose = () => {
    onClose();
  };
  if (!showForm) return null;

  return (
    <div style={overlayStyles.overlay}>
      <div style={overlayStyles.overlayContent}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconComponent size={25} iconName="X" onClick={handleClose} />
        </div>
        <h3>Add New Product Picture</h3>
        <ProductInput boldLabel={true} disabled productId={productID} />
        <div style={overlayStyles.formGroup}>
          <p>
            <b>Add Product Image:</b>
          </p>
          <input
            type="file"
            hidden
            accept=".png,.jpg"
            id="product-image"
            style={{ display: "none" }}
            onChange={(e) => {
              onImageChange(e);
            }}
          />
          {productImage?.thumbImageUrl ? (
            <div className="k-d-flex">
              <div style={{ position: "relative" }}>
                <img
                  className="user-img"
                  src={isCheck(productImage?.thumbImageUrl)}
                  alt="Ad"
                />
                <button
                  onClick={onRemoveImage}
                  style={{
                    position: "absolute",
                    top: "6px",
                    right: "8px",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}>
                  <span>X</span>
                </button>
              </div>
            </div>
          ) : (
            <>
              <GridLayoutItem col={2} row={1} className="form-inner">
                <div
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    border: "0.5px solid #d3d3d3",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "4px",
                    gap: "10px",
                  }}
                  onClick={onSelectProductImage}>
                  <IconComponent iconName={"Image"} size={20} />
                  <span className="add-file-text">Choose image</span>
                </div>
              </GridLayoutItem>
            </>
          )}
        </div>
        <div style={overlayStyles.actions}>
          {submitLoader ? (
            <div
              className="action-btn-loader"
              style={{ marginLeft: "auto", width: "30px", height: "20px" }}>
              <Loader type={"pulsing"} themeColor="white" size="small" />
            </div>
          ) : (
            <KendoButton
              iconClass=""
              size={16}
              className="action-btn"
              buttonName="Save"
              disabled={!isFormValid}
              onClick={saveProduct}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewProductImages;

const overlayStyles = {
  overlay: {
    position: "fixed",
    top: "67px",
    right: 0,
    width: "400px",
    height: "100%",
    background: "white",
    boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
    transform: "translateX(0)",
    transition: "transform 0.3s ease-in-out",
    borderRadius: "10px 0 0 10px",
  },
  overlayContent: {
    padding: "20px",
  },

  formGroup: {
    marginTop: "15px",
  },
  actions: {
    marginTop: "20px",
    textAlign: "right",
  },
};
