import React, { useEffect, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_CONTACT_EVENTS,
  FETCH_CONTACT_EVENT,
  FETCH_CONTACT_EVENT_STATUSES,
  FETCH_RECORD_STATUSES,
  FetchUsers2,
  SAVE_CONTACT_EVENTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { toast } from 'react-toastify';
import { Loader } from "@progress/kendo-react-indicators";

const AddNewContactEvents = () => {
  const { dataId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);

  const [addContactEvents, setAddContactEvents] = useState({
    userName: "",
    userId: 0,
    recordStatus: "New",
    contactEmail: "",
    recordStatusId: 1,
    eventStatusName: "Select Event Status",
    eventStatusId: -1
  });

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [contactStatusList, setContactEventStatusOptions] = useState([]);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);


  useEffect(() => {
    if (dataId !== "add-contact-event") {
      loadContactEvents();
    }
    getRecordStatuses();
    getContactEventStatuses();
    if (userId) {
      getUserDetails();
    }
  }, []);


  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const getContactEventStatuses = async () => {
    try {
      let contactEventStatusesObj = {
        "PageNr": 1,
        "NrOfRecPerPage": 100,
        "FullSearch": "",
        "UserId": "1",
        "SortList": [
          {
            "FieldName": "Id",
            "Direction": "ASC"
          }
        ],
        "IncludeRecordNr": true
      }
      const contactEventStatusesRes = await PostRequestCall(
        FETCH_CONTACT_EVENT_STATUSES,
        contactEventStatusesObj,
        loginData?.token
      );
      let contactEventStatusesData = contactEventStatusesRes?.data?.data?.map((record) => (
        {
          id: record?.id, text: record?.name
        }
      ))
      setContactEventStatusOptions((prevData) => ([...contactEventStatusesData, { id: -1, text: "Select Event Status" }] ?? []));
    } catch (error) {
      console.log("Error while getting contact event statuses :: ", error);
    }
  }

  const loadContactEvents = async () => {
    if (dataId !== "add-contact-event") {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            Id: dataId,
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_CONTACT_EVENT,
        obj,
        loginData?.token
      );
      const data = result?.data?.data?.[0] ?? null;
      setAddContactEvents((prevData) => (
        {
          ...prevData,
          userName: data?.userName,
          userId: data?.userId,
          recordStatus: data?.recordStatus,
          contactEmail: data?.contactEmail,
          recordStatusId: data?.recordStatusId,
          eventStatusName: data?.eventStatusName ?? "Select Event Status",
          eventStatusId: data?.eventStatusId ?? -1
        }
      ))
    }
  };

  const getUserDetails = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FetchUsers2,
        data,
        loginData?.token
      );
      setAddContactEvents((prevData) => ({ ...prevData, userName: result?.data?.data?.[0]?.name, userId }));
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const srchList = [{ Name: value }];
        const data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: value,
          UserId: loginData.userId,
          SearchList: srchList,
          IncludeRecordNr: true,
        });

        const result = await PostRequestCall(
          FetchUsers2,
          data,
          loginData?.token
        );
        const users = result.data.data || [];

        const userOptions = users.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));

        setOptions(userOptions);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (value.trim() !== "") {
      fetchData();
    } else {
      setOptions([]);
    }
  }, [value]);


  const onInputChange = (e) => {
    const { name, value } = e.target;
    setAddContactEvents({ ...addContactEvents, [name]: value });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onCancelClick = () => {
    window.history.back();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const firstOptionValue = options.length > 0 ? options[0].value : null;

    if (addContactEvents?.userId === 0) {
      if (!firstOptionValue) {
        return toast.error("Please select a user", {
          position: "top-right",
        });
      }
    }

    if (addContactEvents.contactEmail === "" || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(addContactEvents.contactEmail)) {
      return toast.error("Please enter valid email addresses", {
        position: "top-right",
      });
    }

    if (addContactEvents?.eventStatusId === -1) {
      return toast.error("Please select a event status", {
        position: "top-right",
      });
    }

    const obj = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData.userId,
      SaveList: [
        {
          Id: dataId === "add-contact-event" ? 0 : dataId,
          UserId: addContactEvents.userId || firstOptionValue,
          RecordStatusId: addContactEvents?.recordStatusId || 1,
          EventStatusId: addContactEvents?.eventStatusId,
          ContactEmail: addContactEvents?.contactEmail || "",
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      setShowLoader(true);
      const result = await PostRequestCall(
        SAVE_CONTACT_EVENTS,
        obj,
        loginData?.token
      );
      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(result?.data?.data?.[0].message ?? "Something went wrong. Please try again later.", {
          position: "top-right",
        });
      } else {
        setShowLoader(false);
        toast.success(`Data ${dataId !== "add-contact-event" ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }


  const onDeleteClick = async (e) => {
    try {
      e.stopPropagation();
      if (window.confirm("Are you sure you wish to delete this record?")) {
        setShowLoader(true);
        const deleteObj = [
          {
            Id: Number(dataId),
            ModifiedBy: loginData?.userId,
          }
        ]
        return PostRequestCall(DELETE_CONTACT_EVENTS, deleteObj, loginData?.token).then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(res?.data?.message ?? "Something went wrong. Please try again later.", {
              position: "top-right",
            });
          }
        }).catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        })
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              // height: 20,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          className="layout"
          style={{ padding: "25px 16px", gap: "0px 35px" }}
        >
          <GridLayoutItem col={1} row={1} className="form-inner">
            <label className="form-heading">User:</label>
            <div className="form-control">
              {dataId !== "add-contact-event" || subMenu ? (
                <AutoComplete
                  data={options}
                  className="field-ip"
                  value={addContactEvents?.userName}
                  textField="label"
                  placeholder="Search for users..."
                  disabled
                />
              ) : (
                <AutoComplete
                  data={options}
                  value={value}
                  className="field-ip"
                  onChange={handleChange}
                  textField="label"
                  placeholder="Search for users..."
                />
              )}
            </div>
          </GridLayoutItem>

          <GridLayoutItem col={2} row={1} className="form-inner">
            <div className="form-group">
              <Label className="form-heading">Event Status :</Label>
              <DropDownList
                className="dropdown-list"
                placeholder="Select Event Status"
                style={{ width: "370px" }}
                onChange={(e) => {
                  setAddContactEvents((preValue) => ({
                    ...preValue,
                    eventStatusName: e.value?.text,
                    eventStatusId: e.value?.id
                  }));
                }}
                value={{
                  value: addContactEvents?.eventStatusId,
                  text: addContactEvents?.eventStatusName
                }}
                data={contactStatusList}
                textField="text"
                dataItemKey="text"
              />
            </div>
          </GridLayoutItem>

          <GridLayoutItem col={3} row={1} className="form-inner">
            <label className="form-heading">Conatct Email :</label>
            <div className="form-control">
              <Input
                type="email"
                id="contactEmail"
                name="contactEmail"
                value={addContactEvents?.contactEmail}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>

          {dataId !== "add-contact-event" || subMenu ? (
            <GridLayoutItem col={1} row={2} className="form-inner">
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setAddContactEvents((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{ id: addContactEvents?.recordStatusId, text: addContactEvents?.recordStatus }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          )
            : (
              <></>
            )}

          <GridLayoutItem col={3} row={4} className="form-inner">
            <div
              className="k-d-flex k-justify-content-end"
              style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />
              {
                dataId !== "add-contact-event" && (
                  <KendoButton
                    size={16}
                    className="action-btn delete-btn"
                    onClick={onDeleteClick}
                    buttonName="Delete"
                  />
                )
              }
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      </section>
    </section>
  );
};

export default AddNewContactEvents;
