import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_TRADES,
  FETCH_RECORD_STATUSES,
  FETCH_TRADES,
  SAVE_TRADES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../../common/Modal";
import { Form } from "@progress/kendo-react-form";
import Footerinformation from "../../common/Footerinformation";
import { IconComponent } from "../../common/Icon";


const AddMiscellaneTrades = () => {

  const { tradeId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = Number(tradeId ?? 0) === 0;
  let navigate = useNavigate();


  const [trade, setTrade] = useState({
    name: "",
    description: "",
    recordStatus: "New",
    recordStatusId: 1,
    pictureUrl: null,
    prevousImageUrl: null,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      loadTradeData();
    }
    getRecordStatuses();
  }, []);

  const loadTradeData = async () => {
    try {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          {
            Id: tradeId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_TRADES,
        obj,
        loginData?.token
      );
      let data = result?.data?.data?.[0] ?? null;
      setTrade((prevData) => ({
        ...prevData,
        ...data,
        pictureUrl: data?.pictureUrl === "" || data?.tradePictureUrl === null ? null : data?.tradePictureUrl,
        prevousImageUrl: data?.pictureUrl === "" || data?.tradePictureUrl === null ? null : data?.tradePictureUrl
      }));
    } catch (error) {
      console.log("Error while getting tarde data :: ", error)
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const onInputChange = (e, type) => {
    let inputValue = e.target.value;
    if (type === "number") {
      inputValue = inputValue.replace(/\D/g, "");
    }
    setIsFiledEdited(true);
    setTrade({ ...trade, [e.target.name]: inputValue });
  };

  const onSelectImage = (e) => {
    const file = e?.target?.files?.[0] ?? null
    if (file) {
      setTrade((preValue) => ({ ...preValue, pictureUrl: file }));
    }
    // else{
    //   e.target.value = "";
    // }
  };

  const checkImageUrl = (pictureUrl) => {
    try {
      if (Object.keys(pictureUrl)?.length === 0) {
        return URL.createObjectURL(pictureUrl);
      } else {
        return pictureUrl;
      }
    } catch (error) {
      console.log("Error while getting front picture url ::", error);
    }
  };

  const onRemoveImg = () => {
    setIsFiledEdited(true);
    setTrade((preValue) => ({ ...preValue, pictureUrl: null }));
  };

  const onChooseImageClick = (e) => {
    e.preventDefault();
    setIsFiledEdited(true);
    document.getElementById("add_trade_picture").click();
  };


  const saveCurrentData = async () => {

    if (trade?.name?.trim() === "") {
      return toast.error("Please enter trade name", {
        position: "top-right",
      });
    }

    const getRequest = new FormData();
    setShowLoader(true);
    if (isAddForm) {
      getRequest.append("Id", 0);
    } else {
      getRequest.append("Id", tradeId);
    }


    getRequest.append("Name", trade?.name || "");
    getRequest.append("Description", trade?.description || "");
    getRequest.append("RecordStatusId", trade?.recordStatusId || 1);
    getRequest.append("ModifiedBy", loginData?.userId);


    if (trade?.pictureUrl) {
      if (trade?.fileName !== trade?.prevousImageUrl) {
        getRequest.append("files", trade?.pictureUrl);
      }
    } else {
      getRequest.append("ForceRemovePicture", true);
    }

    let getResponse = await PostRequestCall(
      SAVE_TRADES,
      getRequest,
      loginData?.token,
      "formData"
    );

    if (getResponse?.data?.status === false) {
      setShowLoader(false);
      toast.error(
        getResponse?.data?.message ??
        "Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
    } else {
      setShowLoader(false);
      toast.success(
        `Data ${!isAddForm ? "updated" : "added"} successfully`,
        {
          position: "top-right",
        }
      );
      navigate("/admin/trades/trades");
    }
  };



  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: tradeId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(DELETE_TRADES, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            navigate("/admin/trades/trades");
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return navigate("/admin/trades/trades");
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      saveCurrentData();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    navigate("/admin/trades/trades");

  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {!isAddForm && (
              <span class="record-id-text">Trade ID : {tradeId}</span>
            )}
          </div>
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className=""
              themeColor={"primary"}
              buttonName="Save"
              onClick={saveCurrentData}
            />
            {!isAddForm && (
              <KendoButton
                size={16}
                className=""
                onClick={showDeleteConfirmation}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #f5f5f5",
            width: "100%",
          }}></div>

        <Form
          render={(formRenderProps) => (
            <GridLayout
              rows={[
                {
                  height: 70,
                },
                {
                  height: 70,
                },
                {
                  height: 250,
                },
                {
                  height: 40,
                },
              ]}
              cols={[
                {
                  width: 375,
                },
                {
                  width: 375,
                },
                {
                  width: 375,
                },
              ]}
              gap={{
                rows: 30,
                cols: 35,
              }}
              style={{ padding: "16px" }}>
              <GridLayoutItem col={1} row={1} className="form-inner">
                <Label className="form-heading"> Name :</Label>
                <Input
                  name="name"
                  value={trade?.name}
                  className="field-ip"
                  onChange={(e) => onInputChange(e)}
                />
              </GridLayoutItem>
              <GridLayoutItem col={2} row={1} className="form-inner">
                <Label className="form-heading">Description :</Label>
                <TextArea
                  type="text"
                  id="description"
                  name="description"
                  value={trade?.description}
                  className="field-ip"
                  onChange={(e) => onInputChange(e)}
                  rows={6}
                  style={{ resize: 'vertical' }}
                />
              </GridLayoutItem>

              <GridLayoutItem col={3} row={1} className="form-inner">
                <Label className="form-heading">  Picture :</Label>
                <div className="k-d-flex" style={{ gap: "15px" }}>
                  <input
                    type="file"
                    hidden
                    accept=".png,.jpg"
                    id="add_trade_picture"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      onSelectImage(e);
                    }}
                  />
                  {trade?.pictureUrl ? (
                    <div className="k-d-flex">
                      <div style={{ position: "relative" }}>
                        <img
                          className="user-img"
                          src={checkImageUrl(trade?.pictureUrl)}
                          alt="trade_picture"
                        />
                        <button
                          onClick={onRemoveImg}
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}>
                          <span>X</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <GridLayoutItem
                        col={2}
                        row={1}
                        className="form-inner">
                        <div
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            border: "0.5px solid #d3d3d3",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            gap: "10px",
                          }}
                          onClick={onChooseImageClick}>
                          <IconComponent iconName={"Image"} size={20} />
                          <span className="add-file-text">
                            Choose Picture
                          </span>
                        </div>
                      </GridLayoutItem>
                    </>
                  )}
                </div>
              </GridLayoutItem>

              {!isAddForm && (
                <GridLayoutItem
                  col={1}
                  row={2}
                  className="form-inner"
                  style={{ width: "163px" }}>
                  <div className="form-group">
                    <Label className="form-heading">
                      Record Status :
                    </Label>
                    <div>
                      <DropDownList
                        style={{ width: "370px" }}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setTrade((preValue) => ({
                            ...preValue,
                            recordStatus: e.value?.text,
                            recordStatusId: e.value?.id,
                          }));
                        }}
                        className="dropdown-list"
                        value={{
                          id: trade?.recordStatusId,
                          text: trade?.recordStatus,
                        }}
                        data={recordStatusOptions}
                        textField="text"
                        dataItemKey="id"
                      />
                    </div>
                  </div>
                </GridLayoutItem>
              )}

              <GridLayoutItem
                col={1}
                row={3}
                className="form-inner">
                <div className="k-d-flex" style={{ gap: "10px" }}>
                  <KendoButton
                    iconClass=""
                    size={16}
                    className="action-btn"
                    buttonName="Save"
                    onClick={saveCurrentData}
                  />
                  {!isAddForm && (
                    <KendoButton
                      size={16}
                      className=""
                      onClick={showDeleteConfirmation}
                      buttonName="Delete"
                      themeColor={"secondary"}
                    />
                  )}
                  <KendoButton
                    iconClass=""
                    themeColor=""
                    size={16}
                    className=""
                    buttonName="Cancel"
                    onClick={onCancelClick}
                  />
                </div>
              </GridLayoutItem>

              {!isAddForm ? (
                <>
                  <GridLayoutItem
                    colSpan={3}
                    row={4}
                    className="form-footer">
                    <Footerinformation footerInfo={trade} />
                  </GridLayoutItem>
                </>
              ) : (
                <></>
              )}
            </GridLayout>
          )}
        />
      </section>

      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default AddMiscellaneTrades;
