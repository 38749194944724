import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_TRADES_NAMES,
  FETCH_RECORD_STATUSES,
  FETCH_TRADES,
  FETCH_TRADES_NAME,
  SAVE_TRADES_NAMES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import Footerinformation from "../../common/Footerinformation";
import Modal from "../../common/Modal";

const AddNewAlternateTradeNames = () => {
  const navigate = useNavigate();
  const { dataId, subMenu } = useParams();
  const [alternateTradeName, setAlternateTradeName] = useState({
    name: "",
    recordStatus: "New",
    recordStatusId: 1,
    current: "false",
  });
  const isAddForm = dataId === "add-trade-name";

  const { loginData } = useSelector((state) => state.main);
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  const [trade, setTrade] = useState([]);
  const [alternateTradeNames, setAlternateTradeNames] = useState({
    id: -1,
    text: "Select Trade..",
  });
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setIsFiledEdited(true);
    setAlternateTradeName({ ...alternateTradeName, [name]: value });
  };

  useEffect(() => {
    if (!isAddForm) {
      loadAlternateTradeNames();
    }
    getRecordStatuses();
  }, []);
  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };
  const loadAlternateTradeNames = async () => {
    if (dataId !== "add-trade-name") {
      var data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        TypeOfObjectReturned: "",
        SearchList: [
          {
            Id: dataId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_TRADES_NAME,
        data,
        loginData?.token
      );
      if (result.data && result.data.data && result.data.data.length > 0) {
        const certData = result.data.data[0];
        setAlternateTradeName({
          ...certData,
          tradeType: { text: certData?.tradeType ?? "Main" },
        });

        setAlternateTradeNames({
          id: certData?.tradeId,
          text: certData?.tradeName,
        });
      }
    }
  };

  useEffect(() => {
    const fetchTrade = async () => {
      try {
        var data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: "",
          SortList: [{ FieldName: "", Direction: "" }],
          IncludeRecordNr: true,
        });
        const result = await PostRequestCall(
          FETCH_TRADES,
          data,
          loginData?.token
        );
        let updatedData = result?.data?.data?.map((item) => ({
          id: item?.id,
          text: item?.name,
        }));
        setTrade([{ id: -1, text: "Select Trade" }, ...updatedData]);
      } catch (error) {
        console.error("Error fetching Trade:", error);
      }
    };

    fetchTrade();
  }, [loginData]);

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      onSubmit();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };
  const onSubmit = async (e) => {
    if (alternateTradeName?.name === "") {
      return toast.error("Please Enter Name", {
        position: "top-right",
      });
    }
    const newtrades = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData?.userId,
      SaveList: [
        {
          // Id: dataId === "add-trade-name" ? 0 : dataId,
          Id: isAddForm ? 0 : dataId,

          Name: alternateTradeName.name,
          TradeId: alternateTradeNames?.id,
          // Current: newCurrent || false,
          Current: alternateTradeName?.current,
          RecordStatusId: alternateTradeName?.recordStatusId || 1,
          ModifiedBy: loginData?.userId,
        },
      ],
    };
    try {
      const result = await PostRequestCall(
        SAVE_TRADES_NAMES,
        newtrades,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.data?.[0].message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${dataId !== "add-trade-name" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };
  const onModalClose = () => {
    window.history.back();
  };
  const handleEventStatusChange = (event) => {
    setIsFiledEdited(true);

    setAlternateTradeNames({ id: event?.value?.id, text: event?.value?.text });
  };
  const onDeleteClick = async (e) => {
    try {
      setShowLoader(true);

      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: [
          {
            Id: dataId,
            ModifiedBy: loginData?.userId,
          },
        ],
      };

      return PostRequestCall(DELETE_TRADES_NAMES, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };
  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }
  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {/* {dataId !== "add-trade-name" && (
              <span class="record-id-text">
                Alternative Trade ID : {dataId}
              </span>
            )} */}
            {!isAddForm && (
              <span class="record-id-text">Alternate Trade ID : {dataId}</span>
            )}
          </div>
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className=""
              themeColor={"primary"}
              buttonName="Save"
              onClick={onSubmit}
            />
            {dataId !== "add-trade-name" && (
              <KendoButton
                size={16}
                className=""
                onClick={showDeleteConfirmation}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #f5f5f5",
            width: "100%",
          }}></div>
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              // height: 20,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <div className="form-group">
              <Label className="form-heading">Trade :</Label>
              <DropDownList
                className="dropdown-list"
                data={trade}
                value={alternateTradeNames}
                onChange={handleEventStatusChange}
                textField="text"
                dataItemKey="text"
                placeholder="Select Trade.."
              />
            </div>
          </GridLayoutItem>

          <GridLayoutItem col={2} row={1} className="form-inner">
            <label className="form-heading">Current :</label>
            {/* <DropDownList
              data={[
                { text: "Yes", id: 1, value: true },
                { text: "No", id: 0, value: false },
              ]}
              value={
                alternateTradeName.current
                  ? { text: "Yes", id: 1, value: true }
                  : { text: "No", id: 0, value: false }
              }
              textField="text"
              dataItemKey="id"
              className="dropdown-list"
              onChange={(e) => {
                setIsFiledEdited(true);
                setAlternateTradeName({
                  ...alternateTradeName,
                  current: e.value.value,
                });
              }}
            /> */}
            <div>
              <Switch
                size={"small"}
                checked={alternateTradeName.current}
                onChange={(e) => {
                  setIsFiledEdited(true);
                  setAlternateTradeName((preValue) => ({
                    ...preValue,
                    current: e?.value,
                  }));
                }}
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={3} row={1} className="form-inner">
            <label className="form-heading">Alternate Name :</label>
            <Input
              type="text"
              id="name"
              name="name"
              value={alternateTradeName?.name}
              className="field-ip"
              onChange={(e) => onInputChange(e)}
            />
          </GridLayoutItem>
          {dataId !== "add-trade-name" && (
            <GridLayoutItem
              col={1}
              row={2}
              className="form-inner"
              style={{ width: "163px" }}>
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setIsFiledEdited(true);

                      setAlternateTradeName((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{
                      id: alternateTradeName?.recordStatusId,
                      text: alternateTradeName?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          )}

          <GridLayoutItem
            col={1}
            row={!dataId || subMenu ? 4 : 3}
            className="form-inner">
            <div className="k-d-flex" style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />

              {dataId !== "add-trade-name" && (
                <KendoButton
                  size={16}
                  className=""
                  onClick={showDeleteConfirmation}
                  buttonName="Delete"
                  themeColor={"secondary"}
                />
              )}
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
          {dataId !== "add-trade-name" && (
            <GridLayoutItem colSpan={7} className="form-footer">
              <Footerinformation footerInfo={alternateTradeName} />
            </GridLayoutItem>
          )}
        </GridLayout>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default AddNewAlternateTradeNames;
