import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { IconComponent } from "../common/Icon";
import KendoButton from "../common/KendoButton";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { PostRequestCall } from "../apicall/PostRequest";
import { SAVE_PRODUCTS, SAVE_PROJECT_PRODUCTS, SAVE_USERS_COMPANIES } from "../constant/Apipath";
import ProductInput from "../common/ProductInput";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ManufacturerInput from "../common/ManufacturerInput";

const AddProjectProduct = ({ showForm, onClose, FetchProjectProducts, getObjectForApiCall }) => {

    const { loginData } = useSelector((state) => state.main);
    const { projectId } = useParams();

    const [submitLoader, setSubmitLoader] = useState(false);

    const [form, setForm] = useState({
        productName: "",
        productId: 0,
        manufacturerName: "",
        manufacturerId: "",
        productType: ""

    });

    const [isProductInputOpen, setIsProductInputOpen] = useState(false);
    const [isManufactureInputOpen, setIsManufactureInputOpen] = useState(false);

    const [newProductValues, setNewProductValues] = useState({
        manufacturerId: 0,
        manufacturerName: "",
        productType: "Material"
    })

    const handleSave = async () => {
        try {
            let productId = form?.productId ?? 0;
            setSubmitLoader(true);

            if (productId === 0) {
                let manufacturerId = newProductValues?.manufacturerId ?? 0;
                if (newProductValues?.manufacturerName?.trim() !== "" && manufacturerId === 0) {
                    const saveObject = {
                        userId: Number(loginData?.userId),
                        ReturnRecordId: true,
                        ReturnRecordError: true,
                        SaveList: [{
                            Id: 0,
                            Name: newProductValues?.manufacturerName,
                            webSite: "",
                            phone: "",
                            ModifiedBy: Number(loginData?.userId),
                            RecordStatusId: 1,
                            Manufacturer: true,
                            active: true,
                            contact: "",
                            email: "",
                        }]
                    };
                    let res = await PostRequestCall(SAVE_USERS_COMPANIES, saveObject, loginData?.token);
                    manufacturerId = res?.data?.data?.[0]?.objData?.id ?? 0;
                }

                const obj = {
                    UserId: loginData?.userId,
                    ReturnRecordId: true,
                    ReturnRecordError: true,
                    SaveList: [
                        {
                            Id: 0,
                            Name: form?.productName,
                            ManufacturerId: manufacturerId,
                            productType: newProductValues?.productType,
                            RecordStatusId: 1,
                            ModifiedBy: loginData?.userId,
                        },
                    ],
                };
                let productRes = await PostRequestCall(SAVE_PRODUCTS, obj, loginData?.token);
                productId = productRes?.data?.data?.[0]?.objData?.id ?? 0;
            }

            const obj = {
                UserId: Number(loginData?.userId),
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [
                    {
                        Id: 0,
                        ProjectId: Number(projectId),
                        ProductId: productId,
                        ModifiedBy: Number(loginData?.userId),
                    },
                ],
            };
            await PostRequestCall(
                SAVE_PROJECT_PRODUCTS,
                obj,
                loginData?.token
            );
            FetchProjectProducts(getObjectForApiCall());
            onClose();
        } catch (error) {
            console.log("Error while saving company in project :: ", error);
            onClose();
        }
    }

    if (!showForm) return null;

    return (
        <div style={overlayStyles.overlay}>
            <div style={overlayStyles.overlayContent}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconComponent
                        size={25}
                        iconName="X"
                        onClick={() => {
                            onClose();
                        }}
                    />
                </div>
                <h3>Add product to this project</h3>
                <div style={overlayStyles.formGroup}>
                    <p>
                        <b>Product:</b>
                    </p>
                    <ProductInput
                        value={form?.productName}
                        setSelectedProduct={({ label, id, manufacturerName, manufacturerId, productType }) => {
                            setForm((preValue) => ({
                                ...preValue,
                                productName: label,
                                productId: id,
                                manufacturerName,
                                manufacturerId,
                                productType
                            }));
                        }}
                        hideLabel
                        open={(value) => {
                            setIsProductInputOpen(value);
                        }}
                    />

                    {
                        form?.productId !== 0 ?
                            (
                                <div>
                                    <p>
                                        <b>Manufacturer:</b> {form?.manufacturerName || "N/A"}
                                    </p>
                                    <p>
                                        <b>Type:</b> {form?.productType || "N/A"}
                                    </p>
                                </div>
                            )
                            :
                            (<></>)
                    }

                    {
                        form?.productId === 0 && !isProductInputOpen && form?.productName?.trim()?.length > 2
                            ?
                            (
                                <p>Adding a new Product to the database:</p>
                            )
                            :
                            (
                                <></>
                            )
                    }
                </div>

                {
                    form?.productId === 0 && !isProductInputOpen && form?.productName?.trim()?.length > 2
                        ?
                        (
                            <>
                                <div style={overlayStyles.formGroup}>
                                    <p>
                                        <b>Manufacturer:</b>
                                    </p>
                                    <ManufacturerInput
                                        value={newProductValues?.manufacturerName}
                                        setSelectedManufacturer={({ label, id }) => {
                                            setNewProductValues((preValue) => ({
                                                ...preValue,
                                                manufacturerName: label,
                                                manufacturerId: id,
                                            }));
                                        }}
                                        open={(value) => {
                                            setIsManufactureInputOpen(value);
                                        }}
                                    />
                                    {
                                        newProductValues?.manufacturerId === 0 && !isManufactureInputOpen && newProductValues?.manufacturerName?.trim()?.length > 2
                                            ?
                                            (
                                                <p>Adding a new Manufacturer to the database:</p>
                                            )
                                            :
                                            (
                                                <></>
                                            )

                                    }
                                </div>
                                <div style={overlayStyles.formGroup}>
                                    <p>
                                        <b>Type:</b>
                                    </p>
                                    <DropDownList
                                        data={["Material", "Tool"]}
                                        value={newProductValues?.productType}
                                        onChange={(e) => {
                                            setNewProductValues((preValue) => ({
                                                ...preValue,
                                                productType: e?.target?.value
                                            }));
                                        }}
                                        placeholder="Select product type"
                                    />
                                </div>
                            </>
                        )
                        :
                        (
                            <></>
                        )
                }

                <div style={overlayStyles.actions}>
                    {
                        submitLoader ? (
                            <div
                                className="action-btn-loader"
                                style={{ marginLeft: "auto", width: "30px", height: "20px" }}>
                                <Loader type={"pulsing"} themeColor="white" size="small" />
                            </div>
                        ) : (
                            <KendoButton
                                iconClass=""
                                size={16}
                                className={`action-btn ${form.productName?.trim()?.length < 2 ? "disabled-btn" : ""}`}
                                buttonName="Save"
                                onClick={handleSave}
                                disabled={form.productName?.trim()?.length < 2}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default AddProjectProduct;

const overlayStyles = {
    overlay: {
        position: "fixed",
        top: "67px",
        right: 0,
        width: "400px",
        height: "100%",
        background: "white",
        boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
        transform: "translateX(0)",
        transition: "transform 0.3s ease-in-out",
        borderRadius: "10px 0 0 10px",
    },
    overlayContent: {
        padding: "20px",
    },
    closeBtn: {
        float: "right",
    },
    formGroup: {
        marginBottom: "15px",
    },
    actions: {
        marginTop: "20px",
        textAlign: "right",
    },
};
