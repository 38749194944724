import {
  Card,
  CardBody,
  GridLayout,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import {
  FETCH_USERS,
  FETCH_USERS_2,
  GET_USER_RECORDS,
} from "../../constant/Apipath";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "../Reports.css";
import { IconComponent } from "../../common/Icon";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { GetrequestCall } from "./../../apicall/GetRequest";
import img from "../../assets/images/profile-logo.jpg";
import { PostRequestCall } from "../../apicall/PostRequest";
import questionMark from "../../assets/images/question-ico.png";
import questionAnswerMark from "../../assets/images/question-answer-ico.png";
import builderBuck from "../../assets/images/builder-buck-ico.png";
import { Chip } from "@progress/kendo-react-buttons";
import UserProductReportSection from "./UserProductReportSection";
import UserCompaniesAndProjects from "./UserExperienceEducation";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import UserReportSection from "./UserReportSection";

function ViewUserReport() {
  const hoverTimeoutRef = useRef(null);
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [DropdownOpen, setDropdownOpen] = useState(false);
  const [userDetails, setUserDetails] = useState([]);

  const [showLoader, setShowLoader] = useState(true);

  const [contribution, setContribution] = useState();

  const [rewards, setRewards] = useState({
    questions: 0,
    questionAnswers: 0,
    questionsToAnswer: 0,
    totalBuilderBucks: 0,
  });
  const [activity, setActivity] = useState([]);
  const [filter, setFilter] = useState({
    contribution: {
      title: "All",
      value: "All",
    },
    activity: {
      title: "All",
      value: "All",
    },
  });
  const [sort, setSort] = useState([
    { field: "lastModifiedDate", dir: "desc" },
  ]);
  const [selectedTab, setSelectedTab] = useState(0);

  const filterOptions = [
    {
      title: "All",
      value: "All",
    },
    {
      title: "This Year",
      value: "ThisYear",
    },
    {
      title: "This Month",
      value: "ThisMonth",
    },
    {
      title: "This Week",
      value: "ThisWeek",
    },
  ];

  useEffect(() => {
    // getUserDetails();
    // getUserRewardsCounts();
    // getUserContributionCounts(filter?.contribution?.value);
    // getUserActivity(filter?.activity?.value, 2);
    getData();
  }, []);

  const getData = async () => {
    try {
      await Promise.all([
        getUserDetails(),
        getUserRewardsCounts(),
        getUserContributionCounts(filter?.contribution?.value),
        getUserActivity(filter?.activity?.value, 2),
      ]).then((res) => {
        console.log("--::--");
      });
    } catch (error) {
      console.log("Error while getting data :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const formatDate = (dateString) => {
    if (dateString) {
      const options = { month: "short", day: "numeric", year: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    } else {
      return null;
    }
  };

  const getUserDetails = async () => {
    try {
      const requestObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: id,
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        SearchList: [{ UserId: id }],
        SortList: [{}],
      };

      const response = await PostRequestCall(
        FETCH_USERS_2,
        requestObj,
        loginData?.token
      );

      const userData = response?.data?.data?.[0] ?? null;
      setUserDetails(userData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getUserRewardsCounts = async () => {
    try {
      const res = await GetrequestCall(
        `${GET_USER_RECORDS}/${id}/7/7`,
        loginData?.token
      );
      const rewardsData = res?.data?.data?.[0] ?? {};
      setRewards({
        questions: rewardsData?.Questions ?? 0,
        questionsToAnswer: rewardsData?.QuestiontoAnswers ?? 0,
        totalBuilderBucks: rewardsData?.TotalBuilderBucks ?? 0,
      });
    } catch (error) {
      console.log("Error while getting data:: ", error);
    }
  };

  const getUserContributionCounts = async (filterType) => {
    try {
      const res = await GetrequestCall(
        `${GET_USER_RECORDS}/${id}/5/${filterType}`,
        loginData?.token
      );
      const contributionData = res?.data?.data ?? [];
      setContribution(contributionData?.sort((a, b) => b?.Count - a?.Count));
    } catch (error) {
      console.log("Error while getting data:: ", error);
    }
  };

  const getUserActivity = async (filterType, order) => {
    try {
      const res = await GetrequestCall(
        `${GET_USER_RECORDS}/${id}/6/${filterType}/${order}`,
        loginData?.token
      );
      const data = res?.data?.data ?? [];
      setActivity(
        data?.map((item) => ({
          moduleName: item.ModuleName,
          count: item.ModuleCount,
          lastModifiedDate: formatDate(item.LastDateModified),
        }))
      );
    } catch (error) {
      console.log("Error while getting data:: ", error);
    }
  };

  const handleFilterChange = (key, filterValue) => {
    setFilter((preValue) => ({ ...preValue, [key]: filterValue }));
    if (key === "contribution") {
      getUserContributionCounts(filterValue?.value);
    } else {
      getUserActivity(filterValue?.value, sort?.[0]?.dir === "desc" ? 2 : 1);
    }
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  };

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setIsDropdownOpen(true);
  };
  const handleMouseEnter2 = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 200);
  };
  const handleMouseLeave2 = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 200);
  };

  const handleClick = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const handleClick2 = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const navigateToUserProfile = () => {
    navigate(`/user/accounts/users/${id}/edit-record`);
  };
  const navigateToUserQuestions = () => {
    navigate(`/user/accounts/users/${id}/user-quizzes`);
  };

  const navigateToUserQuestionsToAnswers = () => {
    navigate(`/user/accounts/users/${id}/user-quizzes`, {
      state: {
        from: "report",
        filter: "question_to_answers",
      },
    });
  };

  const navigateToBuilderBugs = () => {
    alert("Is In Process");
  };

  const handleSortChange = (event) => {
    const sorted = event.sort.filter((s) => s.field === "lastModifiedDate");
    setSort(sorted);
    getUserActivity(
      filter?.activity?.value,
      sorted?.[0]?.dir === "desc" ? 2 : 1
    );
  };

  const onContributionClick = (item) => {
    let title = item?.Title?.trim();
    if (title === "Sites") {
      return navigate(`/user/accounts/users/${id}/user-sites`);
    }
    if (title === "Projects") {
      return navigate(`/user/accounts/users/${id}/user-site-projects`);
    }
    if (title === "Companies") {
      return alert("Is in process");
    }
    if (title === "Products") {
      return alert("Is in process");
    }
    if (title === "Experiences") {
      return navigate(`/user/accounts/users/${id}/user-work-history`);
    }
    if (title === "Questions Answered") {
      return navigate(`/user/accounts/users/${id}/user-quizzes`, {
        state: {
          from: "report",
          filter: "question_answered",
        },
      });
    }
    if (title === "Nr of Posts") {
      return navigate(`/user/accounts/users/${id}/user-post`, {
        state: {
          from: "report",
          filter: "user_post",
        },
      });
    }
    if (title === "Nr of Post Comments") {
      return navigate(`/user/accounts/users/${id}/user-post-comments`, {
        state: {
          from: "report",
          filter: "user_post_comments",
        },
      });
    }
    if (title === "Nr of Support Requests") {
      return navigate(`/user/accounts/users/${id}/user-support`);
    }

    return alert("Is in process");
  };

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div>
      <div
        className="report-section"
        style={{ height: "100%", overflow: "hidden" }}>
        <GridLayout
          rows={[{ height: "100%" }]}
          cols={[{ width: "35%" }, { width: "65%" }]}
          gap={{ rows: 1, cols: 0 }}
          style={{
            height: "100%",
            overflow: "hidden",
            borderRadius: "10px",
          }}>
          <div style={{ position: "relative", width: "100%" }}>
            <Card
              style={{
                padding: "10px 10px",
                height: "90vh",
                position: "relative",
                // overflow: "scroll",
                width: "98%",
              }}>
              <div
                style={{
                  // padding: "5px",
                  fontWeight: "bold",
                  color: "#116fa5",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "10px",
                  position: "fixed",
                }}>
                <IconComponent
                  iconName={"ArrowLeft"}
                  size={15}
                  color="#116fa5"
                  onClick={() => {
                    navigate("/reports/users/users");
                  }}
                />
                <p
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    color: "#116fa5",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    navigate("/reports/users/users");
                  }}>
                  Back to all Users
                </p>
              </div>
              <div
                style={{ width: "100%", marginTop: "35px" }}
                className="about_user_report_conatiner">
                <div className="user_report_about_card">
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <div>
                      <img
                        src={userDetails?.pictureUrl ?? img}
                        alt="Profile"
                        onError={(e) => {
                          e.target.src = img;
                        }}
                        className="profile-image"
                      />
                    </div>

                    <div>
                      <h4 className="ur-user-name">
                        {userDetails?.firstName} {userDetails?.lastName}
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "1px",
                        }}>
                        {userDetails?.tradeList?.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "1px",
                            }}>
                            {userDetails?.tradeList?.map((trade, index) => (
                              <Chip
                                key={index}
                                text={trade.tradeName}
                                type="info"
                                style={{
                                  margin: "2px",
                                  lineHeight: "15px",
                                  borderRadius: "20px",
                                }}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="profile-card" style={{ marginTop: "15px" }}>
                    <div className="about-section">
                      <h4 className="ur-about-title">About</h4>
                      <ul
                        className="profile-details"
                        style={{ marginTop: "15px" }}>
                        {userDetails?.primaryEmail && (
                          <li>
                            <IconComponent
                              size={16}
                              iconName={"EnvelopeFill"}
                              color="#A5A5A5"
                            />
                            <span className="icon"></span>
                            <span style={{ color: "#116fa5" }}>
                              {userDetails?.primaryEmail}
                            </span>
                            <span className="tag">(primary)</span>
                          </li>
                        )}
                        {userDetails?.personalEmail && (
                          <li>
                            <IconComponent
                              size={16}
                              iconName={"EnvelopeFill"}
                              color="#A5A5A5"
                            />

                            <span className="icon"></span>
                            <span style={{ color: "#116fa5" }}>
                              {userDetails?.personalEmail}
                            </span>
                            <span className="tag">(personal)</span>
                          </li>
                        )}
                        {userDetails?.hPhoneNo && (
                          <li>
                            <span className="icon">
                              {" "}
                              <IconComponent
                                size={16}
                                iconName={"TelephoneFill"}
                                color="#A5A5A5"
                              />
                            </span>
                            <span>{userDetails?.hPhoneNo}</span>
                            <span className="tag">(home)</span>
                          </li>
                        )}

                        {userDetails?.mPhoneNo && (
                          <li>
                            <span className="icon">
                              {" "}
                              <IconComponent
                                size={16}
                                iconName={"TelephoneFill"}
                                color="#A5A5A5"
                              />
                            </span>
                            <span>{userDetails?.mPhoneNo}</span>
                            <span className="tag">(mobile)</span>
                          </li>
                        )}
                        {(userDetails?.address1 ||
                          userDetails?.address2 ||
                          userDetails?.city ||
                          userDetails?.country ||
                          userDetails?.province ||
                          userDetails?.postalCode) && (
                          <li>
                            <span className="icon">
                              {" "}
                              <IconComponent
                                size={16}
                                iconName={"HouseFill"}
                                color="#A5A5A5"
                              />
                            </span>
                            <span>
                              {userDetails?.address1} {userDetails?.address2}{" "}
                              {userDetails?.city} {userDetails?.country}{" "}
                              {userDetails?.province} {userDetails?.postalCode}
                            </span>
                          </li>
                        )}
                        {userDetails?.rideShareInterested && (
                          <Chip
                            text={"Interested in ride sharing"}
                            type="info"
                            style={{ padding: "2px", lineHeight: "15px" }}
                          />
                        )}
                      </ul>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "5px ",
                      }}></div>
                  </div>
                </div>

                <div className="user_report_description_card">
                  <ul className="account-details">
                    {userDetails?.language && (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}>
                        <span style={{ color: "#888", fontSize: "13px" }}>
                          Language:
                        </span>
                        {"  "}
                        <span className="label">
                          {userDetails?.language?.trim() !== ""
                            ? userDetails?.language
                            : "--"}
                        </span>
                      </li>
                    )}
                    {userDetails?.accountType && (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}>
                        <span style={{ color: "#888", fontSize: "13px" }}>
                          Account Type:
                        </span>
                        <span className="label">
                          {userDetails?.accountType === "PASSWORD"
                            ? "Email"
                            : userDetails?.accountType}
                        </span>
                      </li>
                    )}
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      <span style={{ color: "#888", fontSize: "13px" }}>
                        Account Status:
                      </span>
                      <span
                        style={{
                          padding: "2px 5px",
                          fontSize: "13px",
                          borderRadius: "5px",
                          backgroundColor: userDetails?.active
                            ? "#d4edda"
                            : "#f8d7da",
                          color: userDetails?.active ? "#155724" : "#721c24",
                          border: `1px solid ${
                            userDetails?.active ? "#c3e6cb" : "#f5c6cb"
                          }`,
                        }}>
                        {userDetails?.active ? "Active" : "Inactive"}
                      </span>
                    </li>

                    <li style={{ padding: "0px", margin: "0px" }}>
                      {userDetails?.lastDateLoggedIn && (
                        <span className="last-logged">
                          Last logged in:{" "}
                          {formatDate(userDetails?.lastDateLoggedIn)}
                        </span>
                      )}
                    </li>
                    <li style={{ padding: "0px", margin: "0px" }}>
                      {userDetails?.referredByName ||
                      userDetails?.referredDate ? (
                        <span className="referred">
                          Referred by {userDetails?.referredByName || "-"}
                          {userDetails?.referredDate &&
                            ` on ${formatDate(userDetails?.referredDate)}`}
                        </span>
                      ) : (
                        userDetails?.dateCreated && (
                          <span className="referred">
                            Account created on{" "}
                            {formatDate(userDetails?.dateCreated)}
                          </span>
                        )
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </Card>
          </div>

          <div
            style={{
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              backgroundColor: "#fff",
              paddingLeft: "10px",
              borderRadius: "6px",
            }}>
            <TabStrip
              selected={selectedTab}
              onSelect={handleTabSelect}
              style={{
                flexGrow: 1,
                overflow: "hidden",
                paddingTop: "5px",
                height: "100%",
              }}>
              <TabStripTab title="Summary">
                <div
                  style={{
                    display: "grid",
                    gap: "10px",
                    height: "100%",
                    overflow: "auto",
                  }}>
                  <div className="card-container count-container">
                    <Card
                      className="custom-card"
                      onClick={navigateToBuilderBugs}>
                      <CardBody className="count-body">
                        <div>
                          <h3 className="card-heading">Builder Bucks</h3>
                          <span className="card-subheading">
                            {rewards?.totalBuilderBucks}
                          </span>
                        </div>
                        <div>
                          <img className="count-img" src={builderBuck}></img>
                        </div>
                      </CardBody>
                    </Card>
                    <Card
                      className="custom-card"
                      onClick={navigateToUserQuestionsToAnswers}>
                      <CardBody className="count-body">
                        <div>
                          <h3 className="card-heading">Questions Answered</h3>
                          <span className="card-subheading">
                            {rewards?.questionsToAnswer}
                          </span>
                        </div>
                        <div>
                          <img
                            className="count-img"
                            src={questionAnswerMark}></img>
                        </div>
                      </CardBody>
                    </Card>
                    <Card
                      className="custom-card"
                      onClick={navigateToUserQuestions}>
                      <CardBody className="count-body">
                        <div>
                          <h3 className="card-heading">
                            Questions Outstanding
                          </h3>
                          <span className="card-subheading">
                            {rewards?.questions}
                          </span>
                        </div>
                        <div>
                          <img className="count-img" src={questionMark}></img>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div style={{ display: "flex", height: "100%" }}>
                    {/* <Card
                      className="report-data"
                      style={{ borderRadius: "10px 0px 0px 10px" }}>
                      <div className="report-header">
                        <h5 className="report-title">
                          <span
                            style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Activity{" "}
                          </span>
                          <span style={{ color: "#2d6ea3", fontSize: "14px" }}>
                            ({filter?.activity?.title})
                          </span>
                        </h5>

                        <div
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          onClick={handleClick}>
                          <IconComponent iconName={"ThreeDots"} size={15} />
                        </div>
                        {isDropdownOpen && (
                          <div
                            className="dropdown-menu"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            <div className="dropdown-item">
                              {filterOptions?.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    cursor: "pointer",
                                    color:
                                      item?.value === filter?.activity?.value
                                        ? "blue"
                                        : "black",
                                  }}
                                  onClick={() =>
                                    handleFilterChange("activity", item)
                                  }>
                                  <div className="dropdown-title">
                                    {item?.title}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <Grid
                        data={activity}
                        style={{ height: "370px" }}
                        onSortChange={handleSortChange}
                        sortable={{
                          allowUnsort: true,
                          mode: "multiple",
                        }}
                        sort={sort}>
                        <GridColumn
                          field="moduleName"
                          title="Module Name"
                          width="auto"
                        />
                        <GridColumn field="count" title="Count" width="auto" />
                        <GridColumn
                          field="lastModifiedDate"
                          title="Last Modified Date"
                          width="auto"
                        />
                      </Grid>
                    </Card>
                    <Card
                      className="report-data"
                      style={{ borderRadius: "0px 10px 10px 0px" }}>
                      <div className="report-header" style={{ width: "300px" }}>
                        <h5 className="report-title">
                          <span
                            style={{ fontSize: "14px", fontWeight: "bold" }}>
                            {" "}
                            Contribution{" "}
                          </span>
                          <span style={{ color: "#2d6ea3", fontSize: "14px" }}>
                            ({filter?.contribution?.title})
                          </span>
                        </h5>

                        <div
                          onMouseEnter={handleMouseEnter2}
                          onMouseLeave={handleMouseLeave2}
                          onClick={handleClick2}>
                          <IconComponent iconName={"ThreeDots"} size={15} />
                        </div>
                        {DropdownOpen && (
                          <div
                            className="dropdown-menu"
                            onMouseEnter={handleMouseEnter2}
                            onMouseLeave={handleMouseLeave2}>
                            <div className="dropdown-item">
                              {filterOptions?.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    cursor: "pointer",
                                    color:
                                      item?.value ===
                                      filter?.contribution?.value
                                        ? "#2d6ea3"
                                        : "black",
                                  }}
                                  onClick={() =>
                                    handleFilterChange("contribution", item)
                                  }>
                                  <div className="dropdown-title">
                                    {item?.title}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="report-data-constribution">
                        <ul className="k-p-0" style={{ marginTop: "5px" }}>
                          {contribution?.map((item, index) => (
                            <li
                              key={index}
                              className="Answer k-d-flex k-justify-content-between k-align-items-center report-list k-pt-2"
                              style={{
                                paddingBottom: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                onContributionClick(item);
                              }}>
                              <span className="gray-color">
                                {item?.Title?.trim()}
                              </span>
                              <div className="info k-d-flex k-justify-content-center k-align-items-center">
                                <span className="theme-color fw-semibold">
                                  {item?.Count}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Card> */}

                    <Card
                      className="report-data"
                      style={{ borderRadius: "10px 0px 0px 10px" }}>
                      <div className="report-header">
                        <h5 className="report-title">
                          <span
                            style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Activity{" "}
                          </span>
                        </h5>
                        <div>
                          <span style={{ marginRight: "10px" }}>Filter</span>
                          <DropDownList
                            data={filterOptions}
                            textField="title"
                            value={
                              filter?.activity || { title: "All", value: null }
                            }
                            onChange={(e) =>
                              handleFilterChange("activity", e.value)
                            }
                            style={{ width: "110px", height: "24px" }}
                          />
                        </div>
                      </div>
                      <Grid
                        data={activity}
                        style={{ height: "370px" }}
                        onSortChange={handleSortChange}
                        sortable={{
                          allowUnsort: true,
                          mode: "multiple",
                        }}
                        sort={sort}>
                        <GridColumn
                          field="moduleName"
                          title="Module Name"
                          width="auto"
                        />
                        <GridColumn field="count" title="Count" width="auto" />
                        <GridColumn
                          field="lastModifiedDate"
                          title="Last Modified Date"
                          width="auto"
                        />
                      </Grid>
                    </Card>

                    <Card
                      className="report-data"
                      style={{ borderRadius: "0px 10px 10px 0px" }}>
                      <div className="report-header" style={{ width: "300px" }}>
                        <h5 className="report-title">
                          <span
                            style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Contribution{" "}
                          </span>
                        </h5>
                        <div>
                          <span style={{ marginRight: "10px" }}>Filter</span>
                          <DropDownList
                            data={filterOptions}
                            textField="title"
                            value={
                              filter?.contribution || {
                                title: "All",
                                value: null,
                              }
                            }
                            onChange={(e) =>
                              handleFilterChange("contribution", e.value)
                            }
                            style={{ width: "110px", height: "24px" }}
                          />
                        </div>
                      </div>
                      <div className="report-data-contribution">
                        <ul className="k-p-0" style={{ marginTop: "5px" }}>
                          {contribution?.map((item, index) => (
                            <li
                              key={index}
                              className="Answer k-d-flex k-justify-content-between k-align-items-center report-list k-pt-2"
                              style={{
                                paddingBottom: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => onContributionClick(item)}>
                              <span className="gray-color">
                                {item?.Title?.trim()}
                              </span>
                              <div className="info k-d-flex k-justify-content-center k-align-items-center">
                                <span className="theme-color fw-semibold">
                                  {item?.Count}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Card>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab title="Experience">
                <UserCompaniesAndProjects />
              </TabStripTab>
              <TabStripTab title="Products">
                <UserProductReportSection />
              </TabStripTab>
              <TabStripTab title="Feedback">
                <UserReportSection />
              </TabStripTab>
            </TabStrip>
          </div>
        </GridLayout>
      </div>
    </div>
  );
}

export default ViewUserReport;
