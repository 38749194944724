import React, { useEffect, useState } from "react";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar,
  Menu,
  MenuItem,
} from "@progress/kendo-react-layout";
import "../header/Header.css";
import logo from "../assets/images/logo.png";
import { SvgIcon } from "@progress/kendo-react-common";
import { menuIcon } from "@progress/kendo-svg-icons";
import profileLogo from "../assets/images/profile-logo.jpg";
import { useDispatch, useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IconComponent } from "../common/Icon";
import { setLogindata } from "../redux/actions/action";
import { useNavigate } from "react-router-dom";

function Header(props) {
  const { loginData } = useSelector((state) => state.main);
  const headerMenu = [
    `${loginData?.firstName} ${" "} ${loginData?.lastName} `,
    "Logout",
  ];
  const userRoleAuthName = [loginData?.userRoleAuthList?.[0]?.userRoleAuthName];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openDrawer = () => {
    if (props.drawerExpand) {
      props.setDrawerexpand(false);
    } else {
      props.setDrawerexpand(true);
    }
  };

  const onHeadermenuchange = () => {
    dispatch(setLogindata({}));
    // navigate("/");
    navigate('/', { replace: true });
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <React.Fragment>
      <AppBar className="header-appbar">
        <AppBarSection>
          <button
            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
            onClick={openDrawer}
            style={{ color: "#000" }}>
            <SvgIcon icon={menuIcon} />
          </button>
        </AppBarSection>
        <AppBarSpacer style={{ width: 4 }} />
        <AppBarSection>
          <img className="logo-img" src={logo} alt="logo" />
        </AppBarSection>
        <AppBarSpacer
          style={{
            width: 4,
          }}
        />
        <AppBarSection>
          <h1 className="title">Construction Database</h1>
          <h6
            className="title"
            style={{
              marginLeft: "25px",
              fontSize: "14px",
              fontWeight: "bold",
            }}>
            {props?.subMenuName}
          </h6>
        </AppBarSection>
        <AppBarSpacer />
        <AppBarSection className="actions"></AppBarSection>

        <AppBarSection>
          <li class="profile-nav onhover-dropdown px-0 py-0">
            <div class="d-flex profile-media align-items-center">
              <img className="img-30" src={loginData?.pictureUrl} />

              <div class="flex-grow-1" style={{ color: "#000" }}>
                <span>{`${loginData?.firstName}  ${loginData?.lastName} `}</span>
              </div>
            </div>
            <ul
              class="profile-dropdown onhover-show-div"
              style={{ marginTop: "5px" }}>
              <li></li>
              <li>
                <a onClick={onHeadermenuchange} style={{ cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-log-in">
                    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                    <polyline points="10 17 15 12 10 7"></polyline>
                    <line x1="15" y1="12" x2="3" y2="12"></line>
                  </svg>
                  <span onClick={onHeadermenuchange}>Log out</span>
                </a>
              </li>
            </ul>
          </li>
        </AppBarSection>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
