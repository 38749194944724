import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  DELETE_POSTS_PICTURES,
  FETCH_PRODUCTS_POSTS_PICTURES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import { IconComponent } from "../../common/Icon";
import { useParams } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { useDispatch, useSelector } from "react-redux";
import KendoButton from "../../common/KendoButton";
import { clearFiterData } from "../../redux/actions/action";
import { toast } from "react-toastify";
import Modal from "../../common/Modal";
import AddNewProductImages from "../product-pictures/AddNewProductImages";
import { Card, CardImage } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import Viewer from "react-viewer";
import InfiniteScroll from "react-infinite-scroll-component";
const initialState = {
  data: [],
  total: 0,
  skip: 0,
  take: 20,
  currentPage: 1,
  fullSearch: "",
  nrOfRecPerPage: 20,
  pageNr: 1,
  searchList: [],
  sortList: [],
  loadingMore: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "DATA":
      return { ...state, data: action.payload };
    case "TOTAL":
      return { ...state, total: action.payload };
    case "SKIP":
      return { ...state, skip: action.payload };
    case "TAKE":
      return { ...state, take: action.payload };
    case "CURRENT_PAGE":
      return { ...state, currentPage: action.payload };
    case "FULL_SEARCH":
      return { ...state, fullSearch: action.payload };
    case "NR_OF_REC_PER_PAGE":
      return { ...state, nrOfRecPerPage: action.payload };
    case "PAGE_NR":
      return { ...state, pageNr: action.payload };
    case "SEARCH_LIST":
      return { ...state, searchList: action.payload };
    case "SORT_LIST":
      return { ...state, sortList: action.payload };
    case "SET_LOADING_MORE":
      return { ...state, loadingMore: action.payload };
    default:
      return state;
  }
};

const formatDate = (dateString) => {
  if (dateString) {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  } else {
    return null;
  }
};

const ProductPicturesList = () => {
  const { productID } = useParams();
  const { loginData, filterData } = useSelector((state) => state.main);
  const reduxDispatch = useDispatch();
  const debounceTimeout = useRef(null);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteEnable, setDeleteEnable] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showLoader, setShowLoader] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });

  useEffect(() => {
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, []);

  useEffect(() => {
    fetchProductImages(getObjectForFirstTimeApiCall());
  }, []);

  async function fetchProductImages(requestObj) {
    try {
      let searchList = productID
        ? [...requestObj?.searchList, { AppRecordId: productID }]
        : [{ ...requestObj?.searchList }];

      setDeleteEnable(true);
      setSelectAll(false);
      var data = JSON.stringify({
        Id: 0,
        PageNr: requestObj?.pageNr,
        NrOfRecPerPage: requestObj?.nrOfRecPerPage,
        FullSearch: requestObj?.fullSearch,
        UserId: loginData?.userId,
        SearchList: searchList,
        SortList: [
          ...requestObj?.sortList,
          { fieldName: "id", direction: "desc" },
        ],
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        DoNotShowInSystemFields: false,
      });
      const result = await PostRequestCall(
        FETCH_PRODUCTS_POSTS_PICTURES,
        data,
        loginData?.token
      );

      const getUpdatedlist = result?.data?.data.map((item) => ({
        ...item,
        dateCreated: formatDate(item.dateCreated),
        dateModified: formatDate(item.dateModified),
        selected: false,
        inEdit: true,
      }));

      dispatch({
        type: "DATA",
        payload:
          requestObj?.pageNr === 1
            ? getUpdatedlist
            : [...state?.data, ...getUpdatedlist],
      });
      dispatch({ type: "TOTAL", payload: result?.data?.nrOfRecords });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  }

  const getObjectForFirstTimeApiCall = () => {
    let previousFilterValues =
      filterData?.screen === "user-product-images" ? { ...filterData } : {};
    let obj = {
      skip: previousFilterValues?.skip ?? 0,
      take: previousFilterValues?.take ?? 20,
      currentPage: previousFilterValues?.currentPage ?? 1,
      nrOfRecPerPage: previousFilterValues?.nrOfRecPerPage ?? 20,
      pageNr: previousFilterValues?.pageNr ?? 1,
      fullSearch: previousFilterValues?.fullSearch ?? "",
      searchList: previousFilterValues?.searchList ?? [],
      sortList: previousFilterValues?.sortList ?? [],
    };
    if (previousFilterValues) {
      dispatch({ type: "SKIP", payload: previousFilterValues?.skip ?? 0 });
      dispatch({ type: "TAKE", payload: previousFilterValues?.take ?? 20 });
      dispatch({
        type: "CURRENT_PAGE",
        payload: previousFilterValues?.currentPage ?? 1,
      });
      dispatch({
        type: "FULL_SEARCH",
        payload: previousFilterValues?.fullSearch ?? "",
      });
      dispatch({
        type: "NR_OF_REC_PER_PAGE",
        payload: previousFilterValues?.nrOfRecPerPage ?? 20,
      });
      dispatch({ type: "PAGE_NR", payload: previousFilterValues?.pageNr ?? 1 });
      dispatch({
        type: "SEARCH_LIST",
        payload: previousFilterValues?.searchList ?? [],
      });
      dispatch({
        type: "SORT_LIST",
        payload: previousFilterValues?.sortList ?? [],
      });
      reduxDispatch(clearFiterData());
    }
    return obj;
  };

  const getObjectForApiCall = (changesArray, hideLoader) => {
    setShowLoader(hideLoader ? false : true);
    let obj = {
      skip: state?.skip,
      take: state?.take,
      currentPage: state?.currentPage,
      nrOfRecPerPage: state?.nrOfRecPerPage,
      pageNr: state?.pageNr ?? 1,
      fullSearch: state?.fullSearch,
      searchList: state?.searchList,
      sortList: state?.sortList,
    };

    if (changesArray && Array.isArray(changesArray)) {
      changesArray.forEach((change) => {
        const { key, value } = change;
        if (key !== undefined && value !== undefined) {
          obj[key] = value;
        }
      });
    }
    return obj;
  };

  const handleCheckboxChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setDeleteEnable(false);
    } else {
      setDeleteEnable(true);
    }
    let getUpdatedlist = state?.data?.map((item) => {
      return {
        ...item,
        selected: !selectAll,
      };
    });
    dispatch({ type: "DATA", payload: getUpdatedlist });
  };

  const handleIndividualCheckbox = (dataItem) => {
    if (state?.data?.length > 0) {
      let getUpdatedEmailList = state?.data.map((item) => {
        if (item.id === dataItem?.id) {
          return {
            ...item,
            selected: item.selected === true ? false : true,
          };
        } else {
          return item;
        }
      });
      let checkFirst = getUpdatedEmailList.filter(
        (item) => item.selected === true
      );
      if (checkFirst?.length > 0) {
        setSelectAll(false);
      } else {
      }
      if (checkFirst?.length > 0) {
        setSelectAll(false);
        setDeleteEnable(false);
      } else {
        setDeleteEnable(true);
      }
      dispatch({ type: "DATA", payload: getUpdatedEmailList });
    }
  };

  const handleSearchChange = async (e) => {
    dispatch({
      type: "FULL_SEARCH",
      payload: e.target.value === "" ? "" : e.target.value,
    });
  };

  const onDeleted = () => {
    setShowLoader(true);
    setDeleteEnable(true);
    fetchProductImages(getObjectForApiCall());
    return;
  };

  const onKeyDownList = (e) => {
    if (e.keyCode === 13) {
      dispatch({ type: "FULL_SEARCH", payload: e.target.value });
      dispatch({ type: "PAGE_NR", payload: 1 });
      let changes = [
        { key: "fullSearch", value: e.target.value },
        { key: "pageNr", value: 1 },
      ];
      fetchProductImages(getObjectForApiCall(changes));
    }

    if (e.keyCode === 8 && e.target.value?.trim().length <= 1) {
      dispatch({ type: "FULL_SEARCH", payload: "" });
      dispatch({ type: "PAGE_NR", payload: 1 });
      let changes = [
        { key: "fullSearch", value: "" },
        { key: "pageNr", value: 1 },
      ];
      fetchProductImages(getObjectForApiCall(changes));
    }
  };

  const onSearchClick = (e) => {
    e.preventDefault();
    dispatch({ type: "PAGE_NR", payload: 1 });
    let changes = [{ key: "pageNr", value: 1 }];
    fetchProductImages(getObjectForApiCall(changes));
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const selectedData = state?.data?.filter((item) => item?.selected);
      if (selectedData?.length > 0) {
        var deleteList = [];
        selectedData.map(async (item) => {
          deleteList.push({
            Id: item.pictureId,
            ModifiedBy: loginData?.userId,
          });
        });

        const deleteObj = {
          ReturnRecordError: true,
          ReturnRecordId: false,
          UserId: loginData?.userId,
          DeleteList: deleteList,
        };

        PostRequestCall(DELETE_POSTS_PICTURES, deleteObj, loginData?.token)
          .then(async (res) => {
            if (res?.data?.status) {
              toast.success("Data deleted successfully", {
                position: "top-right",
              });
              await onDeleted();
            } else {
              toast.error(
                res?.data?.message ??
                "Something went wrong. Please try again later.",
                {
                  position: "top-right",
                }
              );
              await onDeleted();
            }
          })
          .catch(async (error) => {
            toast.error("Something went wrong. Please try again later.", {
              position: "top-right",
            });
            console.log("Error while deleting a record :: ", error);
            await onDeleted();
          });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
      onDeleted();
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onActionBtnClick = () => {
    onModalClose();
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else {
      return null;
    }
  };

  const handleImageClick = (imageUrl) => {
    const index = state?.data?.findIndex((item) => item.pictureUrl === imageUrl);
    setActiveIndex(index);
    setVisible((prevVisible) => !prevVisible);
  };

  const fetchMoreData = () => {
    let previousPageNo = state?.pageNr ?? 1;
    let nextPageNo = previousPageNo + 1;
    dispatch({ type: "PAGE_NR", payload: nextPageNo });
    let changes = [
      {
        key: "pageNr",
        value: nextPageNo,
      },
    ];
    fetchProductImages(getObjectForApiCall(changes, "hideLoader"));
  };

  const renderImageGallery = () => {
    return (
      <div
        id="scrollable-list-product-pictures"
        style={{ height: "500px", overflow: "auto" }}>
        <InfiniteScroll
          dataLength={state?.data?.length}
          next={fetchMoreData}
          hasMore={state?.data !== state?.total}
          loader={<></>}
          scrollableTarget="scrollable-list-product-pictures">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              height: "100%",
            }}
            className="image-gallery">
            <ListView
              data={state?.data}
              item={(props) => (
                <Card
                  style={{
                    width: "150px",
                    margin: "10px",
                    display: "inline-block",
                    textAlign: "center",
                    cursor: "pointer",
                    scrollbarWidth: "none",
                  }}
                  onClick={() => handleImageClick(props?.dataItem?.pictureUrl)}>
                  <CardImage
                    src={props?.dataItem?.thumbUrl || props?.dataItem?.pictureUrl}
                    alt={`Image ${props?.dataItem?.id}`}
                    style={{
                      height: "100px",
                      objectFit: "cover"
                    }}
                  />

                  <div style={{ display: "flex", gap: "10px" }}>
                    <Checkbox
                      style={{ borderColor: "#ACACAC", marginLeft: "10px" }}
                      checked={props?.dataItem?.selected}
                      onClick={(event) => event.stopPropagation()}
                      onChange={() => handleIndividualCheckbox(props?.dataItem)}
                    />
                    <p style={{ marginTop: "2px" }}>{props?.dataItem?.id}</p>
                  </div>
                </Card>
              )}
            />
          </div>
        </InfiniteScroll>
      </div>
    );
  };

  const onModalClose = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
  };

  const handleClose = () => {
    setShowForm(false);
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="main-list main-list-h-100">
      <div
        className="k-d-flex k-justify-content-between list-header table_filter_options"
        style={{ background: "#ffff" }}>
        <div className="search-section">
          <TextBox
            className="search-record"
            placeholder="Search..."
            suffix={() =>
              state?.fullSearch !== "" && (
                <IconComponent
                  size={25}
                  iconName={"X"}
                  className="search-icon"
                  onClick={async () => {
                    dispatch({ type: "FULL_SEARCH", payload: "" });
                    let changes = [{ key: "fullSearch", value: "" }];
                    await fetchProductImages(getObjectForApiCall(changes));
                  }}
                />
              )
            }
            value={state?.fullSearch}
            onChange={handleSearchChange}
            onKeyDown={onKeyDownList}
          />

          <KendoButton
            iconClass="Search"
            size={16}
            className="action-btn search-btn"
            onClick={onSearchClick}
            buttonName="Search"
            disabled={state?.fullSearch === ""}
          />
        </div>

        <div className="k-d-flex k-align-items-center" style={{ gap: "20px" }}>
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "5px",
              }}>
              <Checkbox
                checked={selectAll}
                onChange={handleCheckboxChange}
                style={{ borderColor: "#ACACAC" }}
              />
              <p>Select All</p>
            </div>

            <KendoButton
              iconClass="TrashFill"
              size={16}
              themeColor={"secondary"}
              className="action-btn-delete"
              disabled={deleteEnable}
              onClick={showDeleteConfirmation}
              buttonName="Delete"
            />
            <KendoButton
              iconClass="Plus"
              size={20}
              className="action-btn"
              onClick={() => setShowForm(true)}
              buttonName="New Record"
            />
          </>
        </div>
      </div>
      <div style={{ margin: "20px" }}>
        {state?.data?.length > 0 ? (
          renderImageGallery()
        ) : (
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            No images available.
          </p>
        )}
        {visible && (
          <Viewer
            visible={visible}
            onClose={() => setVisible(false)}
            images={state.data.map((item) => ({
              src: item.pictureUrl,
              alt: item.id,
            }))}
            activeIndex={activeIndex}
            disableZoom={false}
            zoomable={false}
            scalable={false}
            rotatable={false}
            drag={false}
            noImgDetails={true}
          />
        )}
      </div>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
      {showForm && (
        <AddNewProductImages
          showForm={showForm}
          onClose={handleClose}
          fetchProductImages={fetchProductImages}
          getObjectForApiCall={getObjectForApiCall}
        />
      )}
    </section>
  );
};

export default ProductPicturesList;
