import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_FEATURES,
  FETCH_FEATURES,
  SAVE_FEATURES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";

const AddNewFeatures = () => {
  const { dataId } = useParams();
  const isAddForm = dataId === "add-features";
  const [showLoader, setShowLoader] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const [featuresData, setFeaturesData] = useState({
    featureName: "",
    description: "",
    title: "",
    picturePosition: "",
    pictureUrl: null,
    active: false,
    displayOrder: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      getFeatures();
    }
  }, []);

  const getFeatures = async () => {
    let featureObj = { SearchList: [{ Id: dataId }] };
    const res = await PostRequestCall(
      FETCH_FEATURES,
      featureObj,
      loginData?.token
    );

    let resData = res?.data?.data?.[0] ?? {};
    setFeaturesData({
      ...featuresData,
      ...resData,
      featureName: resData?.featureName,
      title: resData?.title,
      description: resData?.description,
      picturePosition: resData?.picturePosition,
      active: resData?.active,
      displayOrder: resData?.displayOrder,
      pictureUrl:
        resData?.pictureUrl === "" || resData?.pictureUrl === null
          ? null
          : resData?.pictureUrl,
      prevousImageUrl:
        resData?.pictureUrl === "" || resData?.pictureUrl === null
          ? null
          : resData?.pictureUrl,
    });
  };

  const onInputChange = (e, type) => {
    let inputValue = e.target.value;
    if (type === "number") {
      inputValue = inputValue.replace(/\D/g, "");
    }
    setIsFiledEdited(true);
    setFeaturesData({
      ...featuresData,
      [e.target.name]: inputValue,
    });
  };

  const onProfileChange = (e) => {
    if (e?.target?.files?.length > 0) {
      setFeaturesData({
        ...featuresData,
        pictureUrl: e.target.files?.[0],
      });
    }
    e.target.value = "";
  };

  const onSelectProfile = () => {
    setIsFiledEdited(true);
    document.getElementById("user-profile").click();
  };

  const onRemoveImage = () => {
    setIsFiledEdited(true);
    setFeaturesData({
      ...featuresData,
      pictureUrl: null,
    });
  };

  const saveCurrentData = async () => {
    setShowLoader(true);
    try {

      const fd = new FormData();
      fd.append("Id", isAddForm ? 0 : dataId);
      fd.append("featureName", featuresData?.featureName || "");
      fd.append("title", featuresData?.title || "");
      fd.append("Description", featuresData?.description || "");
      fd.append("Active", featuresData?.active);
      fd.append("picturePosition", featuresData?.picturePosition || "");
      fd.append("DisplayOrder", featuresData?.displayOrder || "");
      fd.append("ModifiedBy", loginData?.userId);

      if (featuresData?.pictureUrl) {
        if (featuresData?.pictureUrl !== featuresData?.prevousImageUrl) {
          fd.append("files", featuresData?.pictureUrl);
        }
      } else {
        fd.append("ForceRemovePicture", true);
      }

      await PostRequestCall(SAVE_FEATURES, fd, loginData?.token, "formData")
        .then((res) => {
          setShowLoader(false);
          toast.success(
            `Data ${!isAddForm ? "updated" : "added"} successfully`,
            {
              position: "top-right",
            }
          );
          window.history.back();
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.error("Error saving data :", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while saving data :: ", error);
    }
  };

  const isCheck = (pictureUrl) => {
    if (Object.keys(pictureUrl)?.length === 0) {
      return URL.createObjectURL(pictureUrl);
    } else {
      return pictureUrl;
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);

      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: [
          {
            Id: Number(dataId),
            ModifiedBy: loginData?.userId,
          },
        ],
      };

      return PostRequestCall(DELETE_FEATURES, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success(`Data delete successfully`, {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={false}
        isFieldEdited={isFieldEdited}
        recordText={"Faq ID"}
        saveCurrentData={saveCurrentData}
        onDeleteClick={onDeleteClick}>
        <div style={{ width: "100%" }}>
          <Form
            render={() => (
              <GridLayout
                rows={[
                  { height: 70 },
                  { height: 70 },
                  { height: 130 },
                  // { height: 130 },
                  { height: 200 },
                ]}
                cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
                gap={{ rows: 30, cols: 35 }}
                style={{ padding: "16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <Label className="form-heading"> Feature Name:</Label>
                  <Input
                    name="featureName"
                    value={featuresData?.featureName}
                    className="field-ip"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <Label className="form-heading"> Title:</Label>
                  <Input
                    name="title"
                    value={featuresData?.title}
                    className="field-ip"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1} className="form-inner">
                  <Label className="form-heading"> Picture Position:</Label>
                  <Input
                    name="picturePosition"
                    value={featuresData?.picturePosition}
                    className="field-ip"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>

                <GridLayoutItem col={1} row={2} className="form-inner">
                  <Label className="form-heading"> Display Order:</Label>
                  <Input
                    name="displayOrder"
                    value={featuresData?.displayOrder}
                    className="field-ip"
                    onChange={(e) => {
                      onInputChange(e, "number");
                    }}
                  />
                  <Error></Error>
                </GridLayoutItem>

                <GridLayoutItem col={3} row={2} className="form-inner">
                  <label className="form-heading">Description:</label>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <TextArea
                      type="text"
                      id="description"
                      name="description"
                      value={featuresData?.description}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      rows={6}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={2} className="form-inner">
                  <label className="form-heading">Active:</label>
                  <div>
                    <Switch
                      size={"small"}
                      checked={featuresData?.active}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setFeaturesData((preValue) => ({
                          ...preValue,
                          active: e?.value,
                        }));
                      }}
                    />
                  </div>
                </GridLayoutItem>

                <GridLayoutItem col={1} row={3} className="form-inner">
                  <Label className="form-heading"> Add Ads Image:</Label>
                  <div className="k-d-flex" style={{ gap: "15px" }}>
                    <input
                      type="file"
                      hidden
                      accept=".png,.jpg"
                      id="user-profile"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        onProfileChange(e);
                      }}
                    />
                    {featuresData?.pictureUrl ? (
                      <div className="k-d-flex">
                        <div style={{ position: "relative" }}>
                          <img
                            className="user-img"
                            src={isCheck(featuresData?.pictureUrl)}
                            alt="Ad"
                          />
                          <button
                            onClick={onRemoveImage}
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              backgroundColor: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}>
                            <span>X</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <GridLayoutItem col={2} row={1} className="form-inner">
                          <div
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              border: "0.5px solid #d3d3d3",
                              padding: "5px",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "4px",
                              gap: "10px",
                            }}
                            onClick={onSelectProfile}>
                            <IconComponent iconName={"Image"} size={20} />
                            <span className="add-file-text">Choose image</span>
                          </div>
                        </GridLayoutItem>
                      </>
                    )}
                  </div>
                </GridLayoutItem>
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddNewFeatures;
