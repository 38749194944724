import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import KendoButton from "../../common/KendoButton";
import {
  SAVE_COMPANY_PRODUCTS,
  SAVE_MANUFACTURER,
  FETCH_COMPANIES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconComponent } from "../../common/Icon";
import { Loader } from "@progress/kendo-react-indicators";
import ProductInput from "../../common/ProductInput";

const AddNewCompany = ({
  showForm,
  onClose,
  fetchProductCompany,
  getObjectForApiCall,
}) => {
  const { productID } = useParams();
  const [isFormValid, setIsFormValid] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [newCompnayName, setNewCompanyName] = useState("");
  const [company, setCompnay] = useState(null);
  const [companyOpen, setCompnayOpen] = useState(false);
  const [searchCompanyLoader, setSearchcompanyLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const DebounceTimeout = useRef(null);

  useEffect(() => {
    if (showForm) {
      getCompanyList("");
    }
  }, [showForm]);

  useEffect(() => {
    validateForm();
  }, [newCompnayName]);

  const validateForm = () => {
    setIsFormValid(newCompnayName.trim().length > 0);
  };

  const getCompanyList = async (searchTerm) => {
    try {
      let compayObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: searchTerm,
        UserId: loginData.userId,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            company: false,
          },
        ],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
        IncludeRecordNr: true,
      };
      const companyRes = await PostRequestCall(
        FETCH_COMPANIES,
        compayObj,
        loginData?.token
      );

      let companyData = companyRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setCompanyOptions(companyData ?? []);
    } catch (error) {
      console.log("Error while getting product list :: ", error);
    } finally {
      setSearchcompanyLoader(false);
    }
  };

  const saveCompany = async () => {
    try {
      setSubmitLoader(true);
      let companyId = company?.value;

      if (newCompnayName) {
        const compnayPayload = {
          userId: loginData.userId,
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              Name: newCompnayName,
              phone: "",
              ModifiedBy: loginData.userId,
              RecordStatusId: 1,
              Manufacturer: true,
              CountryCode: "",
              WebSite: "",
            },
          ],
        };
        const companyResponse = await PostRequestCall(
          SAVE_MANUFACTURER,
          compnayPayload,
          loginData?.token
        );
        companyId = companyResponse?.data?.data?.[0]?.objData?.id || 0;
      } else {
        companyId = companyId;
      }

      if (companyId) {
        const companyPayload = {
          UserId: loginData.userId,
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              ProductId: productID,
              CompanyId: companyId,
              ModifiedBy: loginData.userId,
              RecordStatusId: 1,
            },
          ],
        };

        await PostRequestCall(
          SAVE_COMPANY_PRODUCTS,
          companyPayload,
          loginData?.token
        );

        setSubmitLoader(false);
        setCompnay(null);
        setNewCompanyName("");
        setCompanyOptions([]);
        onClose();
        fetchProductCompany(getObjectForApiCall());
      }
    } catch (error) {
      setSubmitLoader(false);
      console.log("Error while saving product ::", error);
    }
  };

  const onCompanyChange = (event) => {
    const value = event?.target?.value;

    const selected = companyOptions.find((s) => s.label === event.target.value);
    setNewCompanyName(value);
    setCompnay(selected || null);

    if (value?.length > 2) {
      setSearchcompanyLoader(true);
      if (DebounceTimeout.current) {
        clearTimeout(DebounceTimeout.current);
      }
      DebounceTimeout.current = setTimeout(() => {
        getCompanyList(value);
      }, 1000);
    } else {
      setCompanyOptions([]);
    }
  };

  const handleClose = () => {
    setCompnay(null);
    setNewCompanyName("");
    setCompanyOptions([]);
    onClose();
  };
  if (!showForm) return null;

  return (
    <div style={overlayStyles.overlay}>
      <div style={overlayStyles.overlayContent}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconComponent size={25} iconName="X" onClick={handleClose} />
        </div>
        <h3>Add New Product</h3>
        <div style={overlayStyles.formGroup}>
          <ProductInput boldLabel={true} disabled productId={productID} />
        </div>
        <div>
          <div style={overlayStyles.formGroup}>
            <p>
              <b>Company:</b>
            </p>

            <div style={{ position: "relative" }}>
              <AutoComplete
                data={companyOptions}
                value={company?.label ?? newCompnayName}
                className="field-ip"
                onChange={onCompanyChange}
                textField="label"
                opened={companyOpen}
                placeholder="Select company..."
                itemRender={(li, itemProps) => {
                  const trade = itemProps.dataItem;
                  return (
                    <div
                      className="user-item"
                      onClick={() => {
                        setCompnay(trade);
                        setCompnayOpen(false);
                      }}>
                      <span>{trade.label}</span>
                    </div>
                  );
                }}
                onFocus={() => setCompnayOpen(true)}
                onBlur={() => setCompnayOpen(false)}
              />
              {company?.label?.trim() === "" && (
                <div
                  style={{
                    position: "absolute",
                    top: "35%",
                    right: "10px",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={() => setCompnayOpen((prev) => !prev)}>
                  <IconComponent
                    size={20}
                    iconName={companyOpen ? "ChevronUp" : "ChevronDown"}
                    color="#B5B5B5"
                  />
                </div>
              )}
              {searchCompanyLoader === true &&
                company?.label?.trim() !== "" && (
                  <div
                    style={{
                      position: "absolute",
                      top: "10%",
                      right: "8px",
                      zIndex: 9999,
                      backgroundColor: "#fff",
                    }}>
                    <Loader size="small" style={{ color: "#083DB8" }} />
                  </div>
                )}
              {newCompnayName.trim() !== "" && !company && (
                <div style={{ marginTop: "10px" }}>
                  Adding a new Company to the database
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={overlayStyles.actions}>
          {submitLoader ? (
            <div
              className="action-btn-loader"
              style={{ marginLeft: "auto", width: "30px", height: "20px" }}>
              <Loader type={"pulsing"} themeColor="white" size="small" />
            </div>
          ) : (
            <KendoButton
              iconClass=""
              size={16}
              className="action-btn"
              buttonName="Save"
              onClick={saveCompany}
              disabled={submitLoader || (!newCompnayName.trim() && !company)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewCompany;

const overlayStyles = {
  overlay: {
    position: "fixed",
    top: "67px",
    right: 0,
    width: "400px",
    height: "100%",
    background: "white",
    boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
    transform: "translateX(0)",
    transition: "transform 0.3s ease-in-out",
    borderRadius: "10px 0 0 10px",
  },
  overlayContent: {
    padding: "20px",
  },
  closeBtn: {
    float: "right",
  },
  formGroup: {
    marginTop: "15px",
  },
  actions: {
    marginTop: "20px",
    textAlign: "right",
  },
};
