import {
  GridLayout,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import { PostRequestCall } from "../../apicall/PostRequest";
import { FECTH_TRADE_PRODUCTS, FETCH_PRODUCTS } from "../../constant/Apipath";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ProductProfileSection from "./ProductProfileSection";
import ProductCompanyList from "./ProductCompanyList";
import ProductProjectList from "./ProductProjectList";
import ProductPicturesList from "./ProductPicturesList";

function ProductDetailPage() {
  const { productID } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const [showLoader, setShowLoader] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [productData, setProductData] = useState({
    productDetails: null,
    productAddress: [],
    tradeList: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const [productDetails, tradeList, productAddress] = await Promise.all([
        fetchProductDetails().catch((e) => null),
        getProductTradeList().catch((e) => []),
      ]);
      setProductData({
        productDetails,
        productAddress,
        tradeList,
      });
    } catch (error) {
      console.log("Error while getting company details :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchProductDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: productID }] };
      const res = await PostRequestCall(FETCH_PRODUCTS, obj, loginData?.token);

      let resData = res?.data?.data?.[0] ?? null;

      return resData;
    } catch (error) {
      console.log("Error while getting compnay details :: ", error);
    }
  };

  const getProductTradeList = async () => {
    try {
      let obj = {
        SearchList: [{ ProductId: productID }],
      };
      const res = await PostRequestCall(
        FECTH_TRADE_PRODUCTS,
        obj,
        loginData?.token
      );

      let resData = res?.data?.data ?? [];

      return resData ?? [];
    } catch (error) {
      console.log("Error while getting trade list :: ", error);
    }
  };

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const onDataSaveSuccessfully = async (
    updateCompanyDetails,
    updateTradeDetails
  ) => {
    try {
      setShowLoader(true);
      const apiCalls = [];
      const previousProductData = { ...productData };

      if (updateCompanyDetails) {
        apiCalls.push(fetchProductDetails().catch((e) => null));
      } else {
        apiCalls.push(Promise.resolve(previousProductData.productDetails));
      }

      if (updateTradeDetails) {
        apiCalls.push(getProductTradeList().catch((e) => []));
      } else {
        apiCalls.push(Promise.resolve(previousProductData.tradeList));
      }

      const [productDetails, tradeList] = await Promise.all(apiCalls);

      setProductData({
        productDetails: updateCompanyDetails
          ? productDetails
          : previousProductData.productDetails,
        tradeList: updateTradeDetails
          ? tradeList
          : previousProductData.tradeList,
      });
    } catch (error) {
      console.log("Error while getting company details :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div>
      <div
        className="report-section"
        style={{ height: "100%", overflow: "hidden" }}>
        <GridLayout
          rows={[{ height: "100%" }]}
          cols={[{ width: "35%" }, { width: "65%" }]}
          gap={{ rows: 1, cols: 0 }}
          style={{
            height: "100%",
            overflow: "hidden",
            borderRadius: "10px",
          }}>
          <div style={{ position: "relative", width: "100%" }}>
            <ProductProfileSection
              productDetails={productData?.productDetails}
              tradeList={productData?.tradeList ?? []}
              callUpdatedProdcutData={onDataSaveSuccessfully}
            />
          </div>

          <div
            style={{
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              backgroundColor: "#fff",
              paddingLeft: "10px",
              borderRadius: "6px",
            }}
            className="company_tab_strip_conatiner">
            <TabStrip
              selected={selectedTab}
              onSelect={handleTabSelect}
              style={{
                flexGrow: 1,
                overflow: "hidden",
                paddingTop: "5px",
                height: "100%",
              }}>
              <TabStripTab
                title="Pictures"
                style={{
                  backgroundColor: "red",
                  width: "100%",
                  height: "100%",
                }}>
                <ProductPicturesList />
              </TabStripTab>
              <TabStripTab title="Projects">
                <ProductProjectList />
              </TabStripTab>
              <TabStripTab title="Companies">
                <ProductCompanyList />
              </TabStripTab>
            </TabStrip>
          </div>
        </GridLayout>
      </div>
    </div>
  );
}

export default ProductDetailPage;
