import React, { useState } from "react";
import KendoButton from "../../common/KendoButton";
import {
  SAVE_USERS_SITES_PROJECTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconComponent } from "../../common/Icon";
import { Loader } from "@progress/kendo-react-indicators";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import UserSearchInput from "../../common/UserSearchInput";
import SiteSearchInput from "../../common/SiteSearchInput";
import SiteTypeInput from "../../common/SiteTypeInput";
import { toast } from "react-toastify";

const AddSiteProjectModal = ({
  showForm,
  onClose,
  fetchUserSiteProjects
}) => {

  const { siteId } = useParams();
  const { loginData } = useSelector((state) => state.main);

  const [project, setProject] = useState({
    projectName: "",
    userId: 0,
    userName: "",
    siteId: siteId ?? 0,
    siteName: "",
    projectTypeId: 0,
    projectType: "",
    active: false,
    description: "",
    projectWebsite: "",
    recordStatusId: 1,
    recordStatus: "New",
  });


  const [submitLoader, setSubmitLoader] = useState(false);

  const onInputChange = (e) => {
    setProject({
      ...project,
      [e.target.name]: e.target.value,
    });
  };

  const resetState = () => {
    setProject({
      projectName: "",
      userId: 0,
      userName: "",
      siteId: siteId ?? 0,
      siteName: "",
      projectTypeId: 0,
      projectType: "",
      active: false,
      description: "",
      projectWebsite: "",
      recordStatusId: 1,
      recordStatus: "New",
    });
    setSubmitLoader(false);
    onClose();
  }

  const onSave = async () => {
    if (project?.projectName?.trim() === "") {
      toast.error("Please enter a valid project name", {
        position: "top-right",
      });
      return;
    }

    if (project?.userId === 0) {
      toast.error("Please select a valid user", {
        position: "top-right",
      });
      return;
    }

    if (project?.siteId === 0) {
      toast.error("Please select a valid site", {
        position: "top-right",
      });
      return;
    }

    try {
      setSubmitLoader(true);
      const obj = {
        UserId: loginData?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            UserId: project?.userId ?? 0,
            SiteId: project?.siteId,
            ProjectName: project?.projectName,
            RecordStatusId: project?.recordStatusId ?? 1,
            Description: project?.description,
            ProjectType: project?.projectTypeId,
            ProjectWebsite: project?.projectWebsite,
            IsActive: project?.active,
            ModifiedBy: loginData.userId,
          },
        ],
      };

      const result = await PostRequestCall(
        SAVE_USERS_SITES_PROJECTS,
        obj,
        loginData?.token
      );

      if (result?.data?.status === false) {
        setSubmitLoader(false);
        toast.error(
          result?.data?.data?.[0]?.message ??
          result?.data?.message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setSubmitLoader(false);
        toast.success(
          `Data added successfully`,
          {
            position: "top-right",
          }
        );
        fetchUserSiteProjects()
      }
    } catch (error) {
      console.log("Error while saving new project :: ", error)
    } finally {
      resetState();
    }
  }

  if (!showForm) return null;

  return (
    <div style={overlayStyles.overlay}>
      <div style={overlayStyles.overlayContent} className="hide_scrollbar">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconComponent
            size={25}
            iconName="X"
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <h3>Add New Project</h3>
        <div style={overlayStyles.formGroup}>
          <p style={overlayStyles.formLabel}>Project Name:</p>
          <Input
            type="text"
            id="projectName"
            name="projectName"
            placeholder="Enter a valid project name"
            value={project?.projectName}
            className="field-ip"
            onChange={(e) => onInputChange(e)}
          />
        </div>
        <div style={overlayStyles.formGroup}>
          <p style={overlayStyles.formLabel}>User:</p>
          <UserSearchInput
            hideLabel
            value={project?.userName}
            setSelectedUser={({ label, id }) => {
              setProject((preValue) => ({
                ...preValue,
                userName: label,
                userId: id,
              }));
            }}
          />
        </div>
        <div style={overlayStyles.formGroup}>
          <p style={overlayStyles.formLabel}>Site:</p>
          <SiteSearchInput
            value={project?.siteName}
            setSelectedSite={({ label, id }) => {
              setProject((preValue) => ({
                ...preValue,
                siteName: label,
                siteId: id,
              }));
            }}
            disabled={siteId}
            siteId={siteId}
            hideLabel
          />
        </div>
        <div style={overlayStyles.formGroup}>
          <p style={overlayStyles.formLabel}>Project Type:</p>
          <SiteTypeInput
            placeholder="Search for project type..."
            value={project?.projectType}
            setSelectedSiteType={({ label, id }) => {
              setProject((preValue) => ({
                ...preValue,
                projectType: label,
                projectTypeId: id,
              }));
            }}
            hideLabel
          />
        </div>
        <div style={overlayStyles.formGroup}>
          <p style={overlayStyles.formLabel}>Project Website:</p>
          <Input
            type="url"
            id="projectWebsite"
            name="projectWebsite"
            value={project?.projectWebsite}
            className="field-ip"
            onChange={(e) => onInputChange(e)}
            placeholder="Enter a valid project website url"
          />
        </div>
        <div style={overlayStyles.formGroup}>
          <p style={overlayStyles.formLabel}>Description:</p>
          <TextArea
            type="text"
            id="description"
            name="description"
            placeholder="Enter a description"
            value={project?.description}
            className="field-ip"
            onChange={onInputChange}
            rows={4}
          />
        </div>
        <div style={overlayStyles.formGroup}>
          <p style={overlayStyles.formLabel}>Active:</p>
          <Switch
            size={"small"}
            checked={project?.active}
            onChange={(e) => {
              setProject((preValue) => ({
                ...preValue,
                active: e?.value,
              }));
            }}
          />
        </div>
        <div style={overlayStyles.actions}>
          {
            submitLoader ?
              (
                <div className="action-btn-loader" style={{ marginLeft: 'auto', width: '30px', height: '20px' }}>
                  <Loader type={"pulsing"} themeColor="white" size='small' />
                </div>
              )
              :
              (
                <KendoButton
                  iconClass=""
                  size={16}
                  className="action-btn"
                  buttonName="Save"
                  disabled={project?.projectName?.trim() === ""}
                  onClick={onSave}
                />
              )
          }
        </div>
      </div>
    </div>
  );
};

export default AddSiteProjectModal;

const overlayStyles = {
  overlay: {
    position: "fixed",
    top: "67px",
    right: 0,
    width: "400px",
    height: "100%",
    background: "white",
    boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
    transform: "translateX(0)",
    transition: "transform 0.3s ease-in-out",
    borderRadius: "10px 0 0 10px",
  },
  overlayContent: {
    padding: "20px",
    height: "85%",
  },
  closeBtn: {
    float: "right",
  },
  formGroup: {
    marginTop: "15px",
  },
  formLabel: {
    marginBottom: '5px'
  },
  actions: {
    marginTop: "20px",
    textAlign: "right",
  },
};
