import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_POSTS_COMMENTS,
  DELETE_USER_TRADES,
  FETCH_POSTS_COMMENTS,
  FETCH_USERS_TRADES,
  SAVE_POSTS_COMMENTS,
  SAVE_USERS_TRADES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import UserSearchInput from "../../common/UserSearchInput";
import PostIdSearchInput from "../../common/PostIdSearchInput";
import RecordStatusInput from "../../common/RecordStatusInput";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import TradeSearchInput from "../../common/TradeSearchInput";

const AddNewUserTrades = () => {
  const { dataId, userId, subMenu } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-user-trade";

  const [trade, setTrade] = useState({
    userName: "",
    userId: userId ?? 0,
    tradeId: 0,
    tradeName: "",
    tradeType: "Select trade type",
    mutePushNotifications: false,
    hidePostsOnNewsFeed: false,
    recordStatus: "New",
    recordStatusId: 1,
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [footerInfo, setFooterInfo] = useState({});

  useEffect(() => {
    if (!isAddForm) {
      getTradeDetails();
    }
  }, []);

  const getTradeDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: dataId }] };
      const res = await PostRequestCall(
        FETCH_USERS_TRADES,
        obj,
        loginData?.token
      );

      let resData = res?.data?.data?.[0] ?? {};
      setTrade((preValue) => ({
        ...preValue,
        userName: resData?.userName ?? "",
        userId: resData?.userId ?? 0,
        tradeId: resData?.tradeId ?? 0,
        tradeName: resData?.tradeName ?? "",
        tradeType: resData?.tradeType ?? "",
        mutePushNotifications: resData?.mutePushNotifications,
        hidePostsOnNewsFeed: resData?.hidePostsOnNewsFeed,
        recordStatus: resData?.recordStatus ?? "New",
        recordStatusId: resData?.recordStatusId ?? 1,
      }));
      let footerObj = {
        dateCreated: resData?.dateCreated ?? null,
        modifiedByUser: resData?.modifiedByUser ?? "",
        dateModified: resData?.dateModified ?? "",
        createdByUser: resData?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    } catch (error) {
      console.log("Error while getting post comment details :: ", error);
    }
  };

  const saveCurrentData = async () => {
    if (trade?.userId === 0) {
      toast.error("Please select a valid user", {
        position: "top-right",
      });
      return;
    }

    if (trade?.tradeId === 0) {
      toast.error("Please select a valid trade", {
        position: "top-right",
      });
      return;
    }
    try {
      setShowLoader(true);
      const obj = {
        UserId: loginData?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: isAddForm ? 0 : dataId,
            UserId: trade.userId,
            RecordStatusId: trade?.recordStatusId || 1,
            TradeId: trade?.tradeId,
            MutePushNotifications: trade?.mutePushNotifications,
            HidePostsOnNewsFeed: trade?.hidePostsOnNewsFeed,
            TradeType: trade.tradeType === "Main" ? "Main" : "Other",
            ModifiedBy: loginData?.userId,
          },
        ],
      };

      const result = await PostRequestCall(
        SAVE_USERS_TRADES,
        obj,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.data?.[0]?.message ??
          result?.data?.message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error(
        error?.data?.data?.[0]?.message ??
        error?.data?.message ??
        "Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
      console.log("Error while saving trade data :: ", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = [
        {
          Id: Number(dataId),
          ModifiedBy: loginData?.userId,
        },
      ];
      const obj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: deleteObj,
      };
      return PostRequestCall(DELETE_USER_TRADES, obj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_95">
        <AddPageLayout
          dataId={dataId}
          addForm={isAddForm}
          showFooter={!isAddForm}
          footerInfo={footerInfo}
          isFieldEdited={isFieldEdited}
          recordText={"User Trade ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}>
          <div style={{ height: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <UserSearchInput
                      value={trade?.userName}
                      setSelectedUser={({ label, id }) => {
                        setIsFiledEdited(true);
                        setTrade((preValue) => ({
                          ...preValue,
                          userName: label,
                          userId: id,
                        }));
                      }}
                      disabled={true}
                      userId={userId}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <TradeSearchInput
                      value={trade?.tradeName}
                      setSelectedTrade={({ label, id }) => {
                        setIsFiledEdited(true);
                        setTrade((preValue) => ({
                          ...preValue,
                          tradeName: label,
                          tradeId: id,
                        }));
                      }}
                      disabled={!isAddForm}
                      tradeId={trade?.tradeId}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <label className="form-heading">Trade Type:</label>
                    <DropDownList
                      data={[
                        { text: "Main", id: "Main" },
                        { text: "Other", id: "Other" },
                      ]}
                      value={{
                        id: trade?.tradeTypeId,
                        text: trade?.tradeType,
                      }}
                      textField="text"
                      dataItemKey="text"
                      className="dropdown-list"
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setTrade({
                          ...trade,
                          tradeType: e.value.text,
                          tradeTypeId: e.value.id,
                        });
                      }}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <label className="form-heading">
                      Mute Push Notifications:
                    </label>
                    <div>
                      <Switch
                        size={"small"}
                        checked={trade?.mutePushNotifications}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setTrade((preValue) => ({
                            ...preValue,
                            mutePushNotifications: e?.value,
                          }));
                        }}
                      />
                    </div>
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <label className="form-heading">
                      Hide Posts On NewsFeed:
                    </label>
                    <div>
                      <Switch
                        size={"small"}
                        checked={trade?.hidePostsOnNewsFeed}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setTrade((preValue) => ({
                            ...preValue,
                            hidePostsOnNewsFeed: e?.value,
                          }));
                        }}
                      />
                    </div>
                  </GridLayoutItem>

                  {!isAddForm ||
                    (subMenu && (
                      <GridLayoutItem col={3} row={2} className="form-inner">
                        <RecordStatusInput
                          recordStatusId={trade?.recordStatusId}
                          recordStatus={trade?.recordStatus}
                          onChange={({ recordStatus, recordStatusId }) => {
                            setIsFiledEdited(true);
                            setTrade((preValue) => ({
                              ...preValue,
                              recordStatus,
                              recordStatusId,
                            }));
                          }}
                        />
                      </GridLayoutItem>
                    ))}
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default AddNewUserTrades;
