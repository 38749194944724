import {
    GridLayout,
    TabStrip,
    TabStripTab,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ProjectProfileSection from "./ProjectProfileSection";
import { FETCH_USER_SITES_PROJECTS } from "../constant/Apipath";
import { PostRequestCall } from "../apicall/PostRequest";
import ProjectCompanySection from "./ProjectCompanySection";
import ProjectProductSection from "./ProjectProductSection";

function ProjectDetails() {
    const { siteId, projectId } = useParams();
    const { loginData } = useSelector((state) => state.main);

    const [showLoader, setShowLoader] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);

    const [projectData, setProjectData] = useState({
        projectDetails: null,
        companyAddress: [],
        tradeList: [],
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const [projectDetails] = await Promise.all([
                fetchProjectDetails().catch((e) => null),
            ]);
            setProjectData({
                projectDetails,
            });
        } catch (error) {
            console.log("Error while getting project details :: ", error);
        } finally {
            setShowLoader(false);
        }
    };

    const fetchProjectDetails = async () => {
        try {
            let obj = { SearchList: [{ Id: projectId }] };
            const res = await PostRequestCall(FETCH_USER_SITES_PROJECTS, obj, loginData?.token);
            let resData = res?.data?.data?.[0] ?? null;
            return resData;
        } catch (error) {
            console.log("Error while getting project details :: ", error);
        }
    };


    const handleTabSelect = (e) => {
        setSelectedTab(e.selected);
    };

    const callProjectData = async () => {
        try {
            setShowLoader(true);
            let resData = await fetchProjectDetails();
            setProjectData((prevData) => ({ ...prevData, projectDetails: resData ?? null }))
        } catch (error) {
            console.log("Error while getting project details :: ", error);
        } finally {
            setShowLoader(false);
        }
    }

    if (showLoader) {
        return (
            <Loader
                type="converging-spinner"
                className="kendo-spinner"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "500px",
                    alignItems: "center",
                }}
            />
        );
    }

    return (
        <div>
            <div
                className="report-section"
                style={{ height: "100%", overflow: "hidden" }}>
                <GridLayout
                    rows={[{ height: "100%" }]}
                    cols={[{ width: "35%" }, { width: "65%" }]}
                    gap={{ rows: 1, cols: 0 }}
                    style={{
                        height: "100%",
                        overflow: "hidden",
                        borderRadius: "10px",
                    }}>
                    <div style={{ position: "relative", width: "100%" }}>
                        <ProjectProfileSection
                            projectDetails={projectData?.projectDetails}
                            callProjectData={callProjectData}
                        />
                    </div>

                    <div
                        style={{
                            height: "90vh",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                            backgroundColor: "#fff",
                            paddingLeft: '10px',
                            borderRadius: '6px'
                        }}
                        className="company_tab_strip_conatiner"
                    >
                        <TabStrip
                            selected={selectedTab}
                            onSelect={handleTabSelect}
                            style={{
                                flexGrow: 1,
                                overflow: "hidden",
                                paddingTop: "5px",
                                height: "100%",
                            }}>
                            <TabStripTab title="Companies" style={{ backgroundColor: 'red', width: '100%', height: '100%' }}>
                                <ProjectCompanySection />
                            </TabStripTab>
                            <TabStripTab title="Products" style={{ backgroundColor: 'red', width: '100%', height: '100%' }}>
                                <ProjectProductSection />
                            </TabStripTab>
                        </TabStrip>
                    </div>
                </GridLayout>
            </div>
        </div>
    );
}

export default ProjectDetails;


