import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import KendoButton from "../../common/KendoButton";
import {
  FETCH_USER_SITES_PROJECTS,
  FETCH_SITES,
  FETCH_PROJECT_COMPANY_ROLES,
  SAVE_SITE,
  SAVE_USERS_SITES_PROJECTS,
  SAVE_PROJECT_COMPANIES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconComponent } from "../../common/Icon";
import { Loader } from "@progress/kendo-react-indicators";

const AddNewProject = ({
  showForm,
  onClose,
  fetchCompanieProjects,
  getObjectForApiCall,
}) => {
  const { loginData } = useSelector((state) => state.main);
  const autoCompleteRef = useRef(null);
  const { companyId } = useParams();
  const [setQuery] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [companyRoles, setCompanyRoles] = useState([]);
  const [projectValue, setProjectValue] = useState("");
  const [siteValue, setSiteValue] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [projectOpen, setProjectOpen] = useState(false);
  const [siteOpen, setSiteOpen] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchSiteLoader, setSearchSiteLoader] = useState(false);
  const projectDebounceTimeout = useRef(null);
  const [site, setSite] = useState({
    name: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    country: "",
    longitude: "",
    latitude: "",
    active: false,
    recordStatusId: 1,
    recordStatus: "New",
  });
  const [submitLoader, setSubmitLoader] = useState(false);

  useEffect(() => {
    const element = document.getElementById("location_div_for_address");
    if (element) {
      element.style.visibility = "hidden";
      element.style.height = "0px";
    }
  }, []);

  useEffect(() => {
    if (selectedSite) {
      const element = document.getElementById("location_div_for_address");
      if (element) {
        element.style.visibility = "hidden";
        element.style.height = "0px";
      }
    }
  }, [selectedSite]);

  useEffect(() => {
    if (siteValue === "") {
      const element = document.getElementById("location_div_for_address");
      if (element) {
        element.style.visibility = "hidden";
        element.style.height = "0px";
        if (autoCompleteRef.current) {
          autoCompleteRef.current.value = ""; // Clear the value of the input field
        }
      }
    }
  }, [siteValue]);

  const fetchProjectData = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: projectValue,
        UserId: loginData?.userId,
        FetchAllowedRecordsOnly: false,
        SearchList: [{}],
        IncludeRecordNr: true,
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
      });
      const result = await PostRequestCall(
        FETCH_USER_SITES_PROJECTS,
        data,
        loginData?.token
      );
      const users = result.data.data || [];
      const userOptions = users.map((user) => ({
        value: user.id,
        label: user.projectName,
        siteName: user.siteName,
        siteId: user.siteId,
        address: user.address,
      }));
      setProjectOptions(userOptions ?? []);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setSearchLoader(false);
    }
  };

  const fetchSiteData = async () => {
    try {
      // if (!siteValue.trim()) return;

      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 10,
        FullSearch: siteValue,
        UserId: loginData?.userId,
        IncludeRecordNr: true,

        FetchAllowedRecordsOnly: false,
        SearchList: [{}],
        SortList: [
          {
            FieldName: "Id",
            Direction: "DESC",
          },
        ],
      });
      const result = await PostRequestCall(FETCH_SITES, data, loginData?.token);
      const sites = result.data.data || [];
      const siteOptions = sites.map((site) => ({
        value: site.id,
        label: site.name,
        address: site.country,
      }));
      setSiteOptions(siteOptions);
    } catch (error) {
      console.error("Error fetching sites:", error);
    } finally {
      setSearchSiteLoader(false);
    }
  };
  useEffect(() => {
    if (showForm) {
      fetchProjectData("");
    }
  }, [showForm]);
  useEffect(() => {
    if (showForm) {
      fetchSiteData("");
    }
  }, [showForm]);
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const fetchCompanyRoles = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData?.userId,
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [{}],
        SortList: [
          {
            FieldName: "Id",
            Direction: "DESC",
          },
        ],
      });
      const result = await PostRequestCall(
        FETCH_PROJECT_COMPANY_ROLES,
        data,
        loginData?.token
      );
      const attributesData = result?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setCompanyRoles(attributesData || []);
    } catch (error) {
      console.error("Error fetching company roles:", error);
    }
  };

  useEffect(() => {
    const isValid = projectValue.trim() && selectedRole;
    setIsFormValid(isValid);
  }, [projectValue, selectedRole, selectedSite]);
  useEffect(() => {
    fetchCompanyRoles();
  }, []);

  const resetForm = () => {
    setProjectValue("");
    setSiteValue("");
    setSelectedProject(null);
    setSelectedSite(null);
    setSelectedRole(null);
    setSite({
      name: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      longitude: "",
      latitude: "",
      active: false,
      recordStatusId: 1,
      recordStatus: "New",
    });
    setIsFormValid(false);
  };

  const handleSave = async () => {
    try {
      setSubmitLoader(true);
      let siteId = null;
      let projectId = null;

      if (selectedProject) {
        projectId = selectedProject.value;
        siteId = selectedProject.siteId;
      } else {
        if (!selectedSite && siteValue.trim() !== "") {
          const saveSiteData = {
            UserId: loginData?.userId,
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                Name: siteValue,
                Address: site?.address,
                City: site?.city,
                Province: site?.province,
                PostalCode: site?.postalCode,
                Country: site?.country,
                Latitude: site?.latitude,
                Longitude: site?.longitude,
                Active: true,
                RecordStatusId: 1,
                ModifiedBy: loginData?.userId,
                Description: "",
              },
            ],
          };

          const saveSiteResult = await PostRequestCall(
            SAVE_SITE,
            JSON.stringify(saveSiteData),
            loginData?.token
          );

          if (saveSiteResult?.data?.status === true) {
            siteId = saveSiteResult.data.data?.[0]?.objData?.id;
          } else {
            alert("Failed to save the site. Please try again.");
            return;
          }
        } else if (selectedSite) {
          siteId = selectedSite.value;
        }

        const saveProjectData = {
          UserId: loginData?.userId,
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              UserId: loginData?.userId,
              SiteId: siteId,
              ProjectName: projectValue,
              RecordStatusId: 1,
              Description: "",
              ProjectType: 0,
              ProjectWebsite: "",
              IsActive: true,
              ModifiedBy: loginData?.userId,
            },
          ],
        };

        const saveProjectResult = await PostRequestCall(
          SAVE_USERS_SITES_PROJECTS,
          saveProjectData,
          loginData?.token
        );

        if (saveProjectResult?.data?.status === true) {
          projectId = saveProjectResult?.data?.data?.[0]?.objData?.id;
        } else {
          alert("Failed to save the project. Please try again.");
          return;
        }
      }
      await PostRequestCall(
        SAVE_PROJECT_COMPANIES,
        JSON.stringify([
          {
            Id: 0,
            ProjectId: projectId,
            projectCompanyRoleId: selectedRole?.id,
            CompanyId: companyId,
            ModifiedBy: loginData?.userId,
            recordStatusId: 1,
          },
        ]),
        loginData?.token
      );

      setSubmitLoader(false);
      resetForm();
      onClose();
      fetchCompanieProjects(getObjectForApiCall());
    } catch (error) {
      setSubmitLoader(false);
      console.error("Error during project save:", error);
      alert("An error occurred while saving the project. Please try again.");
    }
  };

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef, site) => {
    const autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);

    autoComplete.addListener("place_changed", () => {
      const place = autoComplete?.getPlace();
      const addressComponents = place?.address_components ?? null;
      let address = "";
      let city = "";
      let province = "";
      let postalCode = "";
      let country = "";
      let longitude = "";
      let latitude = "";
      addressComponents?.forEach((component) => {
        switch (component?.types[0]) {
          case "street_number":
            address = component?.long_name + ", ";
            break;
          case "locality":
            city = component?.long_name;
            break;
          case "administrative_area_level_1":
            province = component?.long_name;
            break;
          case "postal_code":
            postalCode = component?.long_name;
            break;
          case "country":
            country = component?.long_name;
            break;
          default:
            break;
        }
      });

      if (place?.geometry && place?.geometry?.location) {
        latitude = place?.geometry?.location.lat();
        longitude = place?.geometry?.location.lng();
      }

      setSite((prevState) => ({
        ...prevState,
        address,
        city,
        province,
        postalCode,
        country,
        latitude,
        longitude,
      }));
    });
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDFLELJHL-t3S5DkTgHRPWVBNpg8uW76Kc&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, setSite)
    );
  }, []);

  if (!showForm) return null;

  return (
    <div style={overlayStyles.overlay}>
      <div style={overlayStyles.overlayContent}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconComponent
            size={25}
            iconName="X"
            onClick={() => {
              resetForm();
              onClose();
            }}
          />
        </div>
        <h3>Add New Project</h3>
        <div style={overlayStyles.formGroup}>
          <p>
            <b>Project:</b>
          </p>

          <AutoComplete
            data={projectOptions}
            value={projectValue}
            onChange={async (event) => {
              const searchTerm = event.target.value;
              setProjectValue(searchTerm);
              const selected = projectOptions.find(
                (p) => p.label === searchTerm
              );
              setSelectedProject(selected || null);
              if (searchTerm?.length > 2) {
                setSearchLoader(true);
                setProjectOpen(true);
                if (projectDebounceTimeout.current) {
                  clearTimeout(projectDebounceTimeout.current);
                }
                projectDebounceTimeout.current = setTimeout(() => {
                  fetchProjectData();
                }, 1000);
              } else {
                setProjectOpen(false);
                setProjectOptions([]);
              }
            }}
            textField="label"
            opened={projectOpen}
            placeholder="Search for projects..."
            itemRender={(li, itemProps) => {
              const project = itemProps.dataItem || selectedProject;
              return (
                <div
                  className="user-item na_selection_text"
                  onClick={() => {
                    setSearchLoader(false);
                    setProjectValue(project.label);
                    setSelectedProject(project);
                    setProjectOpen(false);
                  }}>
                  <span>{project?.label}</span>
                </div>
              );
            }}
            onFocus={() => setProjectOpen(true)}
            onBlur={() => setProjectOpen(false)}
          />

          {projectValue.trim() === "" && (
            <div
              style={{
                position: "absolute",
                top: "18%",
                right: "30px",
                zIndex: 1,
              }}
              onClick={() => setProjectOpen((prev) => !prev)}>
              <IconComponent
                size={15}
                iconName={projectOpen ? "ChevronUp" : "ChevronDown"}
                color="#B5B5B5"
              />
            </div>
          )}

          {searchLoader === true && projectValue.trim() !== "" && (
            <div
              style={{
                position: "absolute",
                top: "17.5%",
                right: "28px",
                zIndex: 999,
                backgroundColor: "white",
              }}>
              <Loader size="small" style={{ color: "#083DB8" }} />
            </div>
          )}

          {selectedProject ? (
            <div style={{ marginTop: "10px" }}>
              <p>
                <strong>Site Name:</strong>
                {selectedProject?.siteName?.trim() !== ""
                  ? selectedProject?.siteName
                  : "--"}
              </p>
              <p>
                <strong>Address:</strong>{" "}
                {selectedProject?.address?.trim() !== ""
                  ? selectedProject?.address
                  : "--"}
              </p>
            </div>
          ) : (
            projectValue && (
              <div>
                <p>Adding a new Project to the database:</p>
                <p>
                  <b>Site:</b>
                </p>

                <div style={{ position: "relative" }}>
                  <AutoComplete
                    data={siteOptions}
                    value={siteValue}
                    onChange={async (event) => {
                      const searchTerm = event.target.value;
                      setSiteValue(searchTerm);

                      const selected = siteOptions.find(
                        (p) => p.label === searchTerm
                      );
                      setSelectedSite(selected || null);

                      if (!selected && siteValue?.length !== 0) {
                        const element = document.getElementById(
                          "location_div_for_address"
                        );
                        if (element) {
                          element.style.visibility = "visible";
                          element.style.height = "auto";
                        }
                      }

                      if (searchTerm?.length > 2) {
                        setSearchSiteLoader(true);
                        setSiteOpen(true);
                        if (projectDebounceTimeout.current) {
                          clearTimeout(projectDebounceTimeout.current);
                        }
                        projectDebounceTimeout.current = setTimeout(() => {
                          fetchSiteData();
                        }, 1000);
                      } else {
                        setSiteOpen(false);
                        setSiteOptions([]);
                      }
                    }}
                    textField="label"
                    opened={siteOpen}
                    placeholder="Choose a Site..."
                    itemRender={(li, itemProps) => {
                      const site = itemProps.dataItem || setSelectedSite;
                      return (
                        <div
                          className="user-item na_selection_text"
                          onClick={() => {
                            setSearchSiteLoader(false);
                            setSiteValue(site.label);
                            setSelectedSite(site);
                            setSiteOpen(false);
                          }}>
                          <span>{site?.label}</span>
                        </div>
                      );
                    }}
                    onFocus={() => setSiteOpen(true)}
                    onBlur={() => setSiteOpen(false)}
                  />
                  {siteValue.trim() === "" && (
                    <div
                      style={{
                        position: "absolute",
                        top: "25%",
                        right: "8px",
                        zIndex: 1,
                      }}
                      onClick={() => setSiteOpen((prev) => !prev)}>
                      <IconComponent
                        size={15}
                        iconName={siteOpen ? "ChevronUp" : "ChevronDown"}
                        color="#B5B5B5"
                      />
                    </div>
                  )}
                </div>

                {searchSiteLoader === true && siteValue.trim() !== "" && (
                  <div
                    style={{
                      position: "absolute",
                      top: "29%",
                      right: "28px",
                      zIndex: 999,
                      backgroundColor: "white",
                    }}>
                    <Loader size="small" style={{ color: "#083DB8" }} />
                  </div>
                )}

                {selectedSite && (
                  <p>
                    <strong>Address:</strong> {selectedSite.address}
                  </p>
                )}
              </div>
            )
          )}

          <div id={"location_div_for_address"}>
            <p>Adding a new Site to the database:</p>
            <p>
              <b>Location:</b>
            </p>
            <input
              type="text"
              ref={autoCompleteRef}
              placeholder="Enter address"
              style={{
                padding: "10px",
                width: "95%",
                height: "7px",
                borderRadius: "4px",
                outline: "none",
              }}
            />
          </div>
        </div>
        <div style={overlayStyles.formGroup}>
          <p>
            <b>Company Role:</b>
          </p>
          <DropDownList
            data={companyRoles}
            textField="text"
            dataItemKey="id"
            defaultItem={{ text: "Select a role", value: null }}
            value={{
              id: selectedRole?.id,
              text: selectedRole?.text,
            }}
            onChange={handleRoleChange}
          />
        </div>
        <div style={overlayStyles.actions}>
          {submitLoader ? (
            <div
              className="action-btn-loader"
              style={{ marginLeft: "auto", width: "30px", height: "20px" }}>
              <Loader type={"pulsing"} themeColor="white" size="small" />
            </div>
          ) : (
            <KendoButton
              iconClass=""
              size={16}
              className={`action-btn ${!isFormValid ? "disabled-btn" : ""}`}
              buttonName="Save"
              onClick={handleSave}
              disabled={!isFormValid}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewProject;

const overlayStyles = {
  overlay: {
    position: "fixed",
    top: "67px",
    right: 0,
    width: "400px",
    height: "100%",
    background: "white",
    boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
    transform: "translateX(0)",
    transition: "transform 0.3s ease-in-out",
    borderRadius: "10px 0 0 10px",
  },
  overlayContent: {
    padding: "20px",
  },
  closeBtn: {
    float: "right",
  },
  formGroup: {
    marginBottom: "15px",
  },
  actions: {
    marginTop: "20px",
    textAlign: "right",
  },
};
