import React, { useEffect, useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { PostRequestCall } from "../../apicall/PostRequest";
import {
  FETCH_USERS_COMPANIES,
  FETCH_USERS_WORK_HISTORY,
  FetchUserTradeCertifications,
} from "../../constant/Apipath";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "../Reports.css";

const UserCompaniesAndProjects = () => {
  const { loginData } = useSelector((state) => state.main);
  const { id } = useParams();
  const [companies, setCompanies] = useState([]);
  const [education, setEducation] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const companyObj = {
        PageNr: 1,
        FullSearch: "",
        UserId: id,
        IncludeRecordNr: "true",
        SearchList: [{ UserId: id }],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
      };

      const companyRes = await PostRequestCall(
        FETCH_USERS_COMPANIES,
        companyObj,
        loginData?.token
      );
      const companyData = companyRes?.data?.data ?? [];

      const companiesWithProjects = await Promise.all(
        companyData.map(async (company) => {
          const projectObj = {
            PageNr: 1,
            FullSearch: "",
            IncludeRecordNr: true,
            UserId: id,
            SearchList: [{ CompanyId: company.companyId, UserId: id }],
            SortList: [{ FieldName: "Id", Direction: "DESC" }],
          };

          const projectRes = await PostRequestCall(
            FETCH_USERS_WORK_HISTORY,
            projectObj,
            loginData?.token
          );
          const projects = projectRes?.data?.data ?? [];

          return { ...company, projects };
        })
      );

      setCompanies(companiesWithProjects);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEducation = async () => {
    try {
      const educationObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: id,
        IncludeRecordNr: "true",
        SearchList: [{ UserId: id }],
        SortList: [{}],
      };

      const res = await PostRequestCall(
        FetchUserTradeCertifications,
        educationObj,
        loginData?.token
      );
      const educationData = res?.data?.data ?? [];
      setEducation(educationData);
    } catch (error) {
      console.error("Error fetching education data:", error);
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchEducation();
  }, []);

  const calculateDuration = (startDate, endDate) => {
    if (!startDate) return "-";
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();

    const years = end.getFullYear() - start.getFullYear();
    const months = end.getMonth() - start.getMonth();

    const totalYears = months < 0 ? years - 1 : years;
    const totalMonths = months < 0 ? 12 + months : months;

    return `${
      totalYears > 0 ? `${totalYears} year${totalYears > 1 ? "s" : ""}` : ""
    } ${
      totalMonths > 0 ? `${totalMonths} month${totalMonths > 1 ? "s" : ""}` : ""
    }`.trim();
  };

  const formatDate = (dateString, isEndDate = false) => {
    if (!dateString) return isEndDate ? "Present" : "--";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <>
      <div
        className="experience-education-container"
        style={{ display: "flex" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "400px",
            }}>
            <Loader
              type="converging-spinner"
              className="kendo-spinner"
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: "500px",
                alignItems: "center",
              }}
            />
          </div>
        ) : (
          <>
            <div
              className="companies-section"
              style={{
                flex: 2, // Wider section
                marginRight: "10px", // Add spacing between sections
              }}>
              <h5 className="report-title">
                <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Companies
                </span>
              </h5>
              {companies.length > 0 ? (
                companies.map((company, index) => (
                  <Card
                    key={index}
                    style={{ marginBottom: "15px", padding: "10px" }}>
                    <div className="company-header">
                      <div>
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                          {company.companyName}
                        </span>
                        <p style={{ fontSize: "14px", color: "#e6e6e" }}>
                          {company.roleName}
                        </p>
                        <p>{company.description}</p>
                      </div>
                      <div>
                        <p>
                          <strong style={{ fontSize: "14px" }}>
                            {formatDate(company.startDate)} -{" "}
                            {formatDate(company.endDate, true)}
                          </strong>
                        </p>
                        <p style={{ fontSize: "14px", color: "#e6e6e" }}>
                          {calculateDuration(
                            company.startDate,
                            company.endDate
                          )}
                        </p>
                      </div>
                    </div>
                    {company.projects && company.projects.length > 0 && (
                      <div className="project-list">
                        {company.projects.map((project, projectIndex) => (
                          <Card
                            key={projectIndex}
                            className="subproject-card"
                            style={{ margin: "10px 0", padding: "10px" }}>
                            <span
                              style={{ fontSize: "14px", fontWeight: "bold" }}>
                              {project.siteName}
                            </span>
                            <p style={{ fontSize: "14px", color: "#3d3c3c" }}>
                              {project.tradeName}
                            </p>
                            <p>
                              <strong style={{ fontSize: "14px" }}>
                                {formatDate(project.startDate)} -{" "}
                                {formatDate(project.endDate, true)}
                              </strong>
                            </p>
                            <p>{project.projectDescription}</p>
                          </Card>
                        ))}
                      </div>
                    )}
                  </Card>
                ))
              ) : (
                <p>No companies associated with this user.</p>
              )}
            </div>
            <div className="center-line"></div>
            <div
              className="education-section"
              style={{
                flex: 1, // Narrower section
              }}>
              <h5 className="report-title">
                <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Education
                </span>
              </h5>
              {education.length > 0 ? (
                education.map((edu, index) => (
                  <Card
                    key={index}
                    style={{
                      marginBottom: "15px",
                      padding: "10px",
                    }}>
                    <div className="education-card">
                      <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                        {edu.school}
                      </span>
                      <p style={{ fontSize: "15px", fontWeight: "5px" }}>
                        {edu.fieldOfStudy}
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        {formatDate(edu.startDate)} To{" "}
                        {formatDate(edu.endDate, true)}
                      </p>
                    </div>
                  </Card>
                ))
              ) : (
                <p>No education items.</p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserCompaniesAndProjects;
