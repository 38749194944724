import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { FETCH_SITES, SAVE_SITE, SITE_DELETE } from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import RecordStatusInput from "../../common/RecordStatusInput";

const AddSites = () => {
  const { siteId, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = Number(siteId) === 0;

  const [site, setSite] = useState({
    name: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    country: "",
    longitude: "",
    latitude: "",
    active: false,
    recordStatusId: 1,
    recordStatus: "New",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [footerInfo, setFooterInfo] = useState({});

  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef, site) => {
    const autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);

    autoComplete.addListener("place_changed", () => {
      const place = autoComplete?.getPlace();
      const addressComponents = place?.address_components ?? null;

      let address = "";
      let city = "";
      let province = "";
      let postalCode = "";
      let country = "";
      let longitude = "";
      let latitude = "";

      addressComponents?.forEach((component) => {
        switch (component?.types[0]) {
          case "street_number":
            address += component?.long_name + ", ";
            break;
          case "locality":
            city = component?.long_name;
            break;
          case "administrative_area_level_1":
            province = component?.long_name;
            break;
          case "postal_code":
            postalCode = component?.long_name;
            break;
          case "country":
            country = component?.long_name;
            break;
          default:
            break;
        }
      });

      if (place?.geometry && place?.geometry?.location) {
        latitude = place?.geometry?.location.lat();
        longitude = place?.geometry?.location.lng();
      }

      setSite((prevState) => ({
        ...prevState,
        address,
        city,
        province,
        postalCode,
        country,
        latitude,
        longitude,
      }));
    });
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDFLELJHL-t3S5DkTgHRPWVBNpg8uW76Kc&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, setSite)
    );
  }, []);

  useEffect(() => {
    if (!isAddForm) {
      getsiteDetails();
    }
  }, []);

  const getsiteDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: siteId }] };
      const res = await PostRequestCall(FETCH_SITES, obj, loginData?.token);

      let resData = res?.data?.data?.[0] ?? {};
      setSite((preValue) => ({
        ...preValue,
        name: resData?.name ?? "",
        address: resData?.address ?? "",
        city: resData?.city ?? "",
        province: resData?.province ?? "",
        postalCode: resData?.postalCode ?? "",
        country: resData?.country ?? "",
        longitude: resData?.longitude ?? "",
        latitude: resData?.latitude ?? "",
        active: resData?.active ?? false,
        recordStatus: resData?.recordStatus,
        recordStatusId: resData?.recordStatusId,
      }));
      let footerObj = {
        dateCreated: resData?.dateCreated ?? null,
        modifiedByUser: resData?.modifiedByUser ?? "",
        dateModified: resData?.dateModified ?? "",
        createdByUser: resData?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    } catch (error) {
      console.log("Error while getting post comment details :: ", error);
    }
  };

  const onInputChange = (e, number) => {
    let inputValue = e.target.value;
    setSite({
      ...site,
      [e.target.name]: inputValue,
    });
    setIsFiledEdited(true);
  };

  const onAddInputChange = (e) => {
    setIsFiledEdited(true);
    setSite({
      ...site,
      [e.target.name]: e.target.value,
    });
  };

  const saveCurrentData = async () => {
    if (site?.name?.trim() === "") {
      toast.error("Please enter a valid site name", {
        position: "top-right",
      });
      return;
    }

    if (site?.city) {
      const cityRegex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
      if (!cityRegex.test(site?.city)) {
        alert("Please enter valid city name.");
        return false;
      }
    }

    if (site?.country) {
      const cityRegex = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
      if (!cityRegex.test(site?.country)) {
        alert("Please enter valid country name.");
        return false;
      }
    }

    const newsite = {
      UserId: loginData?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: isAddForm ? 0 : siteId,
          Name: site?.name,
          Address: site?.address,
          City: site?.city,
          Province: site?.province,
          PostalCode: site?.postalCode,
          Country: site?.country,
          Longitude: site?.longitude,
          Latitude: site?.latitude,
          Active: site?.active ?? false,
          RecordStatusId: site?.recordStatusId,
          ModifiedBy: isAddForm && userId ? userId : loginData.userId,
        },
      ],
    };
    try {
      setShowLoader(true);
      const res = await PostRequestCall(SAVE_SITE, newsite, loginData?.token);

      if (res?.data?.status === false || res?.data?.status === 400) {
        let errorMessage = res?.data?.errorMessage
          ? res?.data?.errorMessage
          : res?.data?.error
            ? res?.data?.error
            : "Something went wrong. Please try again later.";
        setShowLoader(false);
        toast.error(
          errorMessage ?? "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: siteId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(SITE_DELETE, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_100">
        <AddPageLayout
          dataId={siteId}
          addForm={isAddForm}
          showFooter={!isAddForm}
          footerInfo={footerInfo}
          isFieldEdited={isFieldEdited}
          recordText={"Site ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}>
          <div style={{ height: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <label className="form-heading">Name:</label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      value={site?.name}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <label className="form-heading">Search Address:</label>
                    <input
                      type="text"
                      ref={autoCompleteRef}
                      placeholder="Enter address"
                      className="field-ip"
                      onChange={(e) => onAddInputChange(e)}
                      style={{
                        padding: "10px",
                        width: "95%",
                        height: "7px",
                        borderRadius: "4px",
                        outline: "none",
                      }}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <label className="form-heading"> Address:</label>
                    <Input
                      type="text"
                      id="address"
                      name="address"
                      value={site?.address}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <label className="form-heading">City:</label>
                    <Input
                      type="text"
                      id="city"
                      name="city"
                      value={site?.city}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <label className="form-heading">Province:</label>
                    <Input
                      type="text"
                      id="province"
                      name="province"
                      value={site?.province}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={2} className="form-inner">
                    <label className="form-heading"> Postal Code:</label>
                    <Input
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      value={site?.postalCode}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={3} className="form-inner">
                    <label className="form-heading"> Country:</label>
                    <Input
                      type="text"
                      id="country"
                      name="country"
                      value={site?.country}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={3} className="form-inner">
                    <label className="form-heading">Latitude:</label>
                    <Input
                      type="text"
                      id="country"
                      name="country"
                      value={site?.latitude}
                      className="field-ip"
                      disabled
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={3} className="form-inner">
                    <label className="form-heading">Longitude:</label>
                    <Input
                      type="text"
                      id="country"
                      name="country"
                      value={site?.longitude}
                      className="field-ip"
                      disabled
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={4} className="form-inner">
                    <label className="form-heading">Active:</label>
                    <div>
                      <Switch
                        size={"small"}
                        checked={site?.active}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setSite((preValue) => ({
                            ...preValue,
                            active: e?.value,
                          }));
                        }}
                      />
                    </div>
                  </GridLayoutItem>

                  {!isAddForm && (
                    <GridLayoutItem col={2} row={4} className="form-inner">
                      <RecordStatusInput
                        recordStatusId={site?.recordStatusId}
                        recordStatus={site?.recordStatus}
                        onChange={({ recordStatus, recordStatusId }) => {
                          setIsFiledEdited(true);
                          setSite((preValue) => ({
                            ...preValue,
                            recordStatus,
                            recordStatusId,
                          }));
                        }}
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default AddSites;
