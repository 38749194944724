import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_ADS_CLIENTS,
  DELETE_USER_EXPERIENCES_PRODUCTS,
  FETCH_ADS_CLIENTS,
  FETCH_AUTH_ADS_CLIENTS,
  FETCH_PRODUCTS,
  FETCH_RECORD_STATUSES,
  FETCH_USERS,
  FETCH_USERS_EXPERIENCES_PRODUCTS,
  SAVE_ADS_CLIENTS,
  SAVE_USERS_EXPERIENCES_PRODUCTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../../common/Modal";
import Footerinformation from "../../common/Footerinformation";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from "moment";
import {
  AutoComplete,
  DropDownList,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";

const AddUserExperinceProduct = () => {
  const { dataId } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-user-experince-product";

  const [product, setProduct] = useState({
    userId: 0,
    productId: 0,
    productList: [],
    recordStatusId: 1,
    userExperienceId: 0,
    recordStatus: "New",
  });

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);

  const [user, setUser] = useState("");
  const [showUserList, setShowUserList] = useState(false);
  const [userOptions, setUserOptions] = useState([]);

  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [showProductList, setShowProductList] = useState(false);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    if (!isAddForm) {
      loadData();
      getRecordStatuses();
    }
  }, []);

  const loadData = async () => {
    if (!isAddForm) {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          {
            Id: dataId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      });

      try {
        const result = await PostRequestCall(
          FETCH_USERS_EXPERIENCES_PRODUCTS,
          obj,
          loginData?.token
        );
        if (result?.data?.data && result.data.data.length > 0) {
          let data = result.data.data?.[0];
          setProduct((preValue) => ({ ...preValue, ...data }));
          getUserDetails(data?.userId ?? 0);
          setProductSearchTerm(data?.productName ?? "");
        } else {
          console.log("No data found for the given ID");
        }
      } catch (error) {
        console.log("Error while loading client ads data :: ", error);
      }
    }
  };

  const getUserDetails = async (userId) => {
    try {
      if (userId) {
        const obj = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 0,
          FullSearch: "",
          UserId: loginData.userId,
          SearchList: [{ id: userId }],
          IncludeRecordNr: true,
        });
        const res = await PostRequestCall(FETCH_USERS, obj, loginData?.token);
        setUser(res?.data?.data?.[0]?.name ?? "");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const getUserList = async (onFocus, searchTerm) => {
    try {
      const obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ firstName: searchTerm }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FETCH_USERS,
        obj,
        loginData?.token
      );
      if (getResult?.data?.data?.length > 0) {
        const data = getResult?.data?.data.map((user) => ({
          value: user.id,
          label: `${user?.firstName} ${user?.lastName}`,
        }));
        setUserOptions(data);
        setShowUserList(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getProductList = async (onFocus, searchTerm) => {
    try {
      const obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: searchTerm }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FETCH_PRODUCTS,
        obj,
        loginData?.token
      );
      if (getResult?.data?.data?.length > 0) {
        const list = getResult?.data?.data.map((item) => ({
          value: item.id,
          label: item?.name,
        }));

        if (product?.productList?.length > 0) {
          const filteredProductOptions = list.filter(
            (data) => !ifProductSelected(data)
          );
          setProductOptions(filteredProductOptions);
        } else {
          setProductOptions(list);
        }
        setShowProductList(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const ifProductSelected = (data) => {
    return product?.productList?.some((selectedProduct) => {
      return selectedProduct?.value === data?.value;
    });
  };

  const onUserRender = (li, itemProps) => {
    const selectedUser = itemProps?.dataItem;
    return (
      <div
        className="user-item"
        onClick={() => {
          setShowUserList(false);
          setUser(selectedUser?.label);
          setProduct((prevData) => ({
            ...prevData,
            userId: selectedUser?.value ?? 0,
          }));
        }}>
        <span>{selectedUser?.label}</span>
      </div>
    );
  };

  const onProductRender = (li, itemProps) => {
    const selectedData = itemProps?.dataItem;
    return (
      <div
        className="user-item"
        onClick={() => {
          setShowProductList(false);
          setProductSearchTerm(selectedData?.label);
          setProduct((prevData) => ({
            ...prevData,
            productId: selectedData?.value ?? 0,
          }));
        }}>
        <span>{selectedData?.label}</span>
      </div>
    );
  };

  const saveCurrentData = async () => {
    try {
      let selectProductList = product?.productList
        ?.map((item) => item?.value)
        .join(",");

      if (Number(product?.userId) === 0) {
        return toast.error("Please select a user", {
          position: "top-right",
        });
      }

      if (isAddForm && selectProductList === "") {
        return toast.error("Please select a products", {
          position: "top-right",
        });
      }

      if (!isAddForm && Number(product?.productId) === 0) {
        return toast.error("Please select a product", {
          position: "top-right",
        });
      }

      const obj = {
        UserId: loginData.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: isAddForm ? 0 : dataId,
            UserId: product?.userId || 0,
            UserExperienceId: product?.userExperienceId || 0,
            ProductIdList: isAddForm ? selectProductList : "",
            ProductId: isAddForm ? 0 : product?.productId,
            RecordStatusId: product?.recordStatusId || 1,
            ModifiedBy: loginData?.userId,
          },
        ],
      };

      const result = await PostRequestCall(
        SAVE_USERS_EXPERIENCES_PRODUCTS,
        obj,
        loginData.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${dataId !== "add-data-issue" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while saving data :: ", error);
    }
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      saveCurrentData();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    window.history.back();
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: dataId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(
        DELETE_USER_EXPERIENCES_PRODUCTS,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {!isAddForm && (
              <span className="record-id-text">
                User Experience Product Id : {dataId}
              </span>
            )}
          </div>
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Save"
              themeColor={"primary"}
              onClick={saveCurrentData}
            />
            {!isAddForm && (
              <KendoButton
                size={16}
                className=""
                themeColor={"secondary"}
                onClick={showDeleteConfirmation}
                buttonName="Delete"
              />
            )}
            <KendoButton
              iconClass=""
              themeColor=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #f5f5f5", width: "100%" }}></div>
        <Form
          render={(formRenderProps) => (
            <GridLayout
              rows={[
                { height: 70 },
                { height: 20 },
                { height: 20 },
                { height: "auto" },
                { height: 30 },
              ]}
              cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
              gap={{ rows: 30, cols: 35 }}
              style={{ padding: "16px" }}>
              <GridLayoutItem col={1} row={1} className="form-inner">
                <Label className="form-heading">User :</Label>
                {!isAddForm ? (
                  <Input value={user} className="field-ip" disabled />
                ) : (
                  <AutoComplete
                    data={userOptions}
                    onFocus={() => getUserList(true, "")}
                    onClose={() => setShowUserList(false)}
                    onBlur={() => setShowUserList(false)}
                    opened={showUserList}
                    value={user}
                    className="field-ip"
                    itemRender={onUserRender}
                    onChange={(e) => {
                      setUser(e?.target?.value);
                      getUserList(false, e?.target?.value);
                    }}
                    textField="label"
                    placeholder="Search for user..."
                  />
                )}

                <Error></Error>
              </GridLayoutItem>

              <GridLayoutItem col={2} row={1} className="form-inner">
                {isAddForm ? (
                  <>
                    <Label className="form-heading">Products :</Label>
                    <MultiSelect
                      data={productOptions}
                      onOpen={() => getProductList(false, "")}
                      onChange={(e) => {
                        setProduct((preValue) => ({
                          ...preValue,
                          productList: e.value,
                        }));
                      }}
                      value={product?.productList}
                      onFilterChange={(e) => {
                        setProductSearchTerm(e.filter.value);
                      }}
                      filter={productSearchTerm}
                      className="field-ip"
                      placeholder="Please select products..."
                      textField="label"
                      style={{
                        maxHeight: "80px",
                        overflowY: "auto",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Label className="form-heading">Product :</Label>
                    <AutoComplete
                      data={productOptions}
                      onFocus={() => getProductList(true, "")}
                      onClose={() => setShowUserList(false)}
                      onBlur={() => setShowUserList(false)}
                      opened={showProductList}
                      value={productSearchTerm}
                      className="field-ip"
                      itemRender={onProductRender}
                      onChange={(e) => {
                        if (e?.target?.value === "") {
                          setProduct((preValue) => ({
                            ...preValue,
                            productId: 0,
                          }));
                        }
                        setProductSearchTerm(e?.target?.value);
                        getProductList(false, e?.target?.value);
                      }}
                      textField="label"
                      placeholder="Search for product..."
                    />
                  </>
                )}
              </GridLayoutItem>

              {!isAddForm ? (
                <GridLayoutItem col={3} row={1} className="form-inner">
                  <div className="form-group">
                    <Label className="form-heading">Record Status :</Label>
                    <div>
                      <DropDownList
                        style={{ width: "370px" }}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setProduct((preValue) => ({
                            ...preValue,
                            recordStatus: e.value?.text,
                            recordStatusId: e.value?.id,
                          }));
                        }}
                        className="dropdown-list"
                        value={{
                          id: product?.recordStatusId,
                          text: product?.recordStatus,
                        }}
                        data={recordStatusOptions}
                        textField="text"
                        dataItemKey="id"
                      />
                    </div>
                  </div>
                </GridLayoutItem>
              ) : (
                <></>
              )}

              <GridLayoutItem col={1} row={2} className="form-inner">
                <div className="k-d-flex" style={{ gap: "10px" }}>
                  <KendoButton
                    iconClass=""
                    size={16}
                    className=""
                    themeColor={"primary"}
                    buttonName="Save"
                    onClick={saveCurrentData}
                  />
                  {!isAddForm && (
                    <KendoButton
                      size={16}
                      className=""
                      themeColor={"secondary"}
                      onClick={showDeleteConfirmation}
                      buttonName="Delete"
                    />
                  )}
                  <KendoButton
                    iconClass=""
                    size={16}
                    className=""
                    buttonName="Cancel"
                    onClick={onCancelClick}
                  />
                </div>
              </GridLayoutItem>

              {!isAddForm ? (
                <>
                  <GridLayoutItem colSpan={3} row={3} className="form-footer">
                    <Footerinformation footerInfo={product} />
                  </GridLayoutItem>
                </>
              ) : (
                <></>
              )}
            </GridLayout>
          )}
        />
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default AddUserExperinceProduct;
