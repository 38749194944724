import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FETCH_PRODUCTS } from "../constant/Apipath";
import { PostRequestCall } from "../apicall/PostRequest";
import { IconComponent } from "./Icon";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";

const ProductInput = (props) => {
  const debounceTimeout = useRef(null);
  const { loginData } = useSelector((state) => state.main);

  const [options, setOptions] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [productDetails, setProductDetails] = useState({});

  useEffect(() => {
    getList("");
  }, []);

  const getList = async (seacrhTerm) => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          props?.productId && props?.disabled
            ? { id: props?.productId }
            : { name: seacrhTerm },
        ],
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      };
      const res = await PostRequestCall(FETCH_PRODUCTS, obj, loginData?.token);
      let resData = res?.data?.data ?? [];
      if (props?.productId && props?.disabled) {
        return setProductDetails(resData?.[0] ?? {});
      } else {
        let data = resData.map((record) => ({
          id: record?.id,
          label: record?.name,
          manufacturerName: record?.manufacturerName,
          manufacturerId: record?.manufacturerId,
          productType: record?.productType
        }));
        setSearchLoader(false);
        setOptions(data ?? []);
      }
    } catch (error) {
      setSearchLoader(false);
      console.log("Error while getting user list :: ", error);
    }
  };

  return (
    <>
      <Label className="form-heading">
        {props?.hideLabel ? <> </> : props?.boldLabel ? <b>Product:</b> : "Product:"}
      </Label>
      {!props?.disabled ? (
        <div style={{ position: "relative" }}>
          <AutoComplete
            data={options}
            value={props?.value}
            className="field-ip"
            placeholder="Search for a product"
            onChange={async (event) => {
              const searchTerm = event.target.value;
              setSearchLoader(true);
              props?.setSelectedProduct({ label: event.target.value, id: 0, manufacturerName: "", manufacturerId: 0, productType: "" });
              if (searchTerm) {
                if (props?.open) {
                  props?.open(true);
                }
                setOpen(true);
                if (debounceTimeout.current) {
                  clearTimeout(debounceTimeout.current);
                }
                debounceTimeout.current = setTimeout(() => {
                  getList(event.target.value);
                }, 1000);
              } else {
                if (props?.open) {
                  props?.open(false);
                }
                setOpen(false);
              }
            }}
            textField="label"
            opened={open}
            onBlur={() => {
              if (props?.open) {
                props?.open(false);
              }
              setOpen(false);
            }}
            itemRender={(li, itemProps) => {
              const data = itemProps?.dataItem;
              return (
                <div
                  className="user-item"
                  onClick={() => {
                    setSearchLoader(false);
                    props?.setSelectedProduct(data);
                    setOpen(false);
                    if (props?.open) {
                      props?.open(false);
                    }
                  }}>
                  <span>{data?.label}</span>
                </div>
              );
            }}
          />
          {props?.value?.toString()?.trim() === "" && (
            <div style={{ position: "absolute", top: "20%", right: "15px" }}>
              <IconComponent size={15} iconName={"Search"} color="#B5B5B5" />
            </div>
          )}
          {searchLoader && props?.value?.toString()?.trim() !== "" && (
            <div
              style={{
                position: "absolute",
                top: "15%",
                right: "2%",
                cursor: "pointer",
                zIndex: 999,
                backgroundColor: "white",
              }}>
              <Loader size={"small"} style={{ color: "#083DB8" }} />
            </div>
          )}
        </div>
      ) : (
        <Input
          name="product_name"
          value={
            props?.productId && props?.disabled
              ? productDetails?.name
              : props?.value
          }
          className="field-ip"
          disabled={true}
          type="text"
        />
      )}
    </>
  );
};

export default ProductInput;
