import React, { useEffect, useReducer, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { FETCH_USER_DEVICES, SAVE_USER_DEVICES } from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Checkbox, Input, Switch, TextBox } from "@progress/kendo-react-inputs";
import { IconComponent } from "../../common/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { useDispatch, useSelector } from "react-redux";
import KendoButton from "../../common/KendoButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { clearFiterData, setFiterData } from "../../redux/actions/action";
import { toast } from "react-toastify";
import { showBooleanIcon } from "../../constant/Funtions";
import Modal from "../../common/Modal";

const initialState = {
  data: [],
  total: 0,
  skip: 0,
  take: 20,
  currentPage: 1,
  fullSearch: "",
  nrOfRecPerPage: 20,
  pageNr: 1,
  searchList: [],
  sortList: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DATA":
      return { ...state, data: action.payload };
    case "TOTAL":
      return { ...state, total: action.payload };
    case "SKIP":
      return { ...state, skip: action.payload };
    case "TAKE":
      return { ...state, take: action.payload };
    case "CURRENT_PAGE":
      return { ...state, currentPage: action.payload };
    case "FULL_SEARCH":
      return { ...state, fullSearch: action.payload };
    case "NR_OF_REC_PER_PAGE":
      return { ...state, nrOfRecPerPage: action.payload };
    case "PAGE_NR":
      return { ...state, pageNr: action.payload };
    case "SEARCH_LIST":
      return { ...state, searchList: action.payload };
    case "SORT_LIST":
      return { ...state, sortList: action.payload };
    default:
      return state;
  }
};

const formatDate = (dateString) => {
  if (dateString) {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  } else {
    return null;
  }
};

const UserDevices = () => {
  const { userId } = useParams();
  const { loginData, filterData } = useSelector((state) => state.main);
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState();
  const [isFilter, setIsfilter] = useState(false);
  const [editRecord, setEditRecord] = useState("");
  const [pageSizeValue, setPageSizeValue] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showLoader, setShowLoader] = useState(true);

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });

  const [tableFileds, setTableFileds] = useState([
    {
      field: "id",
      value: "numeric",
      title: "Id",
      width: "70px",
      filter: "numeric",
      editable: false,
      filterCell: (props) => CustomFilterCellForOnlyNumber(props),
    },
    {
      field: "appName",
      value: "text",
      title: "App Name",
      width: "130px",
      filter: "text",
      editable: false,
    },
    {
      field: "appVersion",
      value: "text",
      title: "App Version",
      width: "100px",
      filter: "text",
      editable: false,
    },
    {
      field: "browser",
      value: "text",
      title: "Browser",
      width: "80px",
      filter: "text",
      editable: false,
    },
    {
      field: "browserVersion",
      value: "text",
      title: "Browser Version",
      width: "100px",
      filter: "text",
      editable: false,
    },
    {
      field: "deviceName",
      value: "text",
      title: "Device Name",
      width: "100px",
      filter: "text",
      editable: false,
    },
    {
      field: "mobileDeviceModel",
      value: "text",
      title: "Moblie Device Model",
      width: "190px",
      filter: "text",
      editable: false,
    },
    {
      field: "platform",
      value: "text",
      title: "Platform",
      width: "100px",
      filter: "text",
      editable: false,
    },
    {
      field: "platformVersion",
      value: "text",
      title: "PlatformVersion",
      width: "120px",
      filter: "text",
      editable: false,
    },
    {
      field: "isDeviceNotify",
      value: "boolean",
      title: "Is Device Notify",
      width: "120px",
      editable: true,
      filter: "boolean",
      cell: (props) => showBooleanIcon(props?.dataItem?.isDeviceNotify),
      cellCopy: (props) => showBooleanIcon(props?.dataItem?.isDeviceNotify),
      filterCell: (props) => CustomBooleanFilterCell(props),
    },
    {
      field: "lastDateLoggedIn",
      value: "date",
      title: "Last LoggedIn",
      width: "180px",
      filter: "date",
      editable: false,
    },
    {
      field: "dateCreated",
      title: "Date Created",
      width: "110px",
      editable: false,
      value: "text",
      filterable: false,
    },
    {
      field: "dateModified",
      title: "Date Modifed",
      width: "110px",
      editable: false,
      value: "text",
      filterable: false,
    },
  ]);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, []);
  const CustomFilterCellForOnlyNumber = (props) => {
    const handleChange = (e) => {
      let inputValue = e.target.value;
      inputValue = inputValue.replace(/\D/g, "");
      props.onChange({
        value: inputValue,
        operator: props.operator,
        syntheticEvent: e.syntheticEvent,
      });
    };
    return <Input value={props.value || ""} onChange={handleChange} />;
  };
  useEffect(() => {
    fetchUserDevices(getObjectForFirstTimeApiCall());
  }, []);

  const getSearchListArray = (searchListArray) => {
    if (userId) {
      return [...searchListArray, { userId }];
    }

    return searchListArray;
  };

  const fetchUserDevices = async (requestObj) => {
    try {
      let searchListArray = getSearchListArray([...requestObj?.searchList]);
      var data = JSON.stringify({
        Id: 0,
        NrOfRecPerPage: requestObj?.nrOfRecPerPage,
        PageNr: requestObj?.pageNr,
        FullSearch: requestObj?.fullSearch,
        SearchList: searchListArray,
        SortList: [
          ...requestObj?.sortList,
          { fieldName: "id", direction: "desc" },
        ],
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        DoNotShowInSystemFields: false,
      });

      const result = await PostRequestCall(
        FETCH_USER_DEVICES,
        data,
        loginData?.token
      );
      const getUpdatedlist = result?.data?.data?.map((item) => ({
        ...item,
        dateCreated: formatDate(item.dateCreated),
        dateModified: formatDate(item.dateModified),
        startCampaignDate: formatDate(item.startCampaignDate),
        endCampaignDate: formatDate(item.endCampaignDate),
        selected: false,
        inEdit: true,
      }));

      dispatch({ type: "DATA", payload: getUpdatedlist });
      dispatch({ type: "TOTAL", payload: result?.data?.nrOfRecords });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const getObjectForFirstTimeApiCall = () => {
    let previousFilterValues =
      filterData?.screen === "User_Devices" ? { ...filterData } : null;
    let obj = {
      skip: previousFilterValues?.skip ?? 0,
      take: previousFilterValues?.take ?? 20,
      currentPage: previousFilterValues?.currentPage ?? 1,
      nrOfRecPerPage: previousFilterValues?.nrOfRecPerPage ?? 20,
      pageNr: previousFilterValues?.pageNr ?? 1,
      fullSearch: previousFilterValues?.fullSearch ?? "",
      searchList: previousFilterValues?.searchList ?? [],
      sortList: previousFilterValues?.sortList ?? [],
    };
    if (previousFilterValues) {
      dispatch({ type: "SKIP", payload: previousFilterValues?.skip ?? 0 });
      dispatch({ type: "TAKE", payload: previousFilterValues?.take ?? 20 });
      dispatch({
        type: "CURRENT_PAGE",
        payload: previousFilterValues?.currentPage ?? 1,
      });
      dispatch({
        type: "FULL_SEARCH",
        payload: previousFilterValues?.fullSearch ?? "",
      });
      dispatch({
        type: "NR_OF_REC_PER_PAGE",
        payload: previousFilterValues?.nrOfRecPerPage ?? 20,
      });
      dispatch({ type: "PAGE_NR", payload: previousFilterValues?.pageNr ?? 1 });
      dispatch({
        type: "SEARCH_LIST",
        payload: previousFilterValues?.searchList ?? [],
      });
      dispatch({
        type: "SORT_LIST",
        payload: previousFilterValues?.sortList ?? [],
      });
      reduxDispatch(clearFiterData());
    }
    return obj;
  };

  const getObjectForApiCall = (changesArray) => {
    setShowLoader(true);
    let obj = {
      skip: state?.skip,
      take: state?.take,
      currentPage: state?.currentPage,
      nrOfRecPerPage: state?.nrOfRecPerPage,
      pageNr: state?.pageNr,
      fullSearch: state?.fullSearch,
      searchList: state?.searchList,
      sortList: state?.sortList,
    };

    if (changesArray && Array.isArray(changesArray)) {
      changesArray.forEach((change) => {
        const { key, value } = change;
        if (key !== undefined && value !== undefined) {
          obj[key] = value;
        }
      });
    }
    return obj;
  };

  const CustomBooleanFilterCell = (props) => {
    const handleChange = (event) => {
      const value =
        event.value.value === true
          ? true
          : event.value.value === false
            ? false
            : undefined;
      props.onChange({
        value,
        operator: "eq",
        syntheticEvent: event,
      });
    };

    return (
      <DropDownList
        className="k-select"
        onChange={handleChange}
        value={
          props.value === true
            ? { text: "Yes", value: true }
            : props.value === false
              ? { text: "No", value: false }
              : { text: "All", value: "" }
        }
        data={[
          { text: "All", value: "" },
          { text: "Yes", value: true },
          { text: "No", value: false },
        ]}
        textField="text"
        valueField="value"
        dataItemKey="text"
      />
    );
  };

  const CustomRecordStatusFilterCell = (props, recordStatusesData1) => {
    const handleChange = (event) => {
      const value = event?.value?.value ?? undefined;
      props.onChange({
        value,
        operator: "eq",
        syntheticEvent: event,
      });
    };

    return (
      <DropDownList
        className="k-select"
        onChange={handleChange}
        value={{
          text: props.value === "" ? "All" : props?.value,
          value: props.value === "" ? "" : props?.value,
        }}
        data={recordStatusesData1}
        textField="text"
        dataItemKey="text"
      />
    );
  };

  const handleIndividualCheckbox = (dataItem) => {
    if (state?.data?.length > 0) {
      let getUpdatedEmailList = state?.data.map((item) => {
        if (item.id === dataItem?.id) {
          return {
            ...item,
            selected: item.selected === true ? false : true,
          };
        } else {
          return item;
        }
      });

      dispatch({ type: "DATA", payload: getUpdatedEmailList });
    }
  };

  const handleSortingChange = async (e) => {
    setSort(e.sort);
    let getSortedFields = e.sort.map((item) => {
      return { fieldName: item?.field, direction: item?.dir };
    });
    dispatch({
      type: "SORT_LIST",
      payload: getSortedFields?.length > 0 ? getSortedFields : [],
    });
    let changes = [
      {
        key: "sortList",
        value: getSortedFields?.length > 0 ? getSortedFields : [],
      },
    ];
    fetchUserDevices(getObjectForApiCall(changes));
  };

  const handleSearchChange = async (e) => {
    dispatch({
      type: "FULL_SEARCH",
      payload: e.target.value === "" ? "" : e.target.value,
    });
  };

  const pageChange = async (event) => {
    setShowLoader(true);
    let getCurrentpage = Math.ceil((event.page.skip + 1) / event.page.take);
    let targetEvent = event.targetEvent;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }

    let take = targetEvent.value === "All" ? state?.total : event.page.take;
    dispatch({ type: "NR_OF_REC_PER_PAGE", payload: take });
    dispatch({
      type: "PAGE_NR",
      payload: targetEvent.value === "All" ? 0 : getCurrentpage,
    });
    dispatch({ type: "SKIP", payload: event.page.skip });
    dispatch({ type: "TAKE", payload: take });
    dispatch({ type: "CURRENT_PAGE", payload: getCurrentpage });
    let changes = [
      { key: "nrOfRecPerPage", value: take },
      {
        key: "pageNr",
        value: targetEvent.value === "All" ? 0 : getCurrentpage,
      },
      { key: "skip", value: event.page.skip },
      { key: "take", value: take },
      { key: "currentPage", value: getCurrentpage },
    ];
    await fetchUserDevices(getObjectForApiCall(changes));
    setSort([]);
  };

  const currentRow = ({ dataItem }) => { };

  const CheckboxCell = ({ dataItem }) => (
    <td className="k-command-cell">
      <Checkbox
        checked={dataItem?.selected}
        onChange={() => handleIndividualCheckbox(dataItem)}
      />
    </td>
  );

  const EditiconCell = ({ dataItem }) => (
    <td className="k-command-cell" style={{ cursor: "pointer" }}>
      <KendoButton
        iconClass="PencilFill"
        size={14}
        className="action-btn"
        onClick={(e) => showEditIssue(e, dataItem)}
      />
    </td>
  );

  const showEditIssue = (e, dataItem) => {
    if (dataItem && dataItem.id) {
      let filterData = {
        screen: "User_Devices",
        skip: state?.skip,
        take: state?.take,
        currentPage: state?.currentPage,
        nrOfRecPerPage: state?.nrOfRecPerPage,
        pageNr: state?.pageNr,
        fullSearch: state?.fullSearch,
        searchList: state?.searchList,
        sortList: state?.sortList,
      };
      reduxDispatch(setFiterData(filterData));
      navigate(`${dataItem.id}`);
    } else {
      console.error("Invalid dataItem or missing recordId");
    }
  };

  const updateFilterdata = async (event) => {
    if (event.filter !== null) {
      let getFilterfields = event.filter.filters
        .map((item) => {
          let getField = tableFileds.filter(
            (check) => check.field === item.field
          );
          let newobj = {};
          newobj[getField[0].field] = item.value;
          return newobj;
        })
        .filter((obj) => {
          return Object.values(obj).some(
            (value) => value !== "" && value !== null
          );
        });
      setFilter(event.filter);
      dispatch({ type: "SEARCH_LIST", payload: getFilterfields });
      let changes = [{ key: "searchList", value: getFilterfields }];

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        fetchUserDevices(getObjectForApiCall(changes));
      }, 1000);
    } else {
      dispatch({ type: "SEARCH_LIST", payload: [{}] });
      let changes = [{ key: "searchList", value: [{}] }];
      fetchUserDevices(getObjectForApiCall(changes));
      setFilter(event.filter);
    }
  };

  const itemChange = (e) => {
    let newData = state?.data.map((item) => {
      let result = {};
      if (item.id === e?.dataItem?.id) {
        item[e.field || ""] = e.value;
        result = {
          ...item,
          isRecordEdit: true,
        };
      } else {
        result = { ...item };
      }
      return result;
    });
    dispatch({ type: "DATA", payload: newData });
  };

  const onKeyDownList = (e) => {
    if (e.keyCode === 13) {
      dispatch({ type: "FULL_SEARCH", payload: e.target.value });
      dispatch({ type: "PAGE_NR", payload: 1 });
      let changes = [
        { key: "fullSearch", value: e.target.value },
        { key: "pageNr", value: 1 },
      ];
      fetchUserDevices(getObjectForApiCall(changes));
    }

    if (e.keyCode === 8 && e.target.value?.trim().length <= 1) {
      dispatch({ type: "FULL_SEARCH", payload: "" });
      dispatch({ type: "PAGE_NR", payload: 1 });
      let changes = [
        { key: "fullSearch", value: "" },
        { key: "pageNr", value: 1 },
      ];
      fetchUserDevices(getObjectForApiCall(changes));
    }
  };

  const onSearchClick = (e) => {
    e.preventDefault();
    dispatch({ type: "PAGE_NR", payload: 1 });
    let changes = [{ key: "pageNr", value: 1 }];
    fetchUserDevices(getObjectForApiCall(changes));
  };

  const cellComponents = {
    isDeviceNotify: (props) => (
      <td>
        <div>
          <Switch
            size="small"
            checked={props?.dataItem?.isDeviceNotify}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange({
                  dataIndex: 0,
                  dataItem: props.dataItem,
                  field: props.field,
                  syntheticEvent: e.syntheticEvent,
                  value: e.value,
                });
              }
            }}
          />
        </div>
      </td>
    ),
  };


  const returnRecordValue = (item, toString) => {
    for (let key in item) {
      let field = tableFileds.find((obj) => obj.field === key);
      if (field?.value === "date") {
        item[key] = toString ? formatDate(item[key]) : new Date(item[key]);
      }
    }
    return item;
  };
  const onGridEditClick = () => {
    let dataWithDateChanges = state.data.map((item) => {
      let getItem = returnRecordValue(item);
      return {
        ...getItem,
      };
    });
    dispatch({ type: "DATA", payload: dataWithDateChanges });
    setTableFileds((prevTableColumn) =>
      prevTableColumn.map((column) =>
        cellComponents[column.field]
          ? { ...column, cell: cellComponents[column.field] }
          : column
      )
    );
    setEditRecord("inEdit");
  };

  const onGridEdited = async () => {
    let dataWithDateChanges = state.data.map((item) => {
      let getItem = returnRecordValue(item, "toString");
      return {
        ...getItem,
      };
    });
    dispatch({ type: "DATA", payload: dataWithDateChanges });

    const fieldsToRemoveCell = ["recordStatus"];
    setTableFileds((prevTableColumn) =>
      prevTableColumn
        .map((column) =>
          fieldsToRemoveCell.includes(column.field)
            ? { ...column, cell: undefined }
            : column
        )
        .map((col) =>
          col?.value === "boolean" ? { ...col, cell: col?.cellCopy } : col
        )
    );
    setEditRecord("");
    setShowLoader(true);
    fetchUserDevices(getObjectForApiCall());
  };

  const onGridEditedCancel = () => {
    let getEditedRecords = state?.data.filter((item) => item?.isRecordEdit);
    if (getEditedRecords?.length > 0) {
      setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Update",
      }));
    } else {
      onGridEdited();
    }
  };

  const onFilterClick = () => {
    if (isFilter) {
      dispatch({ type: "SEARCH_LIST", payload: [{}] });
      setFilter();
      let changes = [{ key: "searchList", value: [{}] }];
      fetchUserDevices(getObjectForApiCall(changes));
    }

    setIsfilter((preValue) => !preValue);
  };
  const updateEditGrid = async () => {
    try {
      let getEditedRecords = state?.data?.filter((item) => item?.isRecordEdit);
      if (getEditedRecords.length > 0) {
        setShowLoader(true);

        const savePromises = getEditedRecords.map(async (user) => {
          const data = {
            UserId: 1,
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: user?.id,
                IsDeviceNotify: user?.isDeviceNotify,
              },
            ],
          };
          return PostRequestCall(SAVE_USER_DEVICES, data, loginData?.token);
        });
        Promise.all(savePromises)
          .then((res) => {
            if (res?.[0]?.data.status === 400) {
              setShowLoader(false);
              toast.error(
                res?.[0]?.data?.message ??
                "Something went wrong. Please try again later.",
                {
                  position: "top-right",
                }
              );
            } else {
              toast.success("Data updated successfully", {
                position: "top-right",
              });
              onGridEdited();
            }
          })
          .catch((error) => {
            setShowLoader(false);
            toast.error("Something went wrong. Please try again later.", {
              position: "top-right",
            });
            console.log("Error while updating a records :: ", error);
          });
      } else {
        onGridEdited();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while updating a records :: ", error);
    }
  };

  const onActionBtnClick = () => {
    onModalClose();
    if (modalState?.for === "edit") {
      updateEditGrid();
    } else {
      return null;
    }
  };

  const onModalClose = () => {
    if (modalState?.for === "edit") {
      onGridEdited();
    }
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="main-list main-list-h-100">
      <div
        className="k-d-flex k-justify-content-between list-header table_filter_options"
        style={{ padding: "10px", background: "#ffff" }}>
        <div className="search-section">
          <TextBox
            className="search-record"
            placeholder="Search in all columns "
            suffix={() =>
              state?.fullSearch !== "" && (
                <IconComponent
                  size={25}
                  iconName={"X"}
                  className="search-icon"
                  onClick={async () => {
                    dispatch({ type: "FULL_SEARCH", payload: "" });
                    let changes = [{ key: "fullSearch", value: "" }];
                    await fetchUserDevices(getObjectForApiCall(changes));
                  }}
                />
              )
            }
            value={state?.fullSearch}
            onChange={handleSearchChange}
            onKeyDown={onKeyDownList}
          />

          <KendoButton
            iconClass="Search"
            size={16}
            className="action-btn search-btn"
            onClick={onSearchClick}
            buttonName="Search"
            disabled={state?.fullSearch === ""}
          />
          <span>Total Records : {state?.total}</span>
        </div>

        <div className="k-d-flex k-align-items-center" style={{ gap: "20px" }}>
          {editRecord ? (
            <>
              <KendoButton
                iconClass={isFilter ? "FunnelFill" : "Funnel"}
                size={18}
                className="action-btn"
                onClick={onFilterClick}
                buttonName="Filter"
              />
              <KendoButton
                iconClass="Check2"
                size={16}
                className="action-btn"
                onClick={updateEditGrid}
                buttonName="Update"
              />
              <KendoButton
                iconClass="X"
                size={16}
                className="action-btn"
                onClick={onGridEditedCancel}
                buttonName="Cancel"
              />
            </>
          ) : (
            <>
              <KendoButton
                iconClass={isFilter ? "FunnelFill" : "Funnel"}
                size={18}
                className="action-btn"
                onClick={onFilterClick}
                buttonName="Filter"
              />
              <KendoButton
                iconClass="PencilFill"
                size={16}
                className="action-btn"
                onClick={onGridEditClick}
                buttonName="Grid Edit"
              />
            </>
          )}
        </div>
      </div>

      <Grid
        className="user-list"
        data={state?.data}
        skip={state?.skip}
        take={state?.take}
        total={state?.total}
        onPageChange={pageChange}
        resizable={true}
        sortable={{
          allowUnsort: true,
          mode: "multiple",
        }}
        sort={sort}
        onSortChange={(e) => handleSortingChange(e)}
        onRowClick={currentRow}
        editField={editRecord}
        onItemChange={itemChange}
        filterable={isFilter}
        filter={filter}
        onFilterChange={(event) => updateFilterdata(event)}
        pageable={{
          buttonCount: 4,
          pageSizes: [5, 10, 15, 20, 50, 100, 500, 1000, "All"],
          pageSizeValue: pageSizeValue,
        }}>
        <GridColumn
          filterable={false}
          cell={EditiconCell}
          sortable={false}
          width="55px"
        />
        {tableFileds.map((item) =>
          editRecord === "inEdit" && item?.field === "id" ? (
            <></>
          ) : (
            <GridColumn
              editor={item?.value}
              editable={item?.editable}
              filterable={item?.filterable ?? true}
              filter={item?.filter}
              field={item?.field}
              title={item?.title}
              width={item?.width}
              cell={item?.cell}
              filterCell={item?.filterCell}
            />
          )
        )}
      </Grid>

      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default UserDevices;
