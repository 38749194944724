import React, { useEffect, useState } from 'react'
import {
    Card,
} from "@progress/kendo-react-layout";
import { IconComponent } from "../../common/Icon";
import { Loader } from "@progress/kendo-react-indicators";
import { useNavigate } from 'react-router-dom';
import { Chip } from '@progress/kendo-react-buttons';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import KendoButton from '../../common/KendoButton';
import { FETCH_PROVINCES, SAVE_SITE } from '../../constant/Apipath';
import { PostRequestCall } from '../../apicall/PostRequest';
import { useSelector } from 'react-redux';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import RecordStatusInput from '../../common/RecordStatusInput';
import { toast } from "react-toastify";
import {
    Autocomplete as GooglePlacesAutocomplete,
} from "@react-google-maps/api";

const SiteProfileSection = ({ siteDetails, callSiteData }) => {

    let navigate = useNavigate();
    const { loginData } = useSelector((state) => state.main);


    const [siteEditValues, setSiteEditValues] = useState({
        name: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        country: "",
        longitude: "",
        latitude: "",
        active: "",
        recordStatusId: "",
        recordStatus: "",
    })

    const [isSiteFiledEdited, setIsSiteFiledEdited] = useState(false);

    const [showEditForm, setShowEditForm] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [autocomplete, setAutocomplete] = useState(null);

    const [provinceList, setProvinceList] = useState([]);
    const countries = ["Canada", "United States"];

    const [addressSearchTerm, setAddressSearchTerm] = useState("");


    useEffect(() => {
        getProviencesList();
    }, [])

    useEffect(() => {
        if (showEditForm) {
            resetValuesForEditSite();
        }
    }, [showEditForm])


    const getProviencesList = async (country) => {
        try {
            const obj = {
                SearchList: [{}],
            };
            const res = await PostRequestCall(
                FETCH_PROVINCES,
                obj,
                loginData?.token
            );
            setProvinceList(res?.data?.data ?? []);
        } catch (error) {
            console.log("Error while getting list :: ", error);
        }
    };


    async function handleSearch() {
        let place = autocomplete.getPlace();

        let lat = 0;
        let lng = 0;

        if (place?.geometry) {
            lat = Number(place?.geometry?.location?.lat() ?? 0)
            lng = Number(place?.geometry?.location?.lng() ?? 0)
        }

        if (place?.address_components) {
            const street =
                place?.address_components.find((address) =>
                    address.types.includes("street_number")
                )?.long_name ?? "";
            const route =
                place?.address_components.find((address) =>
                    address.types.includes("route")
                )?.long_name ?? "";

            let city = place?.address_components.find((address) =>
                address.types.includes("locality")
            )?.long_name ?? "";

            let province = place?.address_components.find((address) =>
                address.types.includes("administrative_area_level_1")
            )?.long_name ?? "";
            let country = place?.address_components.find((address) =>
                address.types.includes("country")
            )?.long_name ?? "";
            let address = place?.street != "" ? street + " " + route : route;
            let postalCode = place?.address_components.find((address) =>
                address.types.includes("postal_code")
            )?.long_name ?? "";

            setSiteEditValues((prevValues) => ({
                ...prevValues,
                city,
                province,
                country,
                address,
                postalCode,
                latitude: lat,
                longitude: lng
            }))

            setAddressSearchTerm(address ?? "");
        }
        setIsSiteFiledEdited(true);
    }

    const getComponentRestrictions = () => {
        if (
            siteEditValues?.country?.toLowerCase() == "usa" ||
            siteEditValues?.country?.toLowerCase() == "united states"
        ) {
            return { country: ["US"] };
        } else {
            return { country: ["CA"] };
        }
    };


    const resetValuesForEditSite = () => {
        setSiteEditValues({ ...siteDetails });
        setAddressSearchTerm(siteDetails?.address);
        setIsSiteFiledEdited(false);
        setSubmitLoading(false);
    }

    const formatDate = (dateString) => {
        if (dateString) {
            const options = { month: "short", day: "numeric", year: "numeric" };
            const date = new Date(dateString);
            return date.toLocaleDateString("en-US", options);
        } else {
            return null;
        }
    };

    const onInputChange = (e) => {
        setIsSiteFiledEdited(true);
        setSiteEditValues({
            ...siteEditValues,
            [e.target.name]: e.target.value,
        });
    };

    const getProvinceListBasedOnCountry = (country) => {
        let countryName = country === "Canada" ? "Canada" : country === "United States" ? "USA" : null;
        if (!countryName) {
            return [];
        }
        return [...provinceList]?.filter((record) => record?.country === countryName)?.map((item) => (item?.province))
    }

    const onCancelClick = () => {
        setShowEditForm(false);
    }

    const onCountryChange = (e) => {
        const { value } = e.target;
        setSiteEditValues((prevState) => ({
            ...prevState,
            country: value,
            address: "",
            city: "",
            province: "",
            postalCode: "",
            longitude: 0,
            latitude: 0,
        }))
        setAddressSearchTerm("");
        setIsSiteFiledEdited(true);
    };

    const onProvinceChange = (e) => {
        const { value } = e.target;
        setSiteEditValues((prevState) => ({ ...prevState, province: value }));
        setIsSiteFiledEdited(true);
    };


    const onSaveClick = async () => {
        try {
            if (isSiteFiledEdited) {
                if (siteEditValues?.name?.trim() === "") {
                    toast.error("Please enter a valid site name.", { position: "top-right" });
                    return false;
                }
                if (siteEditValues?.country?.trim() === "" || siteEditValues?.address === "") {
                    toast.error("Please enter a valid address.", { position: "top-right" });
                    return false;
                }

                const obj = {
                    UserId: loginData?.userId,
                    ReturnRecordId: true,
                    ReturnRecordError: true,
                    SaveList: [
                        {
                            Id: siteDetails?.id,
                            Name: siteEditValues?.name,
                            Address: siteEditValues?.address,
                            City: siteEditValues?.city,
                            Province: siteEditValues?.province,
                            PostalCode: siteEditValues?.postalCode,
                            Country: siteEditValues?.country,
                            Longitude: siteEditValues?.longitude,
                            Latitude: siteEditValues?.latitude,
                            Active: siteEditValues?.active ?? false,
                            RecordStatusId: siteEditValues?.recordStatusId,
                            ModifiedBy: loginData.userId,
                        },
                    ],
                };
                setSubmitLoading(true);
                await PostRequestCall(SAVE_SITE, obj, loginData?.token);
                setSubmitLoading(false);
                callSiteData();
                setShowEditForm(false);
                return;
            } else {
                setSubmitLoading(false);
                setShowEditForm(false);
                return;
            }
        } catch (error) {
            setSubmitLoading(false);
            callSiteData();
            setShowEditForm(false);
            console.log("Error while updating site details :: ", error)
            return;
        }
    }


    const renderEditForm = () => {
        return (
            <>
                <div className='ce_container'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ width: "60%" }}>
                            <Input
                                name="name"
                                placeholder="Enter a valid site name"
                                value={siteEditValues?.name}
                                className="field-ip"
                                onChange={onInputChange}
                            />
                        </div>
                        <div style={{ width: '40%', display: 'flex', justifyContent: 'end', gap: '10px' }} >
                            <KendoButton
                                iconClass=""
                                themeColor=""
                                size={16}
                                className=""
                                buttonName="Cancel"
                                onClick={onCancelClick}
                            />
                            {
                                submitLoading ?
                                    (
                                        <div className="action-btn-loader">
                                            <Loader type={"pulsing"} themeColor="white" size='small' />
                                        </div>
                                    )
                                    :
                                    (
                                        <KendoButton
                                            iconClass=""
                                            size={16}
                                            className="action-btn"
                                            onClick={onSaveClick}
                                            buttonName="Save"
                                        />
                                    )
                            }
                        </div>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <Checkbox value={siteEditValues?.active} label={'Active'}
                            onChange={(e) => {
                                setIsSiteFiledEdited(true);
                                setSiteEditValues((preValues) => ({ ...preValues, active: e?.target?.value }))
                            }} />
                    </div>

                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Country</p>
                        </div>
                        <div className='ce_edit_input'>
                            <DropDownList
                                onChange={(e) => onCountryChange(e)}
                                className="dropdown-list"
                                data={countries}
                                value={siteEditValues?.country === "" ? "Country" : siteEditValues?.country}
                            />
                        </div>
                    </div>

                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Address</p>
                        </div>
                        <div className='ce_edit_input'>
                            <GooglePlacesAutocomplete
                                onLoad={(newInstance) => {
                                    setAutocomplete(newInstance);
                                }} // Sets instance of Autocomplete to be referenced
                                onPlaceChanged={handleSearch}
                                options={{
                                    componentRestrictions: getComponentRestrictions(),
                                }}
                            >
                                <Input
                                    name='address'
                                    placeholder="Enter a valid site address"
                                    value={addressSearchTerm}
                                    onChange={(e) => {
                                        setIsSiteFiledEdited(true);
                                        setAddressSearchTerm(e?.target?.value);
                                        setSiteEditValues((preValues) => ({ ...preValues, address: e?.target?.value }))
                                    }}
                                />
                            </GooglePlacesAutocomplete>
                        </div>
                    </div>

                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>City</p>
                        </div>
                        <div className='ce_edit_input'>
                            <Input
                                name="city"
                                placeholder="City"
                                value={siteEditValues?.city}
                                className="field-ip"
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>{siteEditValues?.country === "United States" ? "State" : "Province"}</p>
                        </div>
                        <div className='ce_edit_input'>
                            <DropDownList
                                onChange={(e) => onProvinceChange(e)}
                                className="dropdown-list"
                                data={getProvinceListBasedOnCountry(siteEditValues?.country)}
                                value={siteEditValues?.province === "" ? siteEditValues?.country === "United States" ? "State" : "Province" : siteEditValues?.province}
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>{siteEditValues?.country === "United States" ? "ZIP Code" : "Postal Code"}</p>
                        </div>
                        <div className='ce_edit_input'>
                            <Input
                                name="postalCode"
                                placeholder={siteEditValues?.country === "United States" ? "ZIP Code" : "Postal Code"}
                                value={siteEditValues?.postalCode}
                                className="field-ip"
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>

                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Record Status</p>
                        </div>
                        <div className='ce_edit_input'>
                            <RecordStatusInput
                                hideLabel={true}
                                noExtraWidth={true}
                                recordStatusId={siteEditValues?.recordStatusId}
                                recordStatus={siteEditValues?.recordStatus}
                                onChange={({ recordStatus, recordStatusId }) => {
                                    setIsSiteFiledEdited(true);
                                    setSiteEditValues((preValue) => ({
                                        ...preValue,
                                        recordStatus,
                                        recordStatusId,
                                    }));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderSiteDetails = () => {
        return (
            <>
                <div
                    style={{ width: "100%", marginTop: "35px", overflow: 'hidden' }}
                    className="about_user_report_conatiner" >
                    <div className="user_report_about_card">
                        <div className='company_title_container'>
                            <h4>{siteDetails?.name}</h4>
                            <p onClick={() => { setShowEditForm(true) }}>Edit</p>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <Chip
                                text={siteDetails?.active ? "Active" : "Inactive"}
                                type="info"
                                style={{
                                    padding: '2px', lineHeight: '15px',
                                    color: siteDetails?.active ? "#155724" : "#721c24",
                                    backgroundColor: siteDetails?.active
                                        ? "#d4edda"
                                        : "#f8d7da",
                                }}
                            />
                        </div>
                        <div>
                            <div style={{ marginTop: '10px' }}>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Address
                                    </p>
                                    <p className='company_des_value'>
                                        {siteDetails?.address}
                                        {siteDetails?.city ? "," : ""}
                                        {siteDetails?.city}
                                        {siteDetails?.province ? "," : ""} {siteDetails?.province}
                                        {siteDetails?.postalCode ? "," : ""}
                                        {siteDetails?.postalCode}
                                        {siteDetails?.country ? "," : ""} {siteDetails?.country}
                                    </p>
                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Record Status
                                    </p>
                                    <p className='company_des_value'>
                                        {siteDetails?.recordStatus ?? "--"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="user_report_description_card">
                        <ul className="account-details">
                            <p style={{ fontSize: '13px' }}>Last modified by {siteDetails?.modifiedByUser ?? "---"} on {formatDate(siteDetails?.dateModified ?? null)}</p>
                            <p style={{ fontSize: '13px' }}>Created by {siteDetails?.createdByUser ?? "---"} on {formatDate(siteDetails?.dateCreated ?? null)}</p>
                        </ul>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Card
                style={{
                    padding: "10px 10px",
                    height: "89vh",
                    position: "relative",
                    // overflow: "scroll",
                    width: "98%",
                }}>
                <div
                    style={{
                        // padding: "5px",
                        fontWeight: "bold",
                        color: "#116fa5",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                        position: "fixed",
                        // marginTop: '20px'

                    }}>
                    <IconComponent
                        iconName={"ArrowLeft"}
                        size={15}
                        color="#116fa5"
                        onClick={() => {
                            navigate("/sites/sites");
                        }}
                    />
                    <p
                        style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            color: "#116fa5",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            navigate("/sites/sites");
                        }}>
                        Back to all sites
                    </p>
                </div>

                {
                    showEditForm ? renderEditForm() : renderSiteDetails()
                }



            </Card>
        </>
    )
}

export default SiteProfileSection