import React, { useEffect, useState } from 'react'
import {
    Card,
} from "@progress/kendo-react-layout";
import { IconComponent } from "../../common/Icon";
import { Loader } from "@progress/kendo-react-indicators";
import { useNavigate } from 'react-router-dom';
import { Chip } from '@progress/kendo-react-buttons';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import KendoButton from '../../common/KendoButton';
import { DELETE_COMPANIES_ADDRESSESS, DELETE_COMPANY_TRADE, FETCH_PROVINCES, FETCH_TRADES, SAVE_COMPANIES_ADDRESSESS, SAVE_COMPANIES_TRADES, SAVE_USERS_COMPANIES } from '../../constant/Apipath';
import { PostRequestCall } from '../../apicall/PostRequest';
import { useSelector } from 'react-redux';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import RecordStatusInput from '../../common/RecordStatusInput';
import { toast } from "react-toastify";
import {
    Autocomplete as GooglePlacesAutocomplete,
} from "@react-google-maps/api";


const CompanyProfileSection = ({ companyDetails, companyAddress, tradeList, callUpdatedCompanyData }) => {

    let navigate = useNavigate();
    const { loginData } = useSelector((state) => state.main);

    const [companyEditValues, setCompanyEditValues] = useState({
        name: "",
        webSite: "",
        phone: "",
        recordStatus: "",
        recordStatusId: "",
        active: "",
        manufacturer: "",
        contact: "",
        email: '',
    })

    // const [hideCompanyDeatils, setHideCompanyDetails] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);

    const [editAddress, setEditAddress] = useState([]);
    const [editTrades, setEditTrades] = useState([]);

    const [isCompanyFiledEdited, setIsCompanyFiledEdited] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [tradeOptions, setTradeOptions] = useState([]);

    const [deletedAddressId, setDeletedAddressId] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const countries = ["Canada", "United States"];


    useEffect(() => {
        getProviencesList();
        getTradeList();
    }, [])

    useEffect(() => {
        if (showEditForm) {
            resetValuesForEditCompany();
        }
    }, [showEditForm])

    // useEffect(() => {
    //     if (
    //         !companyDetails?.webSite && !companyDetails?.phone && tradeList?.length === 0
    //         && companyAddress?.length === 0 && !companyDetails?.contact && !companyDetails?.email
    //     ) {
    //         setHideCompanyDetails(true);
    //     }
    // }, [companyDetails])


    const extractNumbers = (value) => {
        if (value) {
            const matches = value?.trim().match(/\d+/g);
            if (matches) {
                return parseInt(matches.join(''), 10);
            }
            return "";
        }
        return "";
    };

    const resetValuesForEditCompany = () => {
        setCompanyEditValues({
            name: companyDetails?.name,
            webSite: companyDetails?.webSite,
            phone: extractNumbers(companyDetails?.phone ?? ""),
            recordStatus: companyDetails?.recordStatus,
            recordStatusId: companyDetails?.recordStatusId,
            active: companyDetails?.active,
            manufacturer: companyDetails?.manufacturer,
            contact: companyDetails?.contact,
            email: companyDetails?.email
        })
        let data = tradeList?.map((record) => ({
            id: record?.tradeId,
            label: record?.tradeName,
        }));
        let addressData = companyAddress?.map((record) => ({
            id: record?.id,
            address: record?.address ?? "",
            city: record?.city ?? "",
            country: record?.country ?? "",
            province: record?.province ?? "",
            postalCode: record?.postalCode ?? "",
            existing: true,
            isEdited: false,
        }))
        setEditAddress(addressData ?? []);
        setDeletedAddressId([])
        setEditTrades(data ?? []);
        setIsCompanyFiledEdited(false);
        setSubmitLoading(false);
    }

    const getTradeList = async (seacrhTerm) => {
        try {
            let tradeObj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: seacrhTerm ?? "",
                SortList: [{ FieldName: "", Direction: "" }],
                IncludeRecordNr: true,
            };
            const tradeObjRes = await PostRequestCall(
                FETCH_TRADES,
                tradeObj,
                loginData?.token
            );
            let data = tradeObjRes?.data?.data?.map((record) => ({
                id: record?.id,
                label: record?.name,
            }));
            setTradeOptions(data ?? []);
        } catch (error) {
            console.log("Error while getting trade list :: ", error);
        }
    };

    const formatDate = (dateString) => {
        if (dateString) {
            const options = { month: "short", day: "numeric", year: "numeric" };
            const date = new Date(dateString);
            return date.toLocaleDateString("en-US", options);
        } else {
            return null;
        }
    };


    async function handleSearch(addressId) {
        let place = autocomplete.getPlace();

        let lat = 0;
        let lng = 0;

        if (place?.geometry) {
            lat = Number(place?.geometry?.location?.lat() ?? 0)
            lng = Number(place?.geometry?.location?.lng() ?? 0)
        }

        if (place?.address_components) {
            const street =
                place?.address_components.find((address) =>
                    address.types.includes("street_number")
                )?.long_name ?? "";
            const route =
                place?.address_components.find((address) =>
                    address.types.includes("route")
                )?.long_name ?? "";

            let city = place?.address_components.find((address) =>
                address.types.includes("locality")
            )?.long_name ?? "";

            let province = place?.address_components.find((address) =>
                address.types.includes("administrative_area_level_1")
            )?.long_name ?? "";
            let country = place?.address_components.find((address) =>
                address.types.includes("country")
            )?.long_name ?? "";
            let address = place?.street != "" ? street + " " + route : route;
            let postalCode = place?.address_components.find((address) =>
                address.types.includes("postal_code")
            )?.long_name ?? "";


            setEditAddress(prevState =>
                prevState.map(a =>
                    a.id === addressId
                        ? {
                            ...a,
                            city,
                            province,
                            country,
                            address,
                            postalCode,
                            isEdited: true,
                        }
                        : a
                )
            );
        }
    }

    const getComponentRestrictions = (country) => {
        if (
            country?.toLowerCase() == "usa" ||
            country?.toLowerCase() == "united states"
        ) {
            return { country: ["US"] };
        } else {
            return { country: ["CA"] };
        }
    };


    const onFilterChangeOfTrade = (e) => {
        getTradeList(e?.filter?.value);
    };

    const onInputChange = (e) => {
        setIsCompanyFiledEdited(true);
        setCompanyEditValues({
            ...companyEditValues,
            [e.target.name]: e.target.value,
        });
    };

    const getProviencesList = async (country) => {
        try {
            const obj = {
                SearchList: [{}],
            };
            const res = await PostRequestCall(
                FETCH_PROVINCES,
                obj,
                loginData?.token
            );
            setProvinceList(res?.data?.data ?? []);
        } catch (error) {
            console.log("Error while getting list :: ", error);
        }
    };

    const onAddressInputChange = (e, addressId) => {
        const { name, value } = e.target;
        setEditAddress(prevState =>
            prevState.map(address =>
                address.id === addressId
                    ? { ...address, [name]: value, isEdited: true }
                    : address
            )
        );
    };

    const onCountryChange = (e, addressId) => {
        const { value } = e.target;
        setEditAddress(prevState =>
            prevState.map(address =>
                address.id === addressId
                    ? {
                        ...address,
                        country: value,
                        isEdited: true,
                        address: "",
                        city: "",
                        province: "",
                        postalCode: "",
                    }
                    : address
            )
        );
    };

    const onProvinceChange = (e, addressId) => {
        const { value } = e.target;

        setEditAddress(prevState =>
            prevState.map(address =>
                address.id === addressId
                    ? { ...address, province: value, isEdited: true }
                    : address
            )
        );
    };

    const getProvinceListBasedOnCountry = (country) => {
        let countryName = country === "Canada" ? "Canada" : country === "United States" ? "USA" : null;
        if (!countryName) {
            return [];
        }
        return [...provinceList]?.filter((record) => record?.country === countryName)?.map((item) => (item?.province))
    }

    const onNumberInputChange = (e) => {
        let inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
            setCompanyEditValues({
                ...companyEditValues,
                [e.target.name]: inputValue,
            });
            setIsCompanyFiledEdited(true);
        }
    };

    const onAddAddressClick = () => {
        setEditAddress((preValues) => ([...preValues, {
            id: Date.now(),
            address: "",
            city: "",
            country: "",
            province: "",
            postalCode: "",
            existing: false,
            isEdited: false,
        }]));
        return;
    }

    const onRemoveAddressClick = (e, address) => {
        e.preventDefault();
        if (address?.existing) {
            setDeletedAddressId((preValue) => ([...preValue, address?.id]))
        }
        let cloneEditAddress = [...editAddress];
        let updatedEditAddress = cloneEditAddress?.filter((record) => record?.id !== address?.id);
        setEditAddress(updatedEditAddress);
        return;
    }

    const onCancelClick = () => {
        setShowEditForm(false);
    }


    const validateURL = (url) => {
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        const wwwUrlRegex = /^www\.[^\s/$.?#].[^\s]*$/i;
        if (url.trim() === "") return false;
        return urlRegex.test(url.trim()) || wwwUrlRegex.test(url.trim());
    };

    const validatePhoneNumber = (phoneNumber) => {
        const cleanedPhoneNumber = phoneNumber?.toString()?.replace(/[^0-9]/g, "");
        return cleanedPhoneNumber?.length === 10;
    };

    const checkWantToSaveCompanyData = async () => {
        if (companyEditValues?.name?.trim() === "") {
            toast.error("Please enter a valid company name.", { position: "top-right" });
            return false;
        }

        if (companyEditValues?.webSite?.trim() !== "" && !validateURL(companyEditValues?.webSite ?? "")) {
            toast.error("Please enter a valid website URL.", { position: "top-right" });
            return false;
        }
        // if (!validatePhoneNumber(companyEditValues?.phone ?? "")) {
        //     toast.error("Please enter a valid phone number.", { position: "top-right" });
        //     return false;
        // }
        return true;
    };

    const checkTradeListChanges = (existingList, updatedList) => {
        let updatedExistingList = [...existingList]?.map((item) => { return { id: item?.tradeId, label: item?.tradeName } })
        const addedTrades = updatedList.filter(updated =>
            !updatedExistingList.some(existing => existing.id === updated.id)
        );
        const deletedTrades = updatedExistingList.filter(existing =>
            !updatedList.some(updated => updated.id === existing.id)
        );
        return { addedTrades, deletedTrades };
    };

    const checkUpdatedAddressList = () => editAddress?.filter((record) => record?.isEdited);

    const saveData = async (api, saveObject) => {
        try {
            await PostRequestCall(api, saveObject, loginData?.token);
        } catch (error) {
            console.error(`Error while saving data to ${api} :: `, error);
            setSubmitLoading(false);
            showEditForm(false);
            return null;
        }
    };

    const saveCompanyDetails = async () => {
        const saveObject = {
            userId: Number(loginData?.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [{
                Id: companyDetails?.id,
                Name: companyEditValues?.name,
                webSite: companyEditValues?.webSite,
                phone: companyEditValues?.phone,
                ModifiedBy: Number(loginData?.userId),
                RecordStatusId: companyEditValues?.recordStatusId,
                Manufacturer: companyEditValues?.manufacturer,
                active: companyEditValues?.active,
                contact: companyEditValues?.contact,
                email: companyEditValues?.email,
            }]
        };
        await saveData(SAVE_USERS_COMPANIES, saveObject);
    };

    const saveUpdatedTradeList = async (newlyAddedTrades) => {
        const SaveList = newlyAddedTrades.map(item => ({
            Id: 0,
            CompanyId: companyDetails?.id,
            TradeId: item.id,
            ModifiedBy: Number(loginData?.userId),
        }));
        const saveObject = { userId: Number(loginData?.userId), ReturnRecordId: true, ReturnRecordError: true, SaveList };
        await saveData(SAVE_COMPANIES_TRADES, saveObject);
    };

    const deleteCompanyTradeList = async (deleteTradeList) => {
        const DeleteList = deleteTradeList?.map(item => ({
            CompanyId: companyDetails?.id,
            TradeId: item.id,
        }));
        const saveObject = { userId: Number(loginData?.userId), ReturnRecordError: true, SoftDelete: true, DeleteList };
        await saveData(DELETE_COMPANY_TRADE, saveObject);
    };

    const saveUpdatedAddressList = async (updatedAddressList) => {
        const SaveList = updatedAddressList.map(item => ({
            Id: item?.existing ? item?.id : 0,
            Type: "Main",
            CompanyId: companyDetails?.id,
            Address: item?.address,
            City: item?.city,
            Province: item?.province,
            PostalCode: item?.postalCode,
            Country: item?.country,
            ModifiedBy: Number(loginData?.userId),
            RecordStatusId: 1,
            Manufacturer: companyEditValues?.manufacturer,
        }));
        const saveObject = { userId: Number(loginData?.userId), ReturnRecordError: true, SoftDelete: true, SaveList };
        await saveData(SAVE_COMPANIES_ADDRESSESS, saveObject);
    };

    const deleteCompanyAddressList = async (deletedAddressId) => {
        const deleteList = deletedAddressId.map(id => ({ Id: id, ModifiedBy: loginData?.userId }));
        const deleteObj = { ReturnRecordError: true, ReturnRecordId: false, UserId: loginData?.userId, DeleteList: deleteList };
        await saveData(DELETE_COMPANIES_ADDRESSESS, deleteObj);
    };

    const onSaveClick = async (e) => {
        try {
            e.preventDefault();

            let updateCompanyDetails = false;
            let updateTradeDetails = false;
            let deleteTradeList = false;
            let updateAddressDetails = false;
            let deleteAddressList = deletedAddressId?.length !== 0;

            if (isCompanyFiledEdited) {
                updateCompanyDetails = await checkWantToSaveCompanyData();
                if (!updateCompanyDetails) return null;
            }

            const { addedTrades: newlyAddedTrades, deletedTrades: deletedTradeList } = checkTradeListChanges(tradeList, editTrades);
            if (newlyAddedTrades.length > 0) updateTradeDetails = true;
            if (deletedTradeList.length > 0) deleteTradeList = true;

            const updatedAddressList = await checkUpdatedAddressList();
            if (updatedAddressList?.length > 0) updateAddressDetails = true;

            setSubmitLoading(true);

            // Save or delete data based on changes
            if (updateCompanyDetails) await saveCompanyDetails();
            if (updateTradeDetails) await saveUpdatedTradeList(newlyAddedTrades);
            if (deleteTradeList) await deleteCompanyTradeList(deletedTradeList);
            if (updateAddressDetails) await saveUpdatedAddressList(updatedAddressList);
            if (deleteAddressList) await deleteCompanyAddressList(deletedAddressId);

            // Call any necessary update data function
            callUpdatedCompanyData(updateCompanyDetails, updateTradeDetails || deleteTradeList, updateAddressDetails || deleteAddressList);
            setShowEditForm(false);
        } catch (error) {
            console.log("Error while updating company details :: ", error);
            setSubmitLoading(false);
            showEditForm(false);
        }
    };


    const renderEditForm = () => {
        return (
            <>
                <div className='ce_container'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ width: "60%" }}>
                            <Input
                                name="name"
                                placeholder="Enter a valid company name"
                                value={companyEditValues?.name}
                                className="field-ip"
                                onChange={onInputChange}
                            />
                        </div>
                        <div style={{ width: '40%', display: 'flex', justifyContent: 'end', gap: '10px' }} >
                            <KendoButton
                                iconClass=""
                                themeColor=""
                                size={16}
                                className=""
                                buttonName="Cancel"
                                onClick={onCancelClick}
                            />
                            {
                                submitLoading ?
                                    (
                                        <div className="action-btn-loader">
                                            <Loader type={"pulsing"} themeColor="white" size='small' />
                                        </div>
                                    )
                                    :
                                    (
                                        <KendoButton
                                            iconClass=""
                                            size={16}
                                            className="action-btn"
                                            onClick={onSaveClick}
                                            buttonName="Save"
                                        />
                                    )
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '50px' }}>
                        <Checkbox value={companyEditValues?.active} label={'Active'}
                            onChange={(e) => {
                                setIsCompanyFiledEdited(true);
                                setCompanyEditValues((preValues) => ({ ...preValues, active: e?.target?.value }))
                            }} />
                        <Checkbox value={companyEditValues?.manufacturer} label={'Manufacturer'}
                            onChange={(e) => {
                                setIsCompanyFiledEdited(true);
                                setCompanyEditValues((preValues) => ({ ...preValues, manufacturer: e?.target?.value }))
                            }} />
                    </div>
                    <div className='ce_edit_container' style={{ alignItems: 'start' }}>
                        <div className='ce_edit_label'>
                            <p>Trade(s)</p>
                        </div>
                        <div className='ce_edit_input'>
                            <MultiSelect
                                data={tradeOptions}
                                value={editTrades}
                                onChange={(e) => {
                                    setEditTrades(e.value);
                                }}
                                filterable={true}
                                onFilterChange={onFilterChangeOfTrade}
                                placeholder="Please select trade..."
                                textField="label"
                                style={{ height: '100px', overflow: 'auto' }}
                                className="ce_trade_input"
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Website</p>
                        </div>
                        <div className='ce_edit_input'>
                            <Input
                                name="webSite"
                                placeholder="Enter a valid website url"
                                value={companyEditValues?.webSite}
                                className="field-ip"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Phone</p>
                        </div>
                        <div className='ce_edit_input'>
                            <Input
                                name="phone"
                                placeholder="Enter a valid phone number"
                                value={companyEditValues?.phone}
                                className="field-ip"
                                onChange={onNumberInputChange}
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Contact</p>
                        </div>
                        <div className='ce_edit_input'>
                            <Input
                                name="contact"
                                placeholder="Enter a valid contact"
                                value={companyEditValues?.contact}
                                className="field-ip"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Email</p>
                        </div>
                        <div className='ce_edit_input'>
                            <Input
                                name="email"
                                placeholder="Enter a valid email address"
                                value={companyEditValues?.email}
                                className="field-ip"
                                onChange={onInputChange}
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Record Status</p>
                        </div>
                        <div className='ce_edit_input'>
                            <RecordStatusInput
                                hideLabel={true}
                                noExtraWidth={true}
                                recordStatusId={companyEditValues?.recordStatusId}
                                recordStatus={companyEditValues?.recordStatus}
                                onChange={({ recordStatus, recordStatusId }) => {
                                    setIsCompanyFiledEdited(true);
                                    setCompanyEditValues((preValue) => ({
                                        ...preValue,
                                        recordStatus,
                                        recordStatusId,
                                    }));
                                }}
                            />
                        </div>
                    </div>

                    {
                        editAddress?.map((address) => (
                            <div className='ce_edit_address_container' key={address?.id}>
                                <p className='ce_edit_address_label'>Address</p>
                                <div className='ce_edit_address_input_container'>
                                    <div style={{ width: '100%' }}>
                                        <div>
                                            {
                                                address?.country?.trim() !== ""
                                                &&
                                                (
                                                    <>
                                                        <GooglePlacesAutocomplete
                                                            onLoad={(newInstance) => {
                                                                setAutocomplete(newInstance);
                                                            }} // Sets instance of Autocomplete to be referenced
                                                            onPlaceChanged={() => handleSearch(address?.id)}
                                                            options={{
                                                                componentRestrictions: getComponentRestrictions(address?.country),
                                                            }}
                                                        >
                                                            <Input
                                                                name='address'
                                                                placeholder="Address"
                                                                value={address?.address}
                                                                onChange={(e) => {
                                                                    onAddressInputChange(e, address.id)
                                                                }}
                                                                className="field-ip"
                                                            />
                                                        </GooglePlacesAutocomplete>

                                                        <div style={{ display: 'flex', justifyContent: 'start', gap: '5px', margin: '10px 0px' }}>
                                                            <Input
                                                                name="city"
                                                                placeholder="City"
                                                                value={address?.city}
                                                                className="field-ip"
                                                                onChange={(e) => onAddressInputChange(e, address.id)}
                                                                style={{ width: '35%' }}
                                                            />
                                                            <DropDownList
                                                                onChange={(e) => onProvinceChange(e, address.id)}
                                                                className="dropdown-list"
                                                                data={getProvinceListBasedOnCountry(address?.country)}
                                                                style={{ width: '35%' }}
                                                                value={address?.province === "" ? address?.country === "United States" ? "State" : "Province" : address?.province}

                                                            />
                                                            <Input
                                                                name="postalCode" United States
                                                                placeholder={address?.country === "United States" ? "ZIP Code" : "Postal Code"}
                                                                value={address?.postalCode}
                                                                className="field-ip"
                                                                onChange={(e) => onAddressInputChange(e, address.id)}
                                                                style={{ width: '30%' }}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }

                                            <DropDownList
                                                onChange={(e) => onCountryChange(e, address.id)}
                                                className="dropdown-list"
                                                value={address?.country === "" ? "Country" : address?.country}
                                                data={countries}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <IconComponent
                                            size={25}
                                            iconName={"X"}
                                            onClick={(e) => { onRemoveAddressClick(e, address) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    <div style={{ marginLeft: '30%', marginTop: '20px', marginBottom: '10px' }}>
                        <KendoButton
                            iconClass=""
                            themeColor=""
                            size={16}
                            buttonName="Add Address"
                            className='rust_text'
                            onClick={onAddAddressClick}
                            fillMode="outline"
                            style={{ width: '90%' }}
                        />
                    </div>
                </div>
            </>
        )
    }

    const renderCompanyDetails = () => {
        return (
            <>
                <div
                    style={{ width: "100%", marginTop: "35px", overflow: 'hidden' }}
                    className="about_user_report_conatiner" >
                    <div className="user_report_about_card">
                        <div className='company_title_container'>
                            <h4>{companyDetails?.name}</h4>
                            <p onClick={() => { setShowEditForm(true) }}>Edit</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '10px', marginTop: '10px', flexWrap: "wrap" }}>
                            <Chip
                                text={companyDetails?.active ? "Active" : "Inactive"}
                                type="info"
                                style={{
                                    padding: '2px', lineHeight: '15px',
                                    color: companyDetails?.active ? "#155724" : "#721c24",
                                    backgroundColor: companyDetails?.active
                                        ? "#d4edda"
                                        : "#f8d7da",
                                }}
                            />
                            {
                                companyDetails?.manufacturer &&
                                (
                                    <Chip
                                        text={"Manufacturer"}
                                        type="info"
                                        style={{ padding: '2px', lineHeight: '15px' }}
                                    />
                                )
                            }

                            {
                                tradeList?.map((trade, index) => (
                                    <Chip
                                        key={index}
                                        text={trade?.tradeName}
                                        type="info"
                                        style={{ padding: '2px', lineHeight: '15px' }}
                                    />
                                ))
                            }
                        </div>
                        <div>
                            <div style={{ marginTop: '10px' }}>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Website
                                    </p>
                                    <p className='company_des_value rust_text'>
                                        {companyDetails?.webSite?.trim() !== "" ? companyDetails?.webSite : "---"}
                                    </p>
                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Phone
                                    </p>
                                    <p className='company_des_value'>
                                        {companyDetails?.phone?.trim() !== "" ? companyDetails?.phone : "---"}
                                    </p>
                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Contact
                                    </p>
                                    <p className='company_des_value'>
                                        {companyDetails?.contact?.trim() !== "" ? companyDetails?.contact : "---"}
                                    </p>
                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Email
                                    </p>
                                    <p className='company_des_value'>
                                        {companyDetails?.email?.trim() !== "" ? companyDetails?.email : "---"}
                                    </p>
                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Record Status
                                    </p>
                                    <p className='company_des_value'>
                                        {companyDetails?.recordStatus ?? "--"}
                                    </p>
                                </div>

                                {
                                    companyAddress?.map((address, index) => (
                                        <div key={index} className='company_description_sub_container'>
                                            <p className='company_des_label'>
                                                Address
                                            </p>
                                            <p className='company_des_value'>
                                                {address?.address} {address?.city ? "," : ""} {address?.city}
                                                {address?.province ? "," : ""} {address?.province}
                                                {address?.postalCode ? "," : ""}
                                                {address?.postalCode}
                                                {address?.country ? "," : ""} {address?.country}
                                            </p>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>

                    <div className="user_report_description_card">
                        <ul className="account-details">
                            <p style={{ fontSize: '13px' }}>Last modified by {companyDetails?.modifiedByUser ?? "---"} on {formatDate(companyDetails?.dateModified ?? null)}</p>
                            <p style={{ fontSize: '13px' }}>Created by {companyDetails?.createdByUser ?? "---"} on {formatDate(companyDetails?.dateCreated ?? null)}</p>
                        </ul>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Card
                style={{
                    padding: "10px 10px",
                    height: "89vh",
                    position: "relative",
                    // overflow: "scroll",
                    width: "98%",
                }}>
                <div
                    style={{
                        // padding: "5px",
                        fontWeight: "bold",
                        color: "#116fa5",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                        position: "fixed",
                        // marginTop: '20px'

                    }}>
                    <IconComponent
                        iconName={"ArrowLeft"}
                        size={15}
                        color="#116fa5"
                        onClick={() => {
                            navigate("/companies/companies");
                        }}
                    />
                    <p
                        style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            color: "#116fa5",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            navigate("/companies/companies");
                        }}>
                        Back to all companies
                    </p>
                </div>

                {/* {
                    showEditForm ? renderEditForm() : hideCompanyDeatils ? null : renderCompanyDetails()
                } */}

                {
                    showEditForm ? renderEditForm() : renderCompanyDetails()
                }


            </Card>
        </>
    )
}

export default CompanyProfileSection