import React, { useEffect, useReducer, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { useDispatch, useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { PostRequestCall } from "../apicall/PostRequest";
import { DELETE_PROJECT_COMPANIES, FETCH_PROJECT_COMPANIE_S, FETCH_RECORD_STATUSES } from "../constant/Apipath";
import Modal from "../common/Modal";
import { clearFiterData } from "../redux/actions/action";
import KendoButton from "../common/KendoButton";
import { IconComponent } from "../common/Icon";
import AddProjectCompany from "./AddProjectCompany";

const initialState = {
    data: [],
    total: 0,
    skip: 0,
    take: 20,
    currentPage: 1,
    fullSearch: "",
    nrOfRecPerPage: 20,
    pageNr: 1,
    searchList: [],
    sortList: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case "DATA":
            return { ...state, data: action.payload };
        case "TOTAL":
            return { ...state, total: action.payload };
        case "SKIP":
            return { ...state, skip: action.payload };
        case "TAKE":
            return { ...state, take: action.payload };
        case "CURRENT_PAGE":
            return { ...state, currentPage: action.payload };
        case "FULL_SEARCH":
            return { ...state, fullSearch: action.payload };
        case "NR_OF_REC_PER_PAGE":
            return { ...state, nrOfRecPerPage: action.payload };
        case "PAGE_NR":
            return { ...state, pageNr: action.payload };
        case "SEARCH_LIST":
            return { ...state, searchList: action.payload };
        case "SORT_LIST":
            return { ...state, sortList: action.payload };
        default:
            return state;
    }
};

const formatDate = (dateString) => {
    if (dateString) {
        const options = { month: "short", day: "numeric", year: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
    } else {
        return null;
    }
};

const ProjectCompanySection = ({ siteDetails }) => {

    const { projectId } = useParams();

    let navigate = useNavigate();
    const { loginData, filterData } = useSelector((state) => state.main);

    const reduxDispatch = useDispatch();
    const [sort, setSort] = useState([]);
    const [filter, setFilter] = useState();
    const [editRecord, setEditRecord] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [pageSizeValue, setPageSizeValue] = useState();
    const [deleteEnable, setDeleteEnable] = useState(true);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [showLoader, setShowLoader] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [modalState, setModalState] = useState({
        for: "",
        show: false,
        onActionBtnTitle: "",
    });

    const [recordStatusOptions, setRecordStatusOptions] = useState([]);


    const [tableFileds, setTableFileds] = useState([
        {
            field: "id",
            value: "numeric",
            title: "Id",
            width: "70px",
            filter: "numeric",
            editable: false,
        },
        {
            field: "companyName",
            value: "text",
            title: "Company Name",
            width: "120px",
            filter: "text",
            editable: true,
        },
        {
            field: "projectCompanyRole",
            value: "text",
            title: "Project Company Role",
            width: "130px",
            filter: "text",
            editable: true,
        },
        {
            field: "recordStatus",
            value: "text",
            title: "Record Status",
            width: "140px",
            filter: "text",
            editable: true,
        },
        {
            field: "createdByUser",
            value: "text",
            title: "Created By",
            width: "110px",
            filter: "text",
            editable: false,
        },
        {
            field: "modifiedByUser",
            value: "text",
            title: "Modified By",
            width: "110px",
            filter: "text",
            editable: false,
        },
        {
            field: "dateCreated",
            value: "text",
            title: "Date Created",
            width: "110px",
            filterable: false,
            editable: false,
        },
        {
            field: "dateModified",
            value: "text",
            title: "Date Modified",
            width: "110px",
            filterable: false,

            editable: false,
        },
    ]);

    const debounceTimeout = useRef(null);

    useEffect(() => {
        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, []);


    useEffect(() => {
        fetchProjectCompany(getObjectForFirstTimeApiCall());
        getRecordStatuses();
    }, []);

    const CustomBooleanFilterCell = (props) => {
        const handleChange = (event) => {
            const value =
                event.value.value === true
                    ? true
                    : event.value.value === false
                        ? false
                        : undefined;
            props.onChange({
                value,
                operator: "eq",
                syntheticEvent: event,
            });
        };

        return (
            <DropDownList
                className="k-select"
                onChange={handleChange}
                value={
                    props.value === true
                        ? { text: "Yes", value: true }
                        : props.value === false
                            ? { text: "No", value: false }
                            : { text: "All", value: "" }
                }
                data={[
                    { text: "All", value: "" },
                    { text: "Yes", value: true },
                    { text: "No", value: false },
                ]}
                textField="text"
                valueField="value"
                dataItemKey="text"
            />
        );
    };

    const fetchProjectCompany = async (requestObj) => {
        try {
            setDeleteEnable(true);
            setSelectAll(false);
            let searchListArray = [{ ...requestObj?.searchList, projectId }];
            // if (projectId) {
            //     searchListArray?.push({ projectId })
            // }
            var data = JSON.stringify({
                Id: 0,
                NrOfRecPerPage: requestObj?.nrOfRecPerPage,
                PageNr: requestObj?.pageNr,
                FullSearch: requestObj?.fullSearch,
                SearchList: searchListArray,
                SortList: [
                    ...requestObj?.sortList,
                    { fieldName: "id", direction: "desc" },
                ],
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                DoNotShowInSystemFields: false,
            });
            const result = await PostRequestCall(
                FETCH_PROJECT_COMPANIE_S,
                data,
                loginData?.token
            );
            const getUpdatedlist = result?.data?.data.map((item) => ({
                ...item,
                dateCreated: formatDate(item.dateCreated),
                dateModified: formatDate(item.dateModified),
                startDate: formatDate(item?.startDate),
                endDate: formatDate(item?.endDate),
                selected: false,
                inEdit: true,
            }));

            dispatch({ type: "DATA", payload: getUpdatedlist });
            dispatch({ type: "TOTAL", payload: result?.data?.nrOfRecords });
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            console.error("Error fetching data:", error);
        } finally {
            setShowLoader(false);
        }
    };

    const getRecordStatuses = async () => {
        try {
            let recordStatusesObj = {
                PageNr: 1,
                NrOfRecPerPage: 100,
                FullSearch: "",
                UserId: "1",
                SortList: [
                    {
                        FieldName: "Id",
                        Direction: "ASC",
                    },
                ],
                IncludeRecordNr: true,
            };
            const recordStatusesRes = await PostRequestCall(
                FETCH_RECORD_STATUSES,
                recordStatusesObj,
                loginData?.token
            );
            let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
                id: record?.id,
                text: record?.name,
            }));
            setRecordStatusOptions(recordStatusesData ?? []);

            let recordStatusesData1 = recordStatusesRes?.data?.data?.map(
                (record) => ({
                    text: record?.name,
                    value: record?.name,
                })
            );
            setTableFileds((prevTableColumn) =>
                prevTableColumn.map((column) =>
                    column?.field === "recordStatus"
                        ? {
                            ...column,
                            filterCell: (props) =>
                                CustomRecordStatusFilterCell(props, [
                                    { text: "All", value: "" },
                                    ...recordStatusesData1,
                                ]),
                        }
                        : column
                )
            );
        } catch (error) {
            console.log("Error while getting record statuses data :: ", error);
        }
    };

    const getObjectForFirstTimeApiCall = () => {
        let previousFilterValues =
            filterData?.screen === "project_companies" ? { ...filterData } : null;
        let obj = {
            skip: previousFilterValues?.skip ?? 0,
            take: previousFilterValues?.take ?? 20,
            currentPage: previousFilterValues?.currentPage ?? 1,
            nrOfRecPerPage: previousFilterValues?.nrOfRecPerPage ?? 20,
            pageNr: previousFilterValues?.pageNr ?? 1,
            fullSearch: previousFilterValues?.fullSearch ?? "",
            searchList: previousFilterValues?.searchList ?? [],
            sortList: previousFilterValues?.sortList ?? [],
        };
        if (previousFilterValues) {
            dispatch({ type: "SKIP", payload: previousFilterValues?.skip ?? 0 });
            dispatch({ type: "TAKE", payload: previousFilterValues?.take ?? 20 });
            dispatch({
                type: "CURRENT_PAGE",
                payload: previousFilterValues?.currentPage ?? 1,
            });
            dispatch({
                type: "FULL_SEARCH",
                payload: previousFilterValues?.fullSearch ?? "",
            });
            dispatch({
                type: "NR_OF_REC_PER_PAGE",
                payload: previousFilterValues?.nrOfRecPerPage ?? 20,
            });
            dispatch({ type: "PAGE_NR", payload: previousFilterValues?.pageNr ?? 1 });
            dispatch({
                type: "SEARCH_LIST",
                payload: previousFilterValues?.searchList ?? [],
            });
            dispatch({
                type: "SORT_LIST",
                payload: previousFilterValues?.sortList ?? [],
            });
            reduxDispatch(clearFiterData());
        }
        return obj;
    };

    const getObjectForApiCall = (changesArray) => {
        setShowLoader(true);
        let obj = {
            skip: state?.skip,
            take: state?.take,
            currentPage: state?.currentPage,
            nrOfRecPerPage: state?.nrOfRecPerPage,
            pageNr: state?.pageNr,
            fullSearch: state?.fullSearch,
            searchList: state?.searchList,
            sortList: state?.sortList,
        };

        if (changesArray && Array.isArray(changesArray)) {
            changesArray.forEach((change) => {
                const { key, value } = change;
                if (key !== undefined && value !== undefined) {
                    obj[key] = value;
                }
            });
        }
        return obj;
    };

    const handleCheckboxChange = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setDeleteEnable(false);
        } else {
            setDeleteEnable(true);
        }
        let getUpdatedlist = state?.data?.map((item) => {
            return {
                ...item,
                selected: !selectAll,
            };
        });
        dispatch({ type: "DATA", payload: getUpdatedlist });
    };

    const handleIndividualCheckbox = (dataItem) => {
        if (state?.data?.length > 0) {
            let getUpdatedEmailList = state?.data.map((item) => {
                if (item.id === dataItem?.id) {
                    return {
                        ...item,
                        selected: item.selected === true ? false : true,
                    };
                } else {
                    return item;
                }
            });
            let checkFirst = getUpdatedEmailList.filter(
                (item) => item.selected === true
            );
            if (checkFirst?.length > 0) {
                setSelectAll(false);
            } else {
            }
            if (checkFirst?.length > 0) {
                setSelectAll(false);
                setDeleteEnable(false);
            } else {
                setDeleteEnable(true);
            }
            dispatch({ type: "DATA", payload: getUpdatedEmailList });
        }
    };

    const handleSortingChange = async (e) => {
        setSort(e.sort);
        let getSortedFields = e.sort.map((item) => {
            return { fieldName: item?.field, direction: item?.dir };
        });
        dispatch({
            type: "SORT_LIST",
            payload: getSortedFields?.length > 0 ? getSortedFields : [],
        });
        let changes = [
            {
                key: "sortList",
                value: getSortedFields?.length > 0 ? getSortedFields : [],
            },
        ];
        fetchProjectCompany(getObjectForApiCall(changes));
    };

    const CustomRecordStatusFilterCell = (props, recordStatusesData1) => {
        const handleChange = (event) => {
            const value = event?.value?.value ?? undefined;
            props.onChange({
                value,
                operator: "eq",
                syntheticEvent: event,
            });
        };

        return (
            <DropDownList
                className="k-select"
                onChange={handleChange}
                value={{
                    text: props.value === "" ? "All" : props?.value,
                    value: props.value === "" ? "" : props?.value,
                }}
                data={recordStatusesData1}
                textField="text"
                dataItemKey="text"
            />
        );
    };

    const handleSearchChange = async (e) => {
        dispatch({
            type: "FULL_SEARCH",
            payload: e.target.value === "" ? "" : e.target.value,
        });
    };

    const pageChange = async (event) => {
        setShowLoader(true);
        let getCurrentpage = Math.ceil((event.page.skip + 1) / event.page.take);
        let targetEvent = event.targetEvent;

        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
        }

        let take = targetEvent.value === "All" ? state?.total : event.page.take;
        dispatch({ type: "NR_OF_REC_PER_PAGE", payload: take });
        dispatch({
            type: "PAGE_NR",
            payload: targetEvent.value === "All" ? 0 : getCurrentpage,
        });
        dispatch({ type: "SKIP", payload: event.page.skip });
        dispatch({ type: "TAKE", payload: take });
        dispatch({ type: "CURRENT_PAGE", payload: getCurrentpage });
        let changes = [
            { key: "nrOfRecPerPage", value: take },
            {
                key: "pageNr",
                value: targetEvent.value === "All" ? 0 : getCurrentpage,
            },
            { key: "skip", value: event.page.skip },
            { key: "take", value: take },
            { key: "currentPage", value: getCurrentpage },
        ];
        await fetchProjectCompany(getObjectForApiCall(changes));
        setSort([]);
    };

    const currentRow = ({ dataItem }) => { };

    const HeaderCheckboxCell = (props) => (
        <div>
            <th>
                <Checkbox
                    checked={selectAll}
                    className="header-checkbox"
                    onChange={handleCheckboxChange}
                />
            </th>
        </div>
    );

    const CheckboxCell = ({ dataItem }) => (
        <td className="k-command-cell">
            <Checkbox
                checked={dataItem?.selected}
                onChange={() => handleIndividualCheckbox(dataItem)}
            />
        </td>
    );

    const onDeleted = () => {
        setShowLoader(true);
        setDeleteEnable(true);
        fetchProjectCompany(getObjectForApiCall());
        return;
    };

    const onKeyDownList = (e) => {
        if (e.keyCode === 13) {
            dispatch({ type: "FULL_SEARCH", payload: e.target.value });
            dispatch({ type: "PAGE_NR", payload: 1 });
            let changes = [
                { key: "fullSearch", value: e.target.value },
                { key: "pageNr", value: 1 },
            ];
            fetchProjectCompany(getObjectForApiCall(changes));
        }

        if (e.keyCode === 8 && e.target.value?.trim().length <= 1) {
            dispatch({ type: "FULL_SEARCH", payload: "" });
            dispatch({ type: "PAGE_NR", payload: 1 });
            let changes = [
                { key: "fullSearch", value: "" },
                { key: "pageNr", value: 1 },
            ];
            fetchProjectCompany(getObjectForApiCall(changes));
        }
    };

    const onSearchClick = (e) => {
        e.preventDefault();
        dispatch({ type: "PAGE_NR", payload: 1 });
        let changes = [{ key: "pageNr", value: 1 }];
        fetchProjectCompany(getObjectForApiCall(changes));
    };

    const onDeleteClick = async () => {
        try {
            setShowLoader(true);
            const selectedData = state?.data?.filter((item) => item?.selected);
            if (selectedData?.length > 0) {
                var deleteList = [];
                selectedData.map(async (item) => {
                    deleteList.push({ Id: item.id, ModifiedBy: loginData?.userId });
                });

                PostRequestCall(DELETE_PROJECT_COMPANIES, deleteList, loginData?.token)
                    .then(async (res) => {
                        if (res?.data?.status) {
                            toast.success("Data deleted successfully", {
                                position: "top-right",
                            });
                            await onDeleted();
                        } else {
                            toast.error(
                                res?.data?.message ??
                                "Something went wrong. Please try again later.",
                                {
                                    position: "top-right",
                                }
                            );
                            await onDeleted();
                        }
                    })
                    .catch(async (error) => {
                        toast.error("Something went wrong. Please try again later.", {
                            position: "top-right",
                        });
                        console.log("Error while deleting a record :: ", error);
                        await onDeleted();
                    });
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again later.", {
                position: "top-right",
            });
            console.log("Error while deleting a record :: ", error);
            onDeleted();
        }
    };

    const onActionBtnClick = () => {
        onModalClose();
        if (modalState?.for === "delete") {
            onDeleteClick();
        } else {
            return null;
        }
    };


    const EditiconCell = ({ dataItem }) => (
        <td className="k-command-cell" style={{ cursor: "pointer" }}>
            <KendoButton
                iconClass="PencilFill"
                size={14}
                className="action-btn"
                onClick={(e) => {
                    navigate(`project/${dataItem?.id}`)
                }}
            />
        </td>
    );

    const showDeleteConfirmation = () => {
        setModalState((preValue) => ({
            ...preValue,
            for: "delete",
            show: true,
            onActionBtnTitle: "Delete",
        }));
    };

    const onModalClose = () => {
        if (modalState?.for === "edit") {
            // onGridEdited();
        }
        setModalState((preValue) => ({
            ...preValue,
            for: "",
            show: false,
            onActionBtnTitle: "",
        }));
    };

    const updateFilterdata = async (event) => {
        if (event.filter !== null) {
            let getFilterfields = event.filter.filters
                .map((item) => {
                    let getField = tableFileds.filter(
                        (check) => check.field === item.field
                    );
                    let newobj = {};
                    if (getField[0].field === "recordStatus") {
                        let recordStatusId = recordStatusOptions?.filter(
                            (record) => record?.text === item?.value
                        );
                        if (recordStatusId?.[0]?.id) {
                            newobj["recordStatusId"] = recordStatusId?.[0]?.id ?? "";
                        }
                    } else {
                        newobj[getField[0].field] = item.value;
                    }
                    return newobj;
                })
                .filter((obj) => {
                    return Object.values(obj).some(
                        (value) => value !== "" && value !== null
                    );
                });
            setFilter(event.filter);
            dispatch({ type: "SEARCH_LIST", payload: getFilterfields });
            let changes = [{ key: "searchList", value: getFilterfields }];

            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }

            debounceTimeout.current = setTimeout(() => {
                fetchProjectCompany(getObjectForApiCall(changes));
            }, 1000);
        } else {
            dispatch({ type: "SEARCH_LIST", payload: [{}] });
            let changes = [{ key: "searchList", value: [{}] }];
            fetchProjectCompany(getObjectForApiCall(changes));
            setFilter(event.filter);
        }
    };

    if (showLoader) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <Loader
                    type="converging-spinner"
                    className="kendo-spinner"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minHeight: "500px",
                        alignItems: "center",
                    }}
                />
            </div>
        );
    }


    return (
        <section className="main-list main-list-h-100">
            {/* Project that <b>{siteDetails?.name}</b> has worked on: */}
            <div
                className="k-d-flex k-justify-content-between list-header table_filter_options"
                style={{ background: "#ffff" }}>
                <div className="search-section">
                    <TextBox
                        className="search-record"
                        placeholder="Search in all columns"
                        suffix={() =>
                            state?.fullSearch !== "" && (
                                <IconComponent
                                    size={25}
                                    iconName={"X"}
                                    className="search-icon"
                                    onClick={async () => {
                                        dispatch({ type: "FULL_SEARCH", payload: "" });
                                        let changes = [{ key: "fullSearch", value: "" }];
                                        await fetchProjectCompany(getObjectForApiCall(changes));
                                    }}
                                />
                            )
                        }
                        value={state?.fullSearch}
                        onChange={handleSearchChange}
                        onKeyDown={onKeyDownList}
                    />
                    <KendoButton
                        iconClass="Search"
                        size={16}
                        className="action-btn search-btn"
                        onClick={onSearchClick}
                        buttonName="Search"
                        disabled={state?.fullSearch === ""}
                    />
                </div>

                <div className="k-d-flex k-align-items-center" style={{ gap: "20px" }}>
                    <KendoButton
                        iconClass="TrashFill"
                        size={16}
                        themeColor={"secondary"}
                        className="action-btn-delete"
                        disabled={deleteEnable}
                        onClick={showDeleteConfirmation}
                        buttonName="Delete"
                    />

                    <KendoButton
                        iconClass="Plus"
                        size={20}
                        className="action-btn"
                        onClick={() => setShowForm(true)}
                        buttonName="New Record"
                    />
                </div>
            </div>
            <Grid
                className="user-list"
                style={{ height: "73vh", width: "100%" }}
                data={state?.data}
                skip={state?.skip}
                take={state?.take}
                total={state?.total}
                onPageChange={pageChange}
                resizable={true}
                sortable={{
                    allowUnsort: true,
                    mode: "multiple",
                }}
                sort={sort}
                onSortChange={(e) => handleSortingChange(e)}
                onRowClick={currentRow}
                filter={filter}
                onFilterChange={(event) => updateFilterdata(event)}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [5, 10, 15, 20, 50, 100, 500, 1000, "All"],
                    pageSizeValue: pageSizeValue,
                }}>
                <GridColumn
                    headerCell={HeaderCheckboxCell}
                    cell={CheckboxCell}
                    width="40px"
                    filterable={false}
                />
                {tableFileds.map((item) =>
                    editRecord === "inEdit" && item?.field === "id" ? (
                        <></>
                    ) : (
                        <GridColumn
                            editor={item?.value}
                            editable={item?.editable}
                            filterable={item?.filterable ?? true}
                            filter={item?.filter}
                            field={item?.field}
                            title={item?.title}
                            width={item?.width}
                            cell={item?.cell}
                            filterCell={item?.filterCell}
                        />
                    )
                )}
            </Grid>
            {modalState?.show && (
                <Modal
                    for={modalState?.for}
                    show={modalState?.show}
                    onClose={onModalClose}
                    onActionBtnClick={onActionBtnClick}
                    onActionBtnTitle={modalState?.onActionBtnTitle}
                />
            )}

            {
                showForm &&
                (
                    <AddProjectCompany
                        showForm={showForm}
                        onClose={() => { setShowForm(false) }}
                        fetchProjectCompany={fetchProjectCompany}
                        getObjectForApiCall={getObjectForApiCall}
                    />
                )
            }

        </section>
    );
};

export default ProjectCompanySection;
