import React, { useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { IconComponent } from "../common/Icon";
import KendoButton from "../common/KendoButton";
import CompanySearchInput from "../common/CompanySearchInput";
import ProjectCompanyRoleSearchInput from "../common/ProjectCompanyRoleSearchInput";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { PostRequestCall } from "../apicall/PostRequest";
import { SAVE_PROJECT_COMPANIES, SAVE_USERS_COMPANIES } from "../constant/Apipath";
import { Input } from "@progress/kendo-react-inputs";

const AddProjectCompany = ({ showForm, onClose, fetchProjectCompany, getObjectForApiCall }) => {

    const { loginData } = useSelector((state) => state.main);
    const { projectId } = useParams();

    const [submitLoader, setSubmitLoader] = useState(false);
    const [isCompanyInputOpen, setIsCompanyInputOpen] = useState(false);

    const [form, setForm] = useState({
        companyName: "",
        companyId: 0,
        projectCompanyRole: "",
        projectCompanyRoleId: 0,
    });

    const [newCompanyValues, setNewCompanyValues] = useState({
        webSite: "",
        phone: "",
        contact: "",
        email: '',
    })

    const onNumberInputChange = (e) => {
        let inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
            setNewCompanyValues({
                ...newCompanyValues,
                [e.target.name]: inputValue,
            });
        }
    };

    const onInputChange = (e) => {
        setNewCompanyValues({
            ...newCompanyValues,
            [e.target.name]: e.target.value,
        });
    };

    const validateURL = (url) => {
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        const wwwUrlRegex = /^www\.[^\s/$.?#].[^\s]*$/i;
        if (url.trim() === "") return false;
        return urlRegex.test(url.trim()) || wwwUrlRegex.test(url.trim());
    };

    const handleSave = async () => {
        let selectedCompanyId = form?.companyId ?? 0;
        if (selectedCompanyId === 0) {
            if (newCompanyValues?.webSite?.trim() !== "" && !validateURL(newCompanyValues?.webSite ?? "")) {
                toast.error("Please enter a valid website URL.", { position: "top-right" });
                return false;
            }
        }

        try {
            setSubmitLoader(true);
            if (selectedCompanyId === 0) {
                const saveObject = {
                    userId: Number(loginData?.userId),
                    ReturnRecordId: true,
                    ReturnRecordError: true,
                    SaveList: [{
                        Id: 0,
                        Name: form?.companyName,
                        webSite: newCompanyValues?.webSite,
                        phone: newCompanyValues?.phone,
                        ModifiedBy: Number(loginData?.userId),
                        RecordStatusId: 1,
                        Manufacturer: false,
                        active: true,
                        contact: newCompanyValues?.contact,
                        email: newCompanyValues?.email,
                    }]
                };
                let res = await PostRequestCall(SAVE_USERS_COMPANIES, saveObject, loginData?.token);
                selectedCompanyId = res?.data?.data?.[0]?.objData?.id ?? 0;
            }
            if (selectedCompanyId === 0) {
                return onClose();
            }
            const data = [
                {
                    Id: 0,
                    ProjectId: projectId ?? 0,
                    projectCompanyRoleId: form?.projectCompanyRoleId,
                    CompanyId: selectedCompanyId,
                    ModifiedBy: loginData?.userId,
                    recordStatusId: 1,
                },
            ];
            await PostRequestCall(
                SAVE_PROJECT_COMPANIES,
                data,
                loginData?.token
            );
            fetchProjectCompany(getObjectForApiCall());
            onClose();
        } catch (error) {
            console.log("Error while saving company in project :: ", error);
            onClose();
        }
    }

    if (!showForm) return null;

    return (
        <div style={overlayStyles.overlay}>
            <div style={overlayStyles.overlayContent}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconComponent
                        size={25}
                        iconName="X"
                        onClick={() => {
                            onClose();
                        }}
                    />
                </div>
                <h3>Add company to this project</h3>
                <div style={overlayStyles.formGroup}>
                    <p>
                        <b>Company:</b>
                    </p>
                    <CompanySearchInput
                        hideLabel
                        value={form?.companyName}
                        setSelectedCompany={({ label, id }) => {
                            setForm((preValue) => ({
                                ...preValue,
                                companyName: label,
                                companyId: id,
                            }));
                        }}
                        open={(value) => {
                            setIsCompanyInputOpen(value);
                        }}
                    />
                    {
                        form?.companyId === 0 && !isCompanyInputOpen && form?.companyName?.trim()?.length > 2
                            ?
                            (
                                <p>Adding a new Company to the database:</p>
                            )
                            :
                            (
                                <></>
                            )
                    }
                </div>

                {
                    form?.companyId === 0 && !isCompanyInputOpen && form?.companyName?.trim()?.length > 2
                        ?
                        (
                            <>
                                <div style={overlayStyles.formGroup}>
                                    <p>
                                        <b>Website:</b>
                                    </p>
                                    <Input
                                        name="webSite"
                                        placeholder="Enter a valid website url"
                                        value={newCompanyValues?.webSite}
                                        className="field-ip"
                                        onChange={onInputChange}
                                    />
                                </div>
                                <div style={overlayStyles.formGroup}>
                                    <p>
                                        <b>Phone:</b>
                                    </p>
                                    <Input
                                        name="phone"
                                        placeholder="Enter a valid phone number"
                                        value={newCompanyValues?.phone}
                                        className="field-ip"
                                        onChange={onNumberInputChange}
                                    />
                                </div>
                                <div style={overlayStyles.formGroup}>
                                    <p>
                                        <b>Contact:</b>
                                    </p>
                                    <Input
                                        name="contact"
                                        placeholder="Enter a valid contact"
                                        value={newCompanyValues?.contact}
                                        className="field-ip"
                                        onChange={onInputChange}
                                    />
                                </div>
                                <div style={overlayStyles.formGroup}>
                                    <p>
                                        <b>Email:</b>
                                    </p>
                                    <Input
                                        name="email"
                                        placeholder="Enter a valid email address"
                                        value={newCompanyValues?.email}
                                        className="field-ip"
                                        onChange={onInputChange}
                                    />
                                </div>
                            </>
                        )
                        :
                        (
                            <></>
                        )
                }

                <div style={overlayStyles.formGroup}>
                    <p>
                        <b>Role:</b>
                    </p>
                    <ProjectCompanyRoleSearchInput
                        hideLabel
                        value={form?.projectCompanyRole}
                        setSelectedProjectCompanyRole={({ label, id }) => {
                            setForm((preValue) => ({
                                ...preValue,
                                projectCompanyRole: label,
                                projectCompanyRoleId: id,
                            }));
                        }}
                    />
                </div>
                <div style={overlayStyles.actions}>
                    {submitLoader ? (
                        <div
                            className="action-btn-loader"
                            style={{ marginLeft: "auto", width: "30px", height: "20px" }}>
                            <Loader type={"pulsing"} themeColor="white" size="small" />
                        </div>
                    ) : (
                        <KendoButton
                            iconClass=""
                            size={16}
                            className={`action-btn ${form.companyName?.trim()?.length < 2 ? "disabled-btn" : ""}`}
                            buttonName="Save"
                            onClick={handleSave}
                            disabled={form.companyName?.trim()?.length < 2}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddProjectCompany;

const overlayStyles = {
    overlay: {
        position: "fixed",
        top: "67px",
        right: 0,
        width: "400px",
        height: "100%",
        background: "white",
        boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
        transform: "translateX(0)",
        transition: "transform 0.3s ease-in-out",
        borderRadius: "10px 0 0 10px",
    },
    overlayContent: {
        padding: "20px",
    },
    closeBtn: {
        float: "right",
    },
    formGroup: {
        marginBottom: "15px",
    },
    actions: {
        marginTop: "20px",
        textAlign: "right",
    },
};
