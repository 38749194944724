import React, { useEffect, useState } from 'react'
import {
    Card,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { useNavigate, useParams } from 'react-router-dom';
import { Chip } from '@progress/kendo-react-buttons';
import { Checkbox, Input, TextArea } from '@progress/kendo-react-inputs';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { SAVE_USERS_SITES_PROJECTS } from '../constant/Apipath';
import { PostRequestCall } from '../apicall/PostRequest';
import { IconComponent } from '../common/Icon';
import KendoButton from '../common/KendoButton';
import RecordStatusInput from '../common/RecordStatusInput';
import SiteTypeInput from '../common/SiteTypeInput';

const ProjectProfileSection = ({ projectDetails, callProjectData }) => {

    const { siteId } = useParams();
    const { loginData } = useSelector((state) => state.main);

    let navigate = useNavigate();

    const [projectEditValues, setProjectEditValues] = useState({
        projectName: "",
        projectType: "",
        projectTypeId: "",
        projectTypeName: "",
        projectWebsite: "",
        description: "",
        isActive: "",
        recordStatusId: "",
        recordStatus: "",
    })

    const [isProjectFiledEdited, setIsProjectFiledEdited] = useState(false);

    const [showEditForm, setShowEditForm] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (showEditForm) {
            resetValuesForEditSite();
        }
    }, [showEditForm])

    const resetValuesForEditSite = () => {
        setProjectEditValues({ ...projectDetails });
        setIsProjectFiledEdited(false);
        setSubmitLoading(false);
    }

    const formatDate = (dateString) => {
        if (dateString) {
            const options = { month: "short", day: "numeric", year: "numeric" };
            const date = new Date(dateString);
            return date.toLocaleDateString("en-US", options);
        } else {
            return null;
        }
    };

    const onInputChange = (e) => {
        setIsProjectFiledEdited(true);
        setProjectEditValues({
            ...projectEditValues,
            [e.target.name]: e.target.value,
        });
    };

    const onCancelClick = () => {
        setShowEditForm(false);
    }

    const onSaveClick = async () => {
        try {
            if (isProjectFiledEdited) {
                if (projectEditValues?.projectName?.trim() === "") {
                    toast.error("Please enter a valid project name.", { position: "top-right" });
                    return false;
                }

                setSubmitLoading(true);
                const obj = {
                    UserId: loginData?.userId,
                    ReturnRecordId: true,
                    ReturnRecordError: true,
                    SaveList: [
                        {
                            Id: projectEditValues?.id ?? 0,
                            UserId: projectEditValues?.userId ?? 0,
                            SiteId: projectEditValues?.siteId,
                            ProjectName: projectEditValues?.projectName,
                            RecordStatusId: projectEditValues?.recordStatusId ?? 1,
                            Description: projectEditValues?.description,
                            ProjectType: projectEditValues?.projectTypeId,
                            ProjectWebsite: projectEditValues?.projectWebsite,
                            IsActive: projectEditValues?.isActive,
                            ModifiedBy: loginData.userId,
                        },
                    ],
                };

                await PostRequestCall(
                    SAVE_USERS_SITES_PROJECTS,
                    obj,
                    loginData?.token
                );
                setSubmitLoading(false);
                callProjectData();
                setShowEditForm(false);
                return;
            } else {
                setSubmitLoading(false);
                setShowEditForm(false);
                return;
            }
        } catch (error) {
            setSubmitLoading(false);
            callProjectData();
            setShowEditForm(false);
            console.log("Error while updating site details :: ", error)
            return;
        }
    }

    const renderEditForm = () => {
        return (
            <>
                <div className='ce_container'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ width: "60%" }}>
                            <Input
                                name="projectName"
                                placeholder="Enter a valid project name"
                                value={projectEditValues?.projectName}
                                className="field-ip"
                                onChange={onInputChange}
                            />
                        </div>
                        <div style={{ width: '40%', display: 'flex', justifyContent: 'end', gap: '10px' }} >
                            <KendoButton
                                iconClass=""
                                themeColor=""
                                size={16}
                                className=""
                                buttonName="Cancel"
                                onClick={onCancelClick}
                            />
                            {
                                submitLoading ?
                                    (
                                        <div className="action-btn-loader">
                                            <Loader type={"pulsing"} themeColor="white" size='small' />
                                        </div>
                                    )
                                    :
                                    (
                                        <KendoButton
                                            iconClass=""
                                            size={16}
                                            className="action-btn"
                                            onClick={onSaveClick}
                                            buttonName="Save"
                                        />
                                    )
                            }
                        </div>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <Checkbox value={projectEditValues?.isActive} label={'Active'}
                            onChange={(e) => {
                                setIsProjectFiledEdited(true);
                                setProjectEditValues((preValues) => ({ ...preValues, isActive: e?.target?.value }))
                            }} />
                    </div>

                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Type</p>
                        </div>
                        <div className='ce_edit_input'>
                            <SiteTypeInput
                                placeholder="Search for project type..."
                                value={projectEditValues?.projectTypeName}
                                setSelectedSiteType={({ label, id }) => {
                                    setIsProjectFiledEdited(true);
                                    setProjectEditValues((preValue) => ({
                                        ...preValue,
                                        projectTypeName: label,
                                        projectTypeId: id,
                                    }));
                                }}
                                hideLabel
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Website</p>
                        </div>
                        <div className='ce_edit_input'>
                            <Input
                                type="url"
                                id="projectWebsite"
                                name="projectWebsite"
                                value={projectEditValues?.projectWebsite}
                                className="field-ip"
                                onChange={(e) => onInputChange(e)}
                                placeholder="Enter a valid project website url"
                            />
                        </div>
                    </div>
                    <div className='ce_edit_container' style={{ alignItems: 'start' }}>
                        <div className='ce_edit_label'>
                            <p>Description</p>
                        </div>
                        <div className='ce_edit_input'>
                            <TextArea
                                type="text"
                                id="description"
                                name="description"
                                placeholder="Enter a description"
                                value={projectEditValues?.description}
                                className="field-ip"
                                onChange={onInputChange}
                                rows={4}
                            />
                        </div>
                    </div>

                    <div className='ce_edit_container'>
                        <div className='ce_edit_label'>
                            <p>Record Status</p>
                        </div>
                        <div className='ce_edit_input'>
                            <RecordStatusInput
                                hideLabel={true}
                                noExtraWidth={true}
                                recordStatusId={projectEditValues?.recordStatusId}
                                recordStatus={projectEditValues?.recordStatus}
                                onChange={({ recordStatus, recordStatusId }) => {
                                    setIsProjectFiledEdited(true);
                                    setProjectEditValues((preValue) => ({
                                        ...preValue,
                                        recordStatus,
                                        recordStatusId,
                                    }));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderProjectDetails = () => {
        return (
            <>
                <div
                    style={{ width: "100%", marginTop: "35px", overflow: 'hidden' }}
                    className="about_user_report_conatiner" >
                    <div className="user_report_about_card">
                        <div className='company_title_container'>
                            <h4>{projectDetails?.projectName}</h4>
                            <p onClick={() => { setShowEditForm(true) }}>Edit</p>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <Chip
                                text={projectDetails?.isActive ? "Active" : "Inactive"}
                                type="info"
                                style={{
                                    padding: '2px', lineHeight: '15px',
                                    color: projectDetails?.isActive ? "#155724" : "#721c24",
                                    backgroundColor: projectDetails?.isActive
                                        ? "#d4edda"
                                        : "#f8d7da",
                                }}
                            />
                        </div>
                        <div>
                            <div style={{ marginTop: '10px' }}>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Address
                                    </p>
                                    {projectDetails?.address === "" ||
                                        projectDetails?.address === null ? (
                                        <div>
                                            {projectDetails?.latitude &&
                                                projectDetails?.latitude !== 0 &&
                                                projectDetails?.longitude &&
                                                projectDetails?.longitude !== 0 ? (
                                                <>
                                                    <p className='company_des_value'>
                                                        {`Latitude: ${projectDetails?.latitude},`}
                                                    </p>
                                                    <p className='company_des_value'>
                                                        {`  Longitude: ${projectDetails?.longitude}`}
                                                    </p>
                                                </>

                                            ) : null}
                                        </div>
                                    ) : (
                                        <p className='company_des_value'>
                                            {projectDetails?.address}
                                            {projectDetails?.city ? "," : ""}
                                            {projectDetails?.city}
                                            {projectDetails?.province ? "," : ""} {projectDetails?.province}
                                            {projectDetails?.postalCode ? "," : ""}
                                            {projectDetails?.postalCode}
                                            {projectDetails?.country ? "," : ""} {projectDetails?.country}
                                        </p>
                                    )}


                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Type
                                    </p>
                                    <p className='company_des_value'>
                                        {projectDetails?.projectTypeName?.trim() !== "" ? projectDetails?.projectTypeName : "---"}
                                    </p>
                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Website
                                    </p>
                                    <p className='company_des_value'>
                                        {projectDetails?.projectWebsite?.trim() !== "" ? projectDetails?.projectWebsite : "---"}

                                    </p>
                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Description
                                    </p>
                                    <p className='company_des_value'>
                                        {projectDetails?.description?.trim() !== "" ? projectDetails?.description : "---"}
                                    </p>
                                </div>
                                <div className='company_description_sub_container'>
                                    <p className='company_des_label'>
                                        Record Status
                                    </p>
                                    <p className='company_des_value'>
                                        {projectDetails?.recordStatus ?? "---"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="user_report_description_card">
                        <ul className="account-details">
                            <p style={{ fontSize: '13px' }}>Last modified by {projectDetails?.modifiedByUser ?? "---"} on {formatDate(projectDetails?.dateModified ?? null)}</p>
                            <p style={{ fontSize: '13px' }}>Created by {projectDetails?.createdByUser ?? "---"} on {formatDate(projectDetails?.dateCreated ?? null)}</p>
                        </ul>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Card
                style={{
                    padding: "10px 10px",
                    height: "89vh",
                    position: "relative",
                    // overflow: "scroll",
                    width: "98%",
                }}>
                <div
                    style={{
                        // padding: "5px",
                        fontWeight: "bold",
                        color: "#116fa5",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                        position: "fixed",
                        // marginTop: '20px'

                    }}>
                    <IconComponent
                        iconName={"ArrowLeft"}
                        size={15}
                        color="#116fa5"
                        onClick={() => {
                            navigate("/sites/sites");
                        }}
                    />
                    <p
                        style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            color: "#116fa5",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            navigate("/sites/sites/" + siteId);
                        }}>
                        Back to the site
                    </p>
                </div>

                {
                    showEditForm ? renderEditForm() : renderProjectDetails()
                }

            </Card>
        </>
    )
}

export default ProjectProfileSection