import React, { useEffect, useState } from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router-dom";
import {
  DELETE_USERS_COMPANIES,
  FETCH_COMPANIES,
  FETCH_USERS_COMPANIES,
  FetchUsers2,
  SAVE_USER_COMPANIES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import RecordStatusInput from "../../common/RecordStatusInput";
import { Form } from "@progress/kendo-react-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const AddNewUserCompany = () => {
  const { dataId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-company";
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [footerInfo, setFooterInfo] = useState({});

  const [company, setCompany] = useState({
    userName: "",
    userId: 0,
    companyId: 0,
    companyName: "",
    description: "",
    includeInResume: false,
    currentCompany: false,
    recordStatusId: 1,
    recordStatus: "New",
  });
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [compnaySearchTerm, setCompanySearchTerm] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (dataId !== "add-company") {
      loadData();
    }
    if (userId) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const srchList = [{ Name: value }];
        const data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: value,
          UserId: loginData.userId,
          SearchList: srchList,
          IncludeRecordNr: true,
        });

        const result = await PostRequestCall(
          FetchUsers2,
          data,
          loginData?.token
        );
        const users = result.data.data || [];

        const userOptions = users.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));

        setOptions(userOptions);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (value.trim() !== "") {
      fetchData();
    } else {
      setOptions([]);
    }
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const srchList = [{ Name: compnaySearchTerm }];
        const data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: "",
          UserId: loginData.userId,
          SearchList: srchList,
          IncludeRecordNr: true,
        });

        const result = await PostRequestCall(
          FETCH_COMPANIES,
          data,
          loginData?.token
        );
        const companies = result.data.data || [];

        const companyOptionList = companies.map((item) => ({
          value: item.id,
          label: item?.name,
        }));

        setCompanyOptions(companyOptionList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (compnaySearchTerm?.trim() !== "") {
      fetchData();
    } else {
      setCompanyOptions([]);
    }
  }, [compnaySearchTerm]);

  const loadData = async () => {
    if (dataId !== "add-company") {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            Id: dataId,
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_USERS_COMPANIES,
        obj,
        loginData?.token
      );

      let data = result?.data?.data?.[0] ?? null;
      setCompany((prevData) => ({
        ...prevData,
        userName: data?.userName,
        userId: data?.userId,
        companyId: data?.companyId,
        companyName: data?.companyName,
        description: data?.description,
        startDate: data?.startDate ? new Date(data?.startDate) : null,
        endDate: data?.endDate ? new Date(data?.endDate) : null,
        includeInResume: data?.includeInResume,
        currentCompany: data?.currentCompany,
        recordStatus: data?.recordStatus,
        recordStatusId: data?.recordStatusId,
      }));
      let footerObj = {
        dateCreated: data?.dateCreated ?? null,
        modifiedByUser: data?.modifiedByUser ?? "",
        dateModified: data?.dateModified ?? "",
        createdByUser: data?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
      setCompanySearchTerm(data?.companyName);
    }
  };

  const getUserDetails = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(FetchUsers2, data, loginData?.token);
      setCompany((prevData) => ({
        ...prevData,
        userName: result?.data?.data?.[0]?.name,
        userId,
      }));
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  };

  const onInputChange = (e) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const formatDateForSavingData = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date.getDate().toString().padStart(2, "0");
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  };

  const saveCurrentData = async (e) => {
    e.preventDefault();
    const firstOptionValue = options.length > 0 ? options[0].value : null;
    const secondOptionValue =
      companyOptions.length > 0 ? companyOptions[0].value : null;

    if (company?.userId === 0) {
      if (!firstOptionValue) {
        return toast.error("Please select a user", {
          position: "top-right",
        });
      }
    }

    if (!secondOptionValue) {
      return toast.error("Please select a company", {
        position: "top-right",
      });
    }

    if (company.startDate === "") {
      return toast.error("Please select a valid start date", {
        position: "top-right",
      });
    }

    if (!company.currentCompany) {
      if (company?.endDate === "") {
        return toast.error("Please select a valid end date", {
          position: "top-right",
        });
      }
      if (new Date(company.startDate) > new Date(company.endDate)) {
        return toast.error("Start date cannot be later than end date", {
          position: "top-right",
        });
      }
    }

    setShowLoader(true);

    const obj = {
      UserId: loginData.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: dataId === "add-company" ? 0 : dataId,
          UserId: company?.userId || firstOptionValue,
          CompanyId: secondOptionValue,
          StartDate: formatDateForSavingData(company.startDate),
          Description: company.description,
          EndDate: company?.currentCompany
            ? ""
            : formatDateForSavingData(company?.endDate),
          IncludeInResume: company?.includeInResume,
          CurrentCompany: company?.currentCompany,
          RecordStatusId: company?.recordStatusId || 1,
          ModifiedBy: loginData.userId,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_USER_COMPANIES,
        obj,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${dataId !== "add-company" ? "updated" : "added"} successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async (e) => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: dataId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(
        DELETE_USERS_COMPANIES,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }
  const onStartDateChange = (e) => {
    setIsFiledEdited(true);
    setCompany({
      ...company,
      startDate: e.value,
    });
  };
  const onEndDateChange = (e) => {
    setIsFiledEdited(true);
    setCompany({
      ...company,
      endDate: e.value,
    });
  };
  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={!isAddForm}
        footerInfo={footerInfo}
        isFieldEdited={isFieldEdited}
        recordText={"user Company ID"}
        saveCurrentData={saveCurrentData}
        onDeleteClick={onDeleteClick}>
        <div
          style={{
            width: "100%",
          }}>
          <Form
            render={(formRenderProps) => (
              <GridLayout
                rows={[
                  {
                    height: 70,
                  },
                  {
                    height: 70,
                  },
                  {
                    height: 70,
                  },
                  {
                    height: 170,
                  },
                  {
                    height: 30,
                  },
                ]}
                cols={[
                  {
                    width: 375,
                  },
                  {
                    width: 300,
                  },
                ]}
                gap={{
                  rows: 25,
                  cols: 35,
                }}
                style={{ padding: "25px 16px", gap: "20px 35px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <label className="form-heading">User:</label>
                  {dataId !== "add-company" || subMenu ? (
                    <AutoComplete
                      data={options}
                      className="field-ip"
                      value={company?.userName}
                      textField="label"
                      placeholder="Search for users..."
                      disabled
                    />
                  ) : (
                    <AutoComplete
                      data={options}
                      value={value}
                      className="field-ip"
                      onChange={handleChange}
                      textField="label"
                      placeholder="Search for users..."
                    />
                  )}
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <Label className="form-heading"> Include In Resume :</Label>
                  <br />
                  <div>
                    <Switch
                      size={"small"}
                      checked={company.includeInResume}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setCompany((preValue) => ({
                          ...preValue,
                          includeInResume: e?.value,
                        }));
                      }}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1} className="form-inner">
                  <Label className="form-heading"> Current Company :</Label>
                  <br />
                  <div>
                    <Switch
                      size={"small"}
                      checked={company.currentCompany}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setCompany((preValue) => ({
                          ...preValue,
                          currentCompany: e?.value,
                        }));
                      }}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={2} className="form-inner">
                  <label className="form-heading">Company :</label>
                  <AutoComplete
                    data={companyOptions}
                    value={compnaySearchTerm}
                    className="field-ip"
                    onChange={(e) => {
                      setCompanySearchTerm(e.target.value);
                    }}
                    textField="label"
                    placeholder="Choose Company"
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={2} className="form-inner">
                  <label className="form-heading">Start Date :</label>
                  <DatePicker
                    name="startDate"
                    value={company?.startDate}
                    onChange={onStartDateChange}
                    format="dd/MM/yyyy"
                  />
                </GridLayoutItem>
                <GridLayoutItem col={3} row={2} className="form-inner">
                  <label className="form-heading">End Date :</label>
                  <DatePicker
                    name="endDate"
                    value={company?.endDate}
                    onChange={onEndDateChange}
                    format="dd/MM/yyyy"
                  />
                </GridLayoutItem>
                <GridLayoutItem col={1} row={3} className="form-inner">
                  <label className="form-heading">Description :</label>
                  <Input
                    type="text"
                    id="description"
                    name="description"
                    value={company?.description}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                  />
                </GridLayoutItem>

                {!isAddForm && (
                  <GridLayoutItem col={2} row={3} className="form-inner">
                    <RecordStatusInput
                      recordStatusId={company?.recordStatusId}
                      recordStatus={company?.recordStatus}
                      onChange={({ recordStatus, recordStatusId }) => {
                        setIsFiledEdited(true);
                        setCompany((preValue) => ({
                          ...preValue,
                          recordStatus,
                          recordStatusId,
                        }));
                      }}
                    />
                  </GridLayoutItem>
                )}
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddNewUserCompany;
