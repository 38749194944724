import React, { useState, useRef, useEffect } from 'react'
import './NewAccount.css'
import { Form } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Loader } from "@progress/kendo-react-indicators";
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { Input } from '@progress/kendo-react-inputs';
import KendoButton from '../common/KendoButton';
import { AutoComplete, DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { CREATE_NEW_ACCOUNT_3, FETCH_COMPANIES, FETCH_SITES, FETCH_TRADES, SAVE_ONBOARDING_USER_EXPERIENCE, SAVE_USER_COMPANIES, SAVE_USERS_COMPANIES, SAVE_USERS_TRADES, UPDATE_VERIFIED_DETAILS } from '../constant/Apipath';
import { useSelector } from 'react-redux';
import { PostRequestCall } from '../apicall/PostRequest';
import BuildIDLogo from "../assets/images/Build-ID-Transparent.png";
import { IconComponent } from '../common/Icon';
import { Autocomplete as GooglePlacesAutocomplete } from "@react-google-maps/api";
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../common/WindowDimensions';


const NewAccount = () => {

    const { loginData } = useSelector((state) => state.main);

    const { width } = useWindowDimensions();

    const [userId, setUserId] = useState(0);

    const [showLoader, setShowLoader] = useState(false);
    const [email, setEmail] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [selectedCompany, setSelectedCompany] = useState({ id: 0, label: "" });
    const [selectedTrade, setSelectedTrade] = useState({ id: 0, label: "" });
    const [companyOpen, setCompanyOpen] = useState(false);
    const [tradeOpen, setTradeOpen] = useState(false);
    const [companyOptions, setCompanyOptions] = useState([]);

    const companyDebounceTimeout = useRef(null);
    const siteDebounceTimeout = useRef(null);
    const tradeDebounceTimeout = useRef(null);

    let navigate = useNavigate();

    const [formType, setFormType] = useState(1);
    const [autoComplete, setAutoComplete] = useState(null);


    const [placeDetails, setPlaceDetails] = useState(null);
    const [citySearchTerm, setCitySearchTerm] = useState('')


    const tabs = [
        {
            "title": "Profile",
            "value": 1
        },
        {
            "title": "Trade",
            "value": 2
        },
        {
            "title": "Company",
            "value": 3
        },
        {
            "title": "Site",
            "value": 4
        },
    ]

    const [tradeOptions, setTradeOptions] = useState([]);
    const [selectedSite, setSelectedSite] = useState({ id: 0, label: '', address: "" });
    const [siteOptions, setSiteOptions] = useState([]);
    const [siteOpen, setSiteOpen] = useState(false);

    const [searchLoader, setSearchLoader] = useState(false);


    useEffect(() => {
        if (formType === 2) {
            getTradeList();
        }
        if (formType === 3) {
            getCompanyList();
        }
        if (formType === 4) {
            getSiteList();
        }
    }, [formType])

    const getSiteList = async (seacrhTerm) => {
        try {
            let siteObj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: seacrhTerm ?? "",
                SortList: [{ FieldName: "", Direction: "" }],
                IncludeRecordNr: true,
            };
            const siteObjRes = await PostRequestCall(
                FETCH_SITES,
                siteObj,
                loginData?.token
            );
            let resData = siteObjRes?.data?.data ?? []
            let data = resData?.map((record) => ({
                id: record?.id ?? 0,
                label: record?.name?.trim() ?? "",
                address: record?.address ?? ""
            })).filter((item) => item?.label !== "");
            setSearchLoader(false);
            setSiteOptions(data ?? []);
        } catch (error) {
            setSearchLoader(false);
            console.log("Error while getting site list :: ", error)
        }
    }

    const getTradeList = async (seacrhTerm) => {
        try {
            let tradeObj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: seacrhTerm ?? "",
                SortList: [{ FieldName: "", Direction: "" }],
                IncludeRecordNr: true,
            };
            const tradeObjRes = await PostRequestCall(
                FETCH_TRADES,
                tradeObj,
                loginData?.token
            );
            let resData = tradeObjRes?.data?.data ?? [];
            let data = resData?.map((record) => ({
                id: record?.id,
                label: record?.name,
            }));
            if (data?.length === 0) {
                data = [{ id: 0, label: "Other" }]
            }
            setTradeOptions(data ?? []);
            setSearchLoader(false);
        } catch (error) {
            setSearchLoader(false);
            console.log("Error while getting trade list :: ", error);
        }
    };

    const getCompanyList = async (seacrhTerm) => {
        try {
            let tradeObj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: seacrhTerm ?? "",
                SortList: [{ FieldName: "", Direction: "" }],
                IncludeRecordNr: true,
            };
            const res = await PostRequestCall(
                FETCH_COMPANIES,
                tradeObj,
                loginData?.token
            );
            let resData = res?.data?.data ?? [];
            let data = resData.map((record) => ({
                id: record?.id,
                label: record?.name,
            }));
            setSearchLoader(false);
            setCompanyOptions(data ?? []);
        } catch (error) {
            setSearchLoader(false);
            console.log("Error while getting trade list :: ", error);
        }
    };

    const resetAllState = () => {
        setUserId(0);
        setEmail("");
        setFirstName("");
        setLastName("");
        setSelectedTrade({ id: 0, label: "" });
        setSelectedCompany({ id: 0, label: "" });
        setCompanyOptions([]);
        setPlaceDetails(null);
        setTradeOptions([]);
        setSelectedSite({ id: 0, label: "", address: "" });
        setSiteOpen(false);
        setCompanyOpen(false);
        setTradeOpen(false);
        setShowLoader(false);
    }

    const onProfileNextClick = async () => {
        if (firstName?.trim() === "") {
            alert("Please enter first name.");
            return;
        }

        if (lastName?.trim() === "") {
            alert("Please enter last name.");
            return;
        }

        const emailId = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        if (email.length === 0) {
            alert("Please enter email.");
            return;
        }

        if (!emailId.test(email.trim())) {
            alert("Please enter valid email address!");
            return;
        }
        setSearchLoader(false);
        return setFormType(2);


        // try {
        //     if (firstName?.trim() === "") {
        //         alert("Please enter first name.");
        //         setFormType(1);
        //         return;
        //     }

        //     if (lastName?.trim() === "") {
        //         alert("Please enter last name.");
        //         setFormType(1);
        //         return;
        //     }

        //     const emailId = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        //     if (email.length === 0) {
        //         alert("Please enter email.");
        //         setFormType(1);
        //         return;
        //     }

        //     if (!emailId.test(email.trim())) {
        //         alert("Please enter valid email address!");
        //         setFormType(1);
        //         return;
        //     }

        //     setShowLoader(true);
        //     const obj = {
        //         FirstName: firstName?.trim() ?? '',
        //         LastName: lastName?.trim() ?? '',
        //         UserEmail: email?.trim() ?? '',
        //         City: address.city ?? '',
        //         Country: address.country ?? '',
        //         Latitude: address.latitude ?? 0.00,
        //         Longitude: address.longitude ?? 0.00,
        //     }

        //     const result = await PostRequestCall(CREATE_NEW_ACCOUNT_3, obj, loginData?.token);
        //     if (result.status === 201) {
        //         if (result?.data?.data?.id) {
        //             setUserId(result?.data?.data?.id);
        //             setFormType(2);
        //             setShowLoader(false);
        //         } else {
        //             setShowLoader(false);
        //             setFormType(1);
        //             loadScriptForCityInput();
        //             alert("Something went wrong. Please try again later.")
        //         }
        //     } else {
        //         setShowLoader(false);
        //         setFormType(1);
        //         loadScriptForCityInput();
        //         let errorMessage = result?.data?.errorMessage ? result?.data?.errorMessage : result?.data?.error ? result?.data?.error : "Something went wrong. Please try again later."
        //         return alert(errorMessage);
        //     }
        // } catch (error) {
        //     setShowLoader(false);
        //     setFormType(1);
        //     loadScriptForCityInput();
        //     console.log("Error while creating a account :: ", error);
        //     return alert('Something went wrong. Please try again later.')
        // }
    }

    const onTradeNextClick = async () => {
        setSearchLoader(false);
        return setFormType(3);

        // try {

        //     if (selectedTrade?.id === 0) {
        //         return setFormType(3);
        //     }

        //     setShowLoader(true);


        //     let tradesArray = [{ Id: 0, UserId: Number(userId), TradeId: selectedTrade?.id, TradeType: 'Main', ModifiedBy: Number(userId) }]

        //     const obj = {
        //         UserId: userId,
        //         ReturnRecordId: true,
        //         ReturnRecordError: true,
        //         SaveList: tradesArray
        //     }
        //     await PostRequestCall(SAVE_USERS_TRADES, obj, loginData?.token).then(async (res) => {
        //         if (res?.status === 201 || res?.status === true) {
        //             setShowLoader(false);
        //             setFormType(3);
        //         } else {
        //             setShowLoader(false);
        //             let errorMessage = res?.data?.errorMessage ? res?.data?.errorMessage : res?.data?.error ? res?.data?.error : "Something went wrong. Please try again later."
        //             return alert(errorMessage);
        //         }
        //     }).catch((error) => {
        //         setShowLoader(false);
        //         console.log("Error while saving trades or company :: ", error)
        //         return alert("Something went wrong. Please try again later.");
        //     })

        // } catch (error) {
        //     setShowLoader(false);
        //     console.log("Error while creating a account :: ", error)
        //     return alert("Something went wrong. Please try again later.");
        // }
    }

    const onBackPress = () => {
        setSearchLoader(false);
        if (formType === 1) {
            return null;
        }
        setFormType((preValue) => preValue - 1);
    }

    const onLoadAuto = (newInstance) => {
        // Access the suggestion list container and add a custom class
        setAutoComplete(newInstance);
    };

    ;

    const handlePlaceChanged = (place1) => {
        let place = autoComplete.getPlace();
        if (place) {
            const { geometry, address_components } = place;
            const lat = geometry?.location?.lat();
            const lng = geometry?.location?.lng();

            const countryComponent = address_components?.find(component =>
                component.types.includes('country')
            );
            const cityComponent = address_components?.find(component =>
                component.types.includes('locality')
            );
            const country = countryComponent ? countryComponent?.long_name : '';
            const city = cityComponent ? cityComponent?.long_name : '';

            setPlaceDetails({
                lat,
                lng,
                country,
                city,
            });
            if (city || country) {
                setCitySearchTerm(city + "," + country);
            }
        }
    };


    const saveUserCompany = async (companyId) => {
        try {
            const companySaveObj = {
                UserId: userId,
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [{
                    Id: 0,
                    UserId: userId,
                    RoleId: 0,
                    CompanyId: companyId,
                    StartDate: null,
                    EndDate: null,
                    ModifiedBy: userId,
                    IncludeInResume: false,
                    CurrentCompany: false,
                    Description: ''
                }]
            }
            await PostRequestCall(SAVE_USER_COMPANIES, companySaveObj, loginData?.token).then((companyRes) => {
                if (companyRes?.status === 201 || companyRes?.status === true) {
                    setFormType(4);
                    setShowLoader(false);
                } else {
                    setShowLoader(false);
                    let errorMessage = companyRes?.data?.errorMessage ? companyRes?.data?.errorMessage : companyRes?.data?.error ? companyRes?.data?.error : "Something went wrong. Please try again later.";
                    alert(errorMessage);
                }
            }).catch((error) => {
                setShowLoader(false);
                console.log("Error while saving trades or company :: ", error);
                alert("Something went wrong. Please try again later.")
            })
        } catch (error) {
            setShowLoader(false);
            console.log("Error while saving trades or company :: ", error)
            return alert("Something went wrong. Please try again later.")
        }
    }

    const onCompanyNextClick = async () => {
        setSearchLoader(false);
        try {
            if (selectedCompany?.id !== 0) {
                return setFormType(4);
            } else if (selectedCompany?.id === 0 && selectedCompany?.label?.trim() !== "") {
                setShowLoader(true);
                const mainObj = {
                    UserId: Number(loginData?.userId ?? 0),
                    ReturnRecordId: true,
                    ReturnRecordError: true,
                };
                const saveObject = {
                    ...mainObj,
                    SaveList: [
                        {
                            Id: 0,
                            Name: selectedCompany?.label,
                            ModifiedBy: Number(loginData?.userId ?? 0),
                            RecordStatusId: 1,
                            Manufacturer: false,
                        },
                    ],
                };

                await PostRequestCall(SAVE_USERS_COMPANIES, saveObject, loginData?.token).then((companyRes) => {
                    if (companyRes?.status === 201 || companyRes?.status === true) {
                        if (companyRes?.data?.data?.[0]?.objData?.id) {
                            setSelectedCompany((preValue) => ({ label: companyRes?.data?.data?.[0]?.objData?.name, id: companyRes?.data?.data?.[0]?.objData?.id }));
                            setFormType(4);
                            setShowLoader(false);
                        } else {
                            setShowLoader(false);
                            console.log("Error while saving new company ::")
                            return alert("Something went wrong. Please try again later.");
                        }
                    } else {
                        setShowLoader(false);
                        let errorMessage = companyRes?.data?.errorMessage ? companyRes?.data?.errorMessage : companyRes?.data?.error ? companyRes?.data?.error : "Something went wrong. Please try again later.";
                        alert(errorMessage);
                    }
                }).catch((error) => {
                    setShowLoader(false);
                    console.log("Error while saving new company :: ", error)
                    return alert("Something went wrong. Please try again later.");
                })
            } else {
                setShowLoader(false);
                setFormType(4);
            }
        } catch (error) {
            console.log("Error While saving new company :: ", error);
            return setShowLoader(false);
        }

        // try {
        //     if (selectedCompany?.id !== 0) {
        //         saveUserCompany(selectedCompany?.id);
        //     } else if (selectedCompany?.id === 0 && selectedCompany?.label?.trim() !== "") {

        //         const mainObj = {
        //             UserId: Number(userId),
        //             ReturnRecordId: true,
        //             ReturnRecordError: true,
        //         };
        //         const saveObject = {
        //             ...mainObj,
        //             SaveList: [
        //                 {
        //                     Id: 0,
        //                     Name: selectedCompany?.label,
        //                     ModifiedBy: Number(userId),
        //                     RecordStatusId: 1,
        //                     Manufacturer: false,
        //                 },
        //             ],
        //         };

        //         await PostRequestCall(SAVE_USERS_COMPANIES, saveObject, loginData?.token).then((companyRes) => {
        //             if (companyRes?.status === 201 || companyRes?.status === true) {
        //                 if (companyRes?.data?.data?.[0]?.objData?.id) {
        //                     saveUserCompany(companyRes?.data?.data?.[0]?.objData?.id)
        //                 } else {
        //                     setShowLoader(false);
        //                     console.log("Error while saving new company ::")
        //                     return alert("Something went wrong. Please try again later.");
        //                 }
        //             } else {
        //                 setShowLoader(false);
        //                 let errorMessage = companyRes?.data?.errorMessage ? companyRes?.data?.errorMessage : companyRes?.data?.error ? companyRes?.data?.error : "Something went wrong. Please try again later.";
        //                 alert(errorMessage);
        //             }
        //         }).catch((error) => {
        //             setShowLoader(false);
        //             console.log("Error while saving new company :: ", error)
        //             return alert("Something went wrong. Please try again later.");
        //         })
        //     } else {
        //         setShowLoader(false);
        //         setFormType(4);
        //     }
        // } catch (error) {

        // }
    }


    // const onSiteNextClick = async () => {
    //     try {
    //         if (selectedSite?.id === 0) {
    //             resetAllState();
    //             return setFormType(5);
    //         }
    //         setShowLoader(true);
    //         const obj = {
    //             UserId: Number(userId),
    //             SiteId: selectedSite?.id ?? 0,
    //             CompanyId: selectedCompany?.id ?? 0
    //         }
    //         const result = await PostRequestCall(SAVE_ONBOARDING_USER_EXPERIENCE, obj, loginData?.token);
    //         if (result.status === 201) {
    //             setShowLoader(false);
    //             setFormType(5);
    //             resetAllState();
    //         } else {
    //             setShowLoader(false);
    //             let errorMessage = result?.data?.errorMessage ? result?.data?.errorMessage : result?.data?.error ? result?.data?.error : "Something went wrong. Please try again later."
    //             return alert(errorMessage);
    //         }
    //     } catch (error) {
    //         setShowLoader(false);
    //         console.log("Error while saving site :: ", error)
    //         return alert("Something went wrong. Please try again later.");
    //     }
    // }


    const saveTradesToUserProfile = async (userId) => {
        try {
            let tradesArray = [{ Id: 0, UserId: Number(userId), TradeId: selectedTrade?.id, TradeType: 'Main', ModifiedBy: Number(userId) }]

            const obj = {
                UserId: userId,
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: tradesArray
            }
            await PostRequestCall(SAVE_USERS_TRADES, obj, loginData?.token).then(async (res) => {
                if (res?.status === 201 || res?.status === true) {
                } else {
                    setShowLoader(false);
                    let errorMessage = res?.data?.errorMessage ? res?.data?.errorMessage : res?.data?.error ? res?.data?.error : "Something went wrong. Please try again later."
                    return alert(errorMessage);
                }
            }).catch((error) => {
                setShowLoader(false);
                console.log("Error while saving trades or company :: ", error)
                return alert("Something went wrong. Please try again later.");
            })
        } catch (error) {
            setShowLoader(false);
            console.log("Error while creating a account :: ", error)
            return alert("Something went wrong. Please try again later.");
        }
    }

    const saveCompanyToUserProfile = async (userId) => {
        try {
            const companySaveObj = {
                UserId: userId,
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [{
                    Id: 0,
                    UserId: userId,
                    RoleId: 0,
                    CompanyId: selectedCompany?.id,
                    StartDate: null,
                    EndDate: null,
                    ModifiedBy: userId,
                    IncludeInResume: false,
                    CurrentCompany: false,
                    Description: ''
                }]
            }
            await PostRequestCall(SAVE_USER_COMPANIES, companySaveObj, loginData?.token).then((companyRes) => {
                if (companyRes?.status === 201 || companyRes?.status === true) {
                } else {
                    setShowLoader(false);
                    let errorMessage = companyRes?.data?.errorMessage ? companyRes?.data?.errorMessage : companyRes?.data?.error ? companyRes?.data?.error : "Something went wrong. Please try again later.";
                    return alert(errorMessage);
                }
            }).catch((error) => {
                setShowLoader(false);
                console.log("Error while saving trades or company :: ", error);
                return alert("Something went wrong. Please try again later.")
            })
        } catch (error) {
            setShowLoader(false);
            console.log("Error while saving trades or company :: ", error)
            return alert("Something went wrong. Please try again later.")
        }
    }

    const saveSiteToUserProfile = async (userId) => {
        try {
            const obj = {
                UserId: Number(userId),
                SiteId: selectedSite?.id ?? 0,
                CompanyId: selectedCompany?.id ?? 0
            }
            const result = await PostRequestCall(SAVE_ONBOARDING_USER_EXPERIENCE, obj, loginData?.token);
            if (result.status === 201) {
                setShowLoader(false);
                // setFormType(5);
                resetAllState();
                return navigate("/user/welcome-new-account");
            } else {
                setShowLoader(false);
                let errorMessage = result?.data?.errorMessage ? result?.data?.errorMessage : result?.data?.error ? result?.data?.error : "Something went wrong. Please try again later."
                return alert(errorMessage);
            }
        } catch (error) {
            setShowLoader(false);
            console.log("Error while saving site :: ", error)
            return alert("Something went wrong. Please try again later.");
        }
    }

    const onSiteNextClick = async () => {
        try {
            setSearchLoader(false);
            setShowLoader(true);
            const obj = {
                FirstName: firstName?.trim() ?? '',
                LastName: lastName?.trim() ?? '',
                UserEmail: email?.trim() ?? '',
                City: placeDetails?.city ?? '',
                Country: placeDetails?.country ?? '',
                Latitude: placeDetails?.lat ?? 0.00,
                Longitude: placeDetails?.lng ?? 0.00,
                ReferredBy: loginData?.userId,
                ReferredSource: "CD"
            }
            const result = await PostRequestCall(CREATE_NEW_ACCOUNT_3, obj, loginData?.token);
            if (result.status === 201) {
                if (result?.data?.data?.id) {
                    setUserId(result?.data?.data?.id);
                    if (selectedTrade?.id !== 0) {
                        await saveTradesToUserProfile(result?.data?.data?.id)
                    }
                    if (selectedCompany?.id !== 0) {
                        await saveCompanyToUserProfile(result?.data?.data?.id)
                    }
                    if (selectedSite?.id !== 0) {
                        await saveSiteToUserProfile(result?.data?.data?.id)
                    }
                    resetAllState();
                    return navigate('/user/welcome-new-account');
                    // return setFormType(5)
                } else {
                    setShowLoader(false);
                    return alert("Something went wrong. Please try again later.")
                }
            } else {
                setShowLoader(false);
                let errorMessage = result?.data?.errorMessage ? result?.data?.errorMessage : result?.data?.error ? result?.data?.error : "Something went wrong. Please try again later."
                return alert(errorMessage);
            }
        } catch (error) {
            setShowLoader(false);
            console.log("Error while saving site :: ", error)
            return alert("Something went wrong. Please try again later.");
        }
    }

    const renderProfileForm = () => {
        return (
            <>
                <div className='form_main_container'>
                    <div className='form_main_container_inputs'>
                        <h3 className='na_form_title'>Sign up for BuildID</h3>

                        <p className='na_form_description'>Let's start by filling out your profile.</p>
                        <Form
                            style={{ padding: '10px' }}
                            render={(formRenderProps) => (
                                <GridLayout
                                    rows={[
                                        {
                                            height: "auto",
                                        },
                                        {
                                            height: "auto",
                                        },
                                        {
                                            height: "auto",
                                        },
                                    ]}
                                    cols={[
                                        {
                                            width: 250,
                                        },
                                        {
                                            width: 250,
                                        },
                                    ]}
                                    gap={{
                                        rows: 0,
                                        cols: 35,
                                    }}
                                    style={{ marginTop: '20px' }}
                                    className='na_gird_layout'
                                >
                                    <GridLayoutItem col={1} row={1} className="form-inner">
                                        <Label className="form-heading">First Name</Label>
                                        <Input
                                            name="firstName"
                                            value={firstName}
                                            // placeholder='Enter first name'
                                            className="field-ip na_inputs"
                                            onChange={(e) => {
                                                const inputValue = e?.target?.value;
                                                const lettersOnly = /^[A-Za-z]*$/;
                                                if (lettersOnly.test(inputValue)) {
                                                    setFirstName(inputValue)
                                                }
                                            }}
                                        />
                                    </GridLayoutItem>

                                    <GridLayoutItem col={2} row={1} className="form-inner">
                                        <Label className="form-heading">Last Name</Label>
                                        <Input
                                            name="lastName"
                                            value={lastName}
                                            // placeholder='Enter last name'
                                            className="field-ip na_inputs"
                                            onChange={(e) => {
                                                const inputValue = e?.target?.value;
                                                const lettersOnly = /^[A-Za-z]*$/;
                                                if (lettersOnly.test(inputValue)) {
                                                    setLastName(inputValue)
                                                }
                                            }}
                                        />
                                    </GridLayoutItem>
                                    <GridLayoutItem col={1} row={2} colSpan={2} className="form-inner">
                                        <Label className="form-heading">Email</Label>
                                        <Input
                                            name="ticketId"
                                            value={email}
                                            // placeholder='Enter email address'
                                            className="field-ip na_inputs"
                                            onChange={(e) => {
                                                let value = e.target.value?.trim();
                                                if (value.length > 0) {
                                                    value = value[0].toLowerCase() + value.slice(1);
                                                }
                                                setEmail(value)
                                            }}
                                            autoComplete="off"
                                            type='email'
                                        />
                                    </GridLayoutItem>

                                    {/* <GridLayoutItem col={1} row={3} className="form-inner">
                                <Label className="form-heading">City</Label>
                                <div>
                                    <input
                                        type="text"
                                        ref={autoCompleteRef}
                                        // placeholder="Search for your city"
                                        className="field-ip na_inputs hide-placeholder"
                                        onChange={(e) => {
                                            if (e?.target?.value?.trim() === "") {
                                                setAddress((preValue) => (
                                                    ({
                                                        address: '',
                                                        city: '',
                                                        province: '',
                                                        postalCode: '',
                                                        country: '',
                                                        latitude: 0.00,
                                                        longitude: 0.00,
                                                        seacrhTerm: ""
                                                    })
                                                ))
                                            } else {
                                                setAddress((preValue) => ({ ...preValue, seacrhTerm: e?.target?.value }))
                                            }

                                        }}
                                        style={{ padding: '0px 10px', width: '94%', borderRadius: "4px", outline: "none" }}
                                    />
                                </div>
                            </GridLayoutItem> */}

                                    <GridLayoutItem col={1} row={3} className="form-inner">
                                        <Label className="form-heading">City <span className='na_optional'>(optional)</span></Label>
                                        <div>
                                            <GooglePlacesAutocomplete
                                                onLoad={(newInstance) => onLoadAuto(newInstance)} // Sets instance of Autocomplete to be referenced
                                                onPlaceChanged={(place) => handlePlaceChanged(place)}
                                                options={{
                                                    types: ['locality'], // Restrict to cities
                                                    componentRestrictions: { country: ["US", "CA", "GB"] }, // Optional: Restrict to certain countries
                                                }}
                                            >

                                                <Input
                                                    name="ticketId"
                                                    value={citySearchTerm}
                                                    className="field-ip na_inputs hide-placeholder"
                                                    onChange={(e) => {
                                                        setPlaceDetails(null);
                                                        setCitySearchTerm(e.target.value)
                                                    }}
                                                />

                                            </GooglePlacesAutocomplete>
                                        </div>
                                    </GridLayoutItem>
                                </GridLayout>
                            )}
                        />
                    </div>



                    <div style={{ textAlign: 'end' }} className='form_main_container_buttons'>
                        {
                            showLoader ?
                                (
                                    <div className='na_loader_btn'>
                                        <Loader size="small" style={{ color: '#083DB8' }} />
                                    </div>
                                )
                                :
                                (
                                    <>

                                        <KendoButton
                                            iconClass=""
                                            size={16}
                                            className="na_action_btn"
                                            themeColor={"primary"}
                                            buttonName="Next"
                                            onClick={onProfileNextClick}
                                            disabled={firstName?.trim() === "" || lastName?.trim() === "" || email?.trim() === ""}
                                        />

                                    </>
                                )
                        }
                    </div>
                </div>

            </>
        )
    }

    const renderTradeForm = () => {
        return (
            <>
                <div className='form_main_container'>
                    <div className='form_main_container_inputs'>
                        <h3 className='na_form_title'>
                            What do you do for work?
                        </h3>
                        <p className='na_form_description'>Your trade will help you connect with similar BuildID users.</p>
                        <div style={{ marginTop: '30px' }}>
                            <Form
                                render={(formRenderProps) => (
                                    <GridLayout
                                        rows={[
                                            {
                                                height: "auto",
                                            },
                                            {
                                                height: 70,
                                            },
                                            {
                                                height: 70,
                                            },
                                        ]}
                                        cols={[
                                            {
                                                width: 375,
                                            },
                                        ]}
                                        gap={{
                                            rows: 20,
                                            cols: 35,
                                        }}>

                                        <GridLayoutItem col={1} row={1} className="form-inner">
                                            <Label className="form-heading">Trade <span className='na_optional'>(optional)</span></Label>
                                            <div style={{ position: 'relative' }}>
                                                <AutoComplete
                                                    data={tradeOptions}
                                                    value={selectedTrade?.label ?? ""}
                                                    className="field-ip na_inputs"
                                                    onChange={async (event) => {
                                                        setSearchLoader(true);
                                                        const searchTerm = event.target.value;
                                                        setSelectedTrade({ label: event.target.value, id: 0 });
                                                        if (searchTerm) {
                                                            setTradeOpen(true);
                                                            if (tradeDebounceTimeout.current) {
                                                                clearTimeout(tradeDebounceTimeout.current);
                                                            }
                                                            tradeDebounceTimeout.current = setTimeout(() => {
                                                                getTradeList(event.target.value);
                                                            }, 1000);
                                                        } else {
                                                            getTradeList("");
                                                            setTradeOpen(false);
                                                        }
                                                    }}
                                                    textField="label"
                                                    opened={tradeOpen}
                                                    placeholder="Select Trade"
                                                    itemRender={(li, itemProps) => {
                                                        const trade = itemProps.dataItem;
                                                        return (
                                                            <div className="user-item na_selection_text" onClick={() => {
                                                                setSearchLoader(false);
                                                                setSelectedTrade(trade)
                                                                setTradeOpen(false);
                                                            }}>
                                                                <span>{trade?.label}</span>
                                                            </div>
                                                        );
                                                    }}
                                                    onFocus={() => {
                                                        setTradeOpen(true);
                                                    }}
                                                    onBlur={() => {
                                                        setTradeOpen(false);
                                                    }}
                                                />
                                                {
                                                    selectedTrade?.label?.trim() === "" && (
                                                        <div style={{ position: 'absolute', top: '35%', right: '10px', cursor: 'pointer', zIndex: 1 }}
                                                            onClick={() => {
                                                                setTradeOpen((preValue) => (!preValue));
                                                            }}
                                                        >
                                                            <IconComponent
                                                                size={width <= 1400 ? 20 : 15}
                                                                iconName={tradeOpen ? "ChevronUp" : "ChevronDown"}
                                                                color="#B5B5B5"
                                                            />
                                                        </div>
                                                    )
                                                }

                                                {
                                                    searchLoader && selectedTrade?.label?.trim() !== "" && (
                                                        <div style={{ position: 'absolute', top: '30%', right: '2%', cursor: 'pointer', zIndex: 999, backgroundColor: "white" }}
                                                        >
                                                            <Loader size={width <= 1400 ? "medium" : "small"} style={{ color: '#083DB8' }} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </GridLayoutItem>
                                    </GridLayout>
                                )}
                            />
                        </div>
                    </div>

                    <div>
                        <div style={{ display: "flex", justifyContent: 'space-between' }} className='form_main_container_buttons'>
                            <KendoButton
                                iconClass=""
                                size={16}
                                className="na_action_btn na_back_btn"
                                themeColor={"primary"}
                                buttonName="Back"
                                onClick={onBackPress}
                            />
                            <KendoButton
                                iconClass=""
                                size={16}
                                className="na_action_btn"
                                themeColor={"primary"}
                                buttonName="Next"
                                onClick={onTradeNextClick}
                            />
                        </div>
                    </div>

                </div>
            </>
        )
    }

    const renderCompanyForm = () => {
        return (
            <>
                <div className='form_main_container'>
                    <div className='form_main_container_inputs'>
                        <h3 className='na_form_title'>
                            Which company do you work for?
                        </h3>
                        <p className='na_form_description'>If you are currently unemployed, you can skip this question.</p>
                        <div style={{ marginTop: '30px' }}>

                            <Form
                                render={(formRenderProps) => (
                                    <GridLayout
                                        rows={[
                                            {
                                                height: "auto",
                                            },
                                            {
                                                height: 70,
                                            },
                                            {
                                                height: 70,
                                            },
                                        ]}
                                        cols={[
                                            {
                                                width: 375,
                                            },
                                        ]}
                                        gap={{
                                            rows: 20,
                                            cols: 35,
                                        }}>

                                        <GridLayoutItem col={1} row={1} className="form-inner">
                                            <label className="form-heading">Company <span className='na_optional'>(optional)</span></label>
                                            <div style={{ position: 'relative' }}>
                                                <AutoComplete
                                                    data={companyOptions}
                                                    value={selectedCompany?.label ?? ""}
                                                    className="field-ip na_inputs"
                                                    onChange={async (event) => {
                                                        setSearchLoader(true);
                                                        const searchTerm = event.target.value;
                                                        setSelectedCompany({ label: event.target.value, id: 0 });
                                                        if (searchTerm) {
                                                            setCompanyOpen(true);
                                                            if (companyDebounceTimeout.current) {
                                                                clearTimeout(companyDebounceTimeout.current);
                                                            }
                                                            companyDebounceTimeout.current = setTimeout(() => {
                                                                getCompanyList(event.target.value);
                                                            }, 1000);
                                                        } else {
                                                            setCompanyOpen(false);
                                                        }
                                                    }}
                                                    textField="label"
                                                    opened={companyOpen}
                                                    onBlur={() => {
                                                        setCompanyOpen(false)
                                                    }}
                                                    itemRender={(li, itemProps) => {
                                                        const company = itemProps.dataItem;
                                                        return (
                                                            <div className="user-item na_selection_text" onClick={() => {
                                                                setSearchLoader(false);
                                                                setSelectedCompany(company)
                                                                setCompanyOpen(false);
                                                            }}>
                                                                <span>{company?.label}</span>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                                {
                                                    selectedCompany?.label?.trim() === "" && (
                                                        <div style={{ position: 'absolute', top: '35%', right: '10px' }}>
                                                            <IconComponent
                                                                size={width <= 1400 ? 20 : 15}
                                                                iconName={"Search"}
                                                                color="#B5B5B5"
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    searchLoader && selectedCompany?.label?.trim() !== "" && (
                                                        <div style={{ position: 'absolute', top: '30%', right: '2%', cursor: 'pointer', zIndex: 999, backgroundColor: "white" }}
                                                        >
                                                            <Loader size={width <= 1400 ? "medium" : "small"} style={{ color: '#083DB8' }} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </GridLayoutItem>
                                    </GridLayout>
                                )}
                            />


                        </div>
                    </div>


                    <div className='form_main_container_buttons'>
                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <KendoButton
                                iconClass=""
                                size={16}
                                className="na_action_btn na_back_btn"
                                themeColor={"primary"}
                                buttonName="Back"
                                onClick={onBackPress}
                            />
                            {
                                showLoader ?
                                    (
                                        <div className='na_loader_btn'>
                                            <Loader size="small" style={{ color: '#083DB8' }} />
                                        </div>
                                    )
                                    :
                                    (
                                        <KendoButton
                                            iconClass=""
                                            size={16}
                                            className="na_action_btn"
                                            themeColor={"primary"}
                                            buttonName="Next"
                                            onClick={onCompanyNextClick}
                                        />
                                    )
                            }

                        </div>
                    </div>

                </div>
            </>
        )
    }

    const renderSiteForm = () => {
        return (
            <>
                <div className='form_main_container'>

                    <div className='form_main_container_inputs'>
                        <h3 className='na_form_title'>
                            What site are you currently working on?
                        </h3>
                        <div style={{ marginTop: '30px' }}>
                            <Form
                                render={(formRenderProps) => (
                                    <GridLayout
                                        rows={[
                                            {
                                                height: "auto",
                                            },
                                            {
                                                height: 70,
                                            },
                                            {
                                                height: 70,
                                            },
                                        ]}
                                        cols={[
                                            {
                                                width: 375,
                                            },
                                            {
                                                width: 375,
                                            },
                                        ]}
                                        gap={{
                                            rows: 20,
                                            cols: 35,
                                        }}
                                    >

                                        <GridLayoutItem col={1} row={1} className="form-inner">
                                            <label className="form-heading">Site <span className='na_optional'>(optional)</span></label>
                                            {
                                                selectedSite?.id === 0 ?
                                                    (
                                                        <div style={{ position: 'relative' }}>
                                                            <AutoComplete
                                                                data={siteOptions}
                                                                valueRender={(value) => {
                                                                    // Render the value in the input field with custom formatting
                                                                    if (selectedSite?.id) {
                                                                        return (
                                                                            <div style={{ padding: '20px 10px', height: 'auto', width: 'auto' }}>
                                                                                <p style={{ margin: "0px", padding: '0px', fontSize: '18px' }}>{selectedSite?.label ?? ""}</p>
                                                                                <p style={{
                                                                                    margin: "0px", padding: '0px',
                                                                                    fontSize: '14px', color: "#7b7070",
                                                                                }}>{selectedSite?.address ?? ""}</p>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return value;
                                                                }}
                                                                style={{}}
                                                                // value={selectedSite?.label ?? ""}
                                                                className="field-ip na_inputs"
                                                                onChange={async (event) => {
                                                                    setSearchLoader(true);
                                                                    const searchTerm = event.target.value;
                                                                    setSelectedSite({ label: event.target.value, id: 0, address: "" });
                                                                    if (searchTerm) {
                                                                        setSiteOpen(true);
                                                                        if (siteDebounceTimeout.current) {
                                                                            clearTimeout(siteDebounceTimeout.current);
                                                                        }
                                                                        siteDebounceTimeout.current = setTimeout(() => {
                                                                            getSiteList(event.target.value);
                                                                        }, 1000);
                                                                    } else {
                                                                        setSiteOpen(false);
                                                                    }
                                                                }}
                                                                textField="label"
                                                                opened={siteOpen}
                                                                onBlur={() => {
                                                                    setSiteOpen(false)
                                                                }}
                                                                itemRender={(li, itemProps) => {
                                                                    const site = itemProps.dataItem;
                                                                    return (
                                                                        <div className="user-item na_selection_text" onClick={() => {
                                                                            setSearchLoader(false);
                                                                            setSelectedSite(site)
                                                                            setSiteOpen(false);
                                                                        }}>
                                                                            <p style={{ margin: "0px", padding: '0px' }}>{site?.label}</p>
                                                                            <p style={{ margin: "0px", padding: '0px', fontSize: '16px', color: "#7b7070" }}>{site?.address}</p>
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                            {
                                                                selectedSite?.label?.trim() === "" && (
                                                                    <div style={{ position: 'absolute', top: '34%', right: '10px' }}>
                                                                        <IconComponent
                                                                            size={width <= 1400 ? 20 : 15}
                                                                            iconName={"Search"}
                                                                            color="#B5B5B5"
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                searchLoader && selectedSite?.label?.trim() !== "" &&
                                                                (
                                                                    <div style={{ position: 'absolute', top: '30%', right: '2%', cursor: 'pointer', zIndex: 999, backgroundColor: "white" }}
                                                                    >
                                                                        <Loader size={width <= 1400 ? "medium" : "small"} style={{ color: '#083DB8' }} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                    )
                                                    :
                                                    (
                                                        <div className='selected_site_container'>
                                                            <div className='selected_site_details'>
                                                                <div className='selcted_site_name' style={{ margin: "0px", padding: '0px' }}>
                                                                    {selectedSite?.label ?? ""}
                                                                </div>
                                                                <div className='selcted_site_address' style={{ margin: "0px", padding: '0px', fontSize: '16px', color: "#7b7070" }}>
                                                                    {selectedSite?.address ?? ""}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <IconComponent iconName={"X"} size={30} onClick={() => {
                                                                    setSelectedSite({ id: 0, label: "", address: "" })
                                                                }}
                                                                    color="#5B5B5B"
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                            }

                                        </GridLayoutItem>
                                    </GridLayout>
                                )}
                            />
                        </div>

                    </div>

                    <div>

                        <div style={{ display: "flex", justifyContent: 'space-between' }} className='form_main_container_buttons'>
                            <KendoButton
                                iconClass=""
                                size={16}
                                className="na_action_btn na_back_btn"
                                themeColor={"primary"}
                                buttonName="Back"
                                onClick={onBackPress}
                            />

                            {
                                showLoader ?
                                    (
                                        <div className='na_loader_btn'>
                                            <Loader size="small" style={{ color: '#083DB8' }} />
                                        </div>
                                    )
                                    :
                                    (
                                        <KendoButton
                                            iconClass=""
                                            size={16}
                                            className="na_action_btn"
                                            themeColor={"primary"}
                                            buttonName="Next"
                                            onClick={onSiteNextClick}
                                        />
                                    )
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderWelcome = () => {
        return (
            <>
                <div style={{ marginTop: '20px' }}>

                    <h4 className='na_form_title'>Welcome to our construction community!</h4>
                    <div style={{ marginTop: '20px' }}>
                        <p className='na_form_description' style={{ color: '#545050' }}>Finish setting up your account by:</p>
                        <div style={{ color: '#545050' }}>
                            <p>1. Download the BuildID app on your device</p>
                            <p>2. Tap on <b className='light_bold'>Log In</b> </p>
                            <p>3. Tap on <b className='light_bold'>Forgot your password?</b></p>
                        </div>
                    </div>

                    {/* <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        themeColor={"primary"}
                        style={{ marginTop: '20px' }}
                        buttonName="Create another account"
                        onClick={() => {
                            resetAllState();
                            setFormType(1)
                        }}
                    /> */}

                </div>
            </>
        )
    }

    // if (showLoader) {
    //     return (
    //         <Loader
    //             type="converging-spinner"
    //             className="kendo-spinner"
    //             style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 minHeight: "500px",
    //                 alignItems: "center",
    //             }}
    //         />
    //     );
    // }



    return (
        <>
            <div className='new_account_container'>
                <div style={{ height: '100%' }}>
                    <div className='new_account_form_container'>
                        <div className='form_container'>
                            <div>
                                <img
                                    src={BuildIDLogo}
                                    alt=""
                                    style={{
                                        maxWidth: "100%", maxHeight: "100%",
                                        width: "auto", height: "auto"
                                    }} />
                            </div>
                            {
                                formType != 5 && (
                                    <div className='progress_container'>
                                        {
                                            tabs?.map((tab, index) => (
                                                <div className='progress_info' key={index}>
                                                    <div className='progress_bar_hr' style={{ backgroundColor: tab?.value <= formType ? "#116fa5" : "#afafaf" }}>
                                                    </div>
                                                    <div className='progress_bar_title' style={{ color: tab?.value === formType ? "#116fa5" : "#afafaf", fontWeight: '500' }}>
                                                        {tab?.title}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }


                            <div style={{ marginTop: '30px' }}>
                                {
                                    formType === 1 && renderProfileForm()
                                }
                                {
                                    formType === 2 && renderTradeForm()
                                }
                                {
                                    formType === 3 && renderCompanyForm()
                                }
                                {
                                    formType === 4 && renderSiteForm()
                                }
                                {
                                    formType === 5 && renderWelcome()
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewAccount;