import React, { useEffect, useState } from "react";
import "./AddNew.css";
import { useParams } from "react-router-dom";
import {
  Delete_DataIssues,
  FETCH_DATAISSUES,
  FETCH_RECORD_STATUSES,
  SAVE_DATAISSUES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { Form } from "@progress/kendo-react-form";
import AddPageLayout from "../../addpagelayout/AddPageLayout";

const initialDataIssueState = {
  recordId: "",
  dataSource: "",
  dataComments: "",
  dataIncorrect: false,
  dataOffensive: false,
  dataOther: false,
  recordStatusId: 1,
  recordStatus: "New",
};

const AddNewRecord = () => {
  const { dataId, subMenu } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-data-issue";
  const [dataIssue, setDataIssue] = useState(initialDataIssueState);
  const [showLoader, setShowLoader] = useState(false);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [footerInfo, setFooterInfo] = useState({});
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      loadDataIssue();
    }
    getRecordStatuses();
  }, []);

  const loadDataIssue = async () => {
    if (dataId !== "add-data-issue") {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          {
            Id: dataId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_DATAISSUES,
        obj,
        loginData?.token
      );
      let data = result?.data?.data?.[0] ?? null;
      setDataIssue((prevData) => ({
        ...prevData,
        ...data,
        recordId: data?.recordId,
        dataSource: data?.dataSource,
        dataComments: data?.dataComments,
        dataIncorrect: data?.dataIncorrect,
        dataOffensive: data?.dataOffensive,
        dataOther: data?.dataOther,
        recordStatusId: data?.recordStatusId,
        recordStatus: data?.recordStatus,
      }));
      let footerObj = {
        dateCreated: data?.dateCreated ?? null,
        modifiedByUser: data?.modifiedByUser ?? "",
        dateModified: data?.dateModified ?? "",
        createdByUser: data?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const onInputChange = (e, type) => {
    let inputValue = e.target.value;
    if (type === "number") {
      inputValue = inputValue.replace(/\D/g, "");
    }
    setIsFiledEdited(true);
    setDataIssue({ ...dataIssue, [e.target.name]: inputValue });
  };

  const saveCurrentData = async () => {
    if (dataIssue.recordId === "") {
      return toast.error("Please enter a valid Record ID", {
        position: "top-right",
      });
    }

    if (dataIssue.dataSource === "") {
      return toast.error("Please enter a valid Data Source", {
        position: "top-right",
      });
    }

    setShowLoader(true);
    const newDataIssue = {
      UserId: loginData.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: isAddForm ? 0 : dataId,
          RecordId: dataIssue?.recordId || 0,
          DataSource: dataIssue?.dataSource || "",
          DataIncorrect: dataIssue?.dataIncorrect,
          DataOffensive: dataIssue?.dataOffensive,
          DataOther: dataIssue?.dataOther,
          DataComments: dataIssue?.dataComments || "",
          RecordStatusId: dataIssue?.recordStatusId || 1,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_DATAISSUES,
        newDataIssue,
        loginData.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${
            dataId !== "add-data-issue" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: dataId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(Delete_DataIssues, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={!isAddForm}
        footerInfo={footerInfo}
        isFieldEdited={isFieldEdited}
        recordText={"Company ID"}
        saveCurrentData={saveCurrentData}
        onDeleteClick={onDeleteClick}>
        <div
          style={{
            width: "100%",
          }}>
          <Form
            render={(formRenderProps) => (
              <GridLayout
                rows={[
                  {
                    height: 70,
                  },
                  {
                    height: 70,
                  },
                  {
                    height: 70,
                  },
                  {
                    height: 70,
                  },
                ]}
                cols={[
                  {
                    width: 375,
                  },
                  {
                    width: 375,
                  },
                  {
                    width: 375,
                  },
                ]}
                gap={{
                  rows: 25,
                  cols: 35,
                }}
                style={{ padding: "25px 16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <Label className="form-heading">Record ID :</Label>
                  <Input
                    type="text"
                    className="field-ip"
                    name="recordId"
                    value={dataIssue.recordId}
                    onChange={(e) => {
                      onInputChange(e, "number");
                    }}
                    style={{ "-moz-appearance": "textfield" }}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <Label className="form-heading">Data Source :</Label>
                  <Input
                    className="field-ip"
                    type="text"
                    name="dataSource"
                    value={dataIssue.dataSource}
                    onChange={onInputChange}
                  />
                </GridLayoutItem>

                <GridLayoutItem
                  col={3}
                  row={1}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <Label className="form-heading">Data Incorrect :</Label>
                  <div>
                    <Switch
                      size={"small"}
                      checked={dataIssue.dataIncorrect}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setDataIssue((preValue) => ({
                          ...preValue,
                          dataIncorrect: e?.value,
                        }));
                      }}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  col={1}
                  row={2}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <Label className="form-heading">Data Offensive :</Label>

                  <div>
                    <Switch
                      size={"small"}
                      checked={dataIssue.dataOffensive}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setDataIssue((preValue) => ({
                          ...preValue,
                          dataOffensive: e?.value,
                        }));
                      }}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  col={2}
                  row={2}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <Label className="form-heading">Data Other :</Label>

                  <div>
                    <Switch
                      size={"small"}
                      checked={dataIssue.dataOther}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setDataIssue((preValue) => ({
                          ...preValue,
                          dataOther: e?.value,
                        }));
                      }}
                    />
                  </div>
                </GridLayoutItem>

                {!isAddForm || subMenu ? (
                  <GridLayoutItem col={3} row={2} className="form-inner">
                    <div className="form-group">
                      <Label className="form-heading">Record Status :</Label>
                      <div>
                        <DropDownList
                          style={{ width: "370px" }}
                          onChange={(e) => {
                            setDataIssue((preValue) => ({
                              ...preValue,
                              recordStatus: e.value?.text,
                              recordStatusId: e.value?.id,
                            }));
                          }}
                          className="dropdown-list"
                          value={{
                            id: dataIssue?.recordStatusId,
                            text: dataIssue?.recordStatus,
                          }}
                          data={recordStatusOptions}
                          textField="text"
                          dataItemKey="id"
                        />
                      </div>
                    </div>
                  </GridLayoutItem>
                ) : (
                  <></>
                )}
                <GridLayoutItem
                  col={1}
                  row={3}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <Label className="form-heading">Comment :</Label>
                  <TextArea
                    type="text"
                    id="dataComments"
                    name="dataComments"
                    value={dataIssue.dataComments}
                    className="field-ip"
                    onChange={onInputChange}
                    rows={5}
                  />
                </GridLayoutItem>
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddNewRecord;
