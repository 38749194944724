import React, { useEffect, useReducer, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Input, TextBox } from "@progress/kendo-react-inputs";
import { useParams } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { useDispatch, useSelector } from "react-redux";
import { FECTH_NOTIFICATION_MESSAGES } from "../constant/Apipath";
import { PostRequestCall } from "../apicall/PostRequest";
import { IconComponent } from "../common/Icon";
import KendoButton from "../common/KendoButton";
import { clearFiterData } from "../redux/actions/action";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const initialState = {
  data: [],
  total: 0,
  skip: 0,
  take: 20,
  currentPage: 1,
  fullSearch: "",
  nrOfRecPerPage: 20,
  pageNr: 1,
  searchList: [],
  sortList: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DATA":
      return { ...state, data: action.payload };
    case "TOTAL":
      return { ...state, total: action.payload };
    case "SKIP":
      return { ...state, skip: action.payload };
    case "TAKE":
      return { ...state, take: action.payload };
    case "CURRENT_PAGE":
      return { ...state, currentPage: action.payload };
    case "FULL_SEARCH":
      return { ...state, fullSearch: action.payload };
    case "NR_OF_REC_PER_PAGE":
      return { ...state, nrOfRecPerPage: action.payload };
    case "PAGE_NR":
      return { ...state, pageNr: action.payload };
    case "SEARCH_LIST":
      return { ...state, searchList: action.payload };
    case "SORT_LIST":
      return { ...state, sortList: action.payload };
    default:
      return state;
  }
};

const formatDate = (dateString) => {
  if (dateString) {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  } else {
    return null;
  }
};
const NotificationMessages = () => {
  const { userId } = useParams();
  const { loginData, filterData } = useSelector((state) => state.main);
  const reduxDispatch = useDispatch();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState();
  const [isFilter, setIsfilter] = useState(false);
  const [editRecord, setEditRecord] = useState("");
  const [pageSizeValue, setPageSizeValue] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showLoader, setShowLoader] = useState(true);

  const [tableFileds, setTableFileds] = useState([
    {
      field: "id",
      value: "numeric",
      title: "Id",
      width: "70px",
      filter: "numeric",
      editable: false,
      filterCell: (props) => CustomFilterCellForOnlyNumber(props),
    },
    {
      field: "senderName",
      value: "numeric",
      title: "Sender Name",
      width: "150px",
      filter: "numeric",
      editable: false,
      filterCell: (props) => CustomFilterCellForOnlyNumber(props),
    },
    {
      field: "categoryName",
      value: "numeric",
      title: "Category Name",
      width: "150px",
      filter: "numeric",
      editable: false,
      filterCell: (props) => CustomFilterCellForOnlyNumber(props),
    },
    {
      field: "dateSent",
      value: "text",
      title: "Date Sent",
      width: "auto",
      filter: "date",
      editable: false,
      filterable: false,
    },
    {
      field: "title",
      value: "numeric",
      title: "Title",
      width: "auto",
      filter: "numeric",
      editable: false,
      filterCell: (props) => CustomFilterCellForOnlyNumber(props),
    },
    {
      field: "message",
      value: "numeric",
      title: "Message",
      width: "auto",
      filter: "numeric",
      editable: false,
      filterCell: (props) => CustomFilterCellForOnlyNumber(props),
    },
    {
      field: "nrOfMessagesSent",
      value: "numeric",
      title: "No Of MessagesSent",
      width: "70px",
      filter: "numeric",
      editable: false,
      filterCell: (props) => CustomFilterCellForOnlyNumber(props),
    },
    {
      field: "nrOfMessagesRead",
      value: "numeric",
      title: "No Of MessagesRead",
      width: "70px",
      filter: "numeric",
      editable: false,
      filterCell: (props) => CustomFilterCellForOnlyNumber(props),
    },
    {
      field: "createdByUser",
      value: "text",
      title: "Created ByUser",
      width: "auto",
      filter: "text",
      editable: true,
    },
  ]);

  const debounceTimeout = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, []);

  const CustomFilterCellForOnlyNumber = (props) => {
    const handleChange = (e) => {
      let inputValue = e.target.value;
      inputValue = inputValue.replace(/\D/g, "");
      props.onChange({
        value: inputValue,
        operator: props.operator,
        syntheticEvent: e.syntheticEvent,
      });
    };
    return <Input value={props.value || ""} onChange={handleChange} />;
  };
  useEffect(() => {
    fetchNotification(getObjectForFirstTimeApiCall());
  }, []);
  const CustomBooleanFilterCell = (props) => {
    const handleChange = (event) => {
      const value =
        event.value.value === true
          ? true
          : event.value.value === false
            ? false
            : undefined;
      props.onChange({
        value,
        operator: "eq",
        syntheticEvent: event,
      });
    };

    return (
      <DropDownList
        className="k-select"
        onChange={handleChange}
        value={
          props.value === true
            ? { text: "Yes", value: true }
            : props.value === false
              ? { text: "No", value: false }
              : { text: "All", value: "" }
        }
        data={[
          { text: "All", value: "" },
          { text: "Yes", value: true },
          { text: "No", value: false },
        ]}
        textField="text"
        valueField="value"
        dataItemKey="text"
      />
    );
  };
  const fetchNotification = async (requestObj) => {
    try {
      const searchListArray = userId
        ? [...requestObj?.searchList, { UserId: userId }]
        : requestObj?.searchList;
      var data = JSON.stringify({
        Id: 0,
        NrOfRecPerPage: requestObj?.nrOfRecPerPage,
        PageNr: requestObj?.pageNr,
        FullSearch: requestObj?.fullSearch,
        SearchList: searchListArray,

        SortList: [
          ...requestObj?.sortList,
          { fieldName: "id", direction: "desc" },
        ],
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        DoNotShowInSystemFields: false,
      });
      const result = await PostRequestCall(
        FECTH_NOTIFICATION_MESSAGES,
        data,
        loginData?.token
      );

      const getUpdatedlist = result?.data?.data.map((item) => ({
        ...item,
        dateCreated: formatDate(item.dateCreated),
        notificationDate: formatDate(item.notificationDate),
        dateModified: formatDate(item.dateModified),
        dateSent: formatDate(item.dateSent),
        selected: false,
        inEdit: true,
      }));

      dispatch({ type: "DATA", payload: getUpdatedlist });
      dispatch({ type: "TOTAL", payload: result?.data?.nrOfRecords });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const getObjectForFirstTimeApiCall = () => {
    let previousFilterValues =
      filterData?.screen === "Notification" ? { ...filterData } : null;
    let obj = {
      skip: previousFilterValues?.skip ?? 0,
      take: previousFilterValues?.take ?? 20,
      currentPage: previousFilterValues?.currentPage ?? 1,
      nrOfRecPerPage: previousFilterValues?.nrOfRecPerPage ?? 20,
      pageNr: previousFilterValues?.pageNr ?? 1,
      fullSearch: previousFilterValues?.fullSearch ?? "",
      searchList: previousFilterValues?.searchList ?? [],
      sortList: previousFilterValues?.sortList ?? [],
    };
    if (previousFilterValues) {
      dispatch({ type: "SKIP", payload: previousFilterValues?.skip ?? 0 });
      dispatch({ type: "TAKE", payload: previousFilterValues?.take ?? 20 });
      dispatch({
        type: "CURRENT_PAGE",
        payload: previousFilterValues?.currentPage ?? 1,
      });
      dispatch({
        type: "FULL_SEARCH",
        payload: previousFilterValues?.fullSearch ?? "",
      });
      dispatch({
        type: "NR_OF_REC_PER_PAGE",
        payload: previousFilterValues?.nrOfRecPerPage ?? 20,
      });
      dispatch({ type: "PAGE_NR", payload: previousFilterValues?.pageNr ?? 1 });
      dispatch({
        type: "SEARCH_LIST",
        payload: previousFilterValues?.searchList ?? [],
      });
      dispatch({
        type: "SORT_LIST",
        payload: previousFilterValues?.sortList ?? [],
      });
      reduxDispatch(clearFiterData());
    }
    return obj;
  };

  const getObjectForApiCall = (changesArray) => {
    setShowLoader(true);
    let obj = {
      skip: state?.skip,
      take: state?.take,
      currentPage: state?.currentPage,
      nrOfRecPerPage: state?.nrOfRecPerPage,
      pageNr: state?.pageNr,
      fullSearch: state?.fullSearch,
      searchList: state?.searchList,
      sortList: state?.sortList,
    };

    if (changesArray && Array.isArray(changesArray)) {
      changesArray.forEach((change) => {
        const { key, value } = change;
        if (key !== undefined && value !== undefined) {
          obj[key] = value;
        }
      });
    }
    return obj;
  };

  const handleSortingChange = async (e) => {
    setSort(e.sort);
    let getSortedFields = e.sort.map((item) => {
      return { fieldName: item?.field, direction: item?.dir };
    });
    dispatch({
      type: "SORT_LIST",
      payload: getSortedFields?.length > 0 ? getSortedFields : [],
    });
    let changes = [
      {
        key: "sortList",
        value: getSortedFields?.length > 0 ? getSortedFields : [],
      },
    ];
    fetchNotification(getObjectForApiCall(changes));
  };

  const handleSearchChange = async (e) => {
    dispatch({
      type: "FULL_SEARCH",
      payload: e.target.value === "" ? "" : e.target.value,
    });
  };

  const pageChange = async (event) => {
    setShowLoader(true);
    let getCurrentpage = Math.ceil((event.page.skip + 1) / event.page.take);
    let targetEvent = event.targetEvent;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }

    let take = targetEvent.value === "All" ? state?.total : event.page.take;
    dispatch({ type: "NR_OF_REC_PER_PAGE", payload: take });
    dispatch({
      type: "PAGE_NR",
      payload: targetEvent.value === "All" ? 0 : getCurrentpage,
    });
    dispatch({ type: "SKIP", payload: event.page.skip });
    dispatch({ type: "TAKE", payload: take });
    dispatch({ type: "CURRENT_PAGE", payload: getCurrentpage });
    let changes = [
      { key: "nrOfRecPerPage", value: take },
      {
        key: "pageNr",
        value: targetEvent.value === "All" ? 0 : getCurrentpage,
      },
      { key: "skip", value: event.page.skip },
      { key: "take", value: take },
      { key: "currentPage", value: getCurrentpage },
    ];
    await fetchNotification(getObjectForApiCall(changes));
    setSort([]);
  };

  const currentRow = ({ dataItem }) => { };

  const updateFilterdata = async (event) => {
    if (event.filter !== null) {
      let getFilterfields = event.filter.filters
        .map((item) => {
          let getField = tableFileds.filter(
            (check) => check.field === item.field
          );
          let newobj = {};
          if (getField[0].field === "recordStatus") {
          } else {
            newobj[getField[0].field] = item.value;
          }
          return newobj;
        })
        .filter((obj) => {
          return Object.values(obj).some(
            (value) => value !== "" && value !== null
          );
        });

      setFilter(event.filter);
      dispatch({ type: "SEARCH_LIST", payload: getFilterfields });
      let changes = [{ key: "searchList", value: getFilterfields }];

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        fetchNotification(getObjectForApiCall(changes));
      }, 1000);
    } else {
      dispatch({ type: "SEARCH_LIST", payload: [{}] });
      let changes = [{ key: "searchList", value: [{}] }];
      fetchNotification(getObjectForApiCall(changes));
      setFilter(event.filter);
    }
  };

  const itemChange = (e) => {
    let newData = state?.data.map((item) => {
      let result = {};
      if (item.id === e?.dataItem?.id) {
        item[e.field || ""] = e.value;
        result = {
          ...item,
          isRecordEdit: true,
        };
      } else {
        result = { ...item };
      }
      return result;
    });
    dispatch({ type: "DATA", payload: newData });
  };

  const onKeyDownList = (e) => {
    if (e.keyCode === 13) {
      dispatch({ type: "FULL_SEARCH", payload: e.target.value });
      dispatch({ type: "PAGE_NR", payload: 1 });
      let changes = [
        { key: "fullSearch", value: e.target.value },
        { key: "pageNr", value: 1 },
      ];
      fetchNotification(getObjectForApiCall(changes));
    }

    if (e.keyCode === 8 && e.target.value?.trim().length <= 1) {
      dispatch({ type: "FULL_SEARCH", payload: "" });
      dispatch({ type: "PAGE_NR", payload: 1 });
      let changes = [
        { key: "fullSearch", value: "" },
        { key: "pageNr", value: 1 },
      ];
      fetchNotification(getObjectForApiCall(changes));
    }
  };

  const onSearchClick = (e) => {
    e.preventDefault();
    dispatch({ type: "PAGE_NR", payload: 1 });
    let changes = [{ key: "pageNr", value: 1 }];
    fetchNotification(getObjectForApiCall(changes));
  };

  const onFilterClick = () => {
    if (isFilter) {
      dispatch({ type: "SEARCH_LIST", payload: [{}] });
      setFilter();
      let changes = [{ key: "searchList", value: [{}] }];
      fetchNotification(getObjectForApiCall(changes));
    }
    setIsfilter((preValue) => !preValue);
  };

  const showGridColumn = (item) => {
    if (item.field === "id" && editRecord === "inEdit") {
      return false;
    }
    if (userId && item?.field === "notifiedUserName") {
      return false;
    }
    return true;
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="main-list main-list-h-100">
      <div
        className="k-d-flex k-justify-content-between list-header table_filter_options"
        style={{ padding: "10px", background: "#ffff" }}>
        <div className="search-section">
          <TextBox
            className="search-record"
            placeholder="Search in all columns "
            suffix={() =>
              state?.fullSearch !== "" && (
                <IconComponent
                  size={25}
                  iconName={"X"}
                  className="search-icon"
                  onClick={async () => {
                    dispatch({ type: "FULL_SEARCH", payload: "" });
                    let changes = [{ key: "fullSearch", value: "" }];
                    await fetchNotification(getObjectForApiCall(changes));
                  }}
                />
              )
            }
            value={state?.fullSearch}
            onChange={handleSearchChange}
            onKeyDown={onKeyDownList}
          />

          <KendoButton
            iconClass="Search"
            size={16}
            className="action-btn search-btn"
            onClick={onSearchClick}
            buttonName="Search"
            disabled={state?.fullSearch === ""}
          />
          <span>Total Records : {state?.total}</span>
        </div>

        <div className="k-d-flex k-align-items-center" style={{ gap: "20px" }}>
          <>
            <KendoButton
              iconClass={isFilter ? "FunnelFill" : "Funnel"}
              size={18}
              className="action-btn"
              onClick={onFilterClick}
              buttonName="Filter"
            />
          </>
        </div>
      </div>

      <Grid
        className="user-list"
        data={state?.data}
        skip={state?.skip}
        take={state?.take}
        total={state?.total}
        onPageChange={pageChange}
        resizable={true}
        sortable={{
          allowUnsort: true,
          mode: "multiple",
        }}
        sort={sort}
        onSortChange={(e) => handleSortingChange(e)}
        onRowClick={currentRow}
        editField={editRecord}
        onItemChange={itemChange}
        filterable={isFilter}
        filter={filter}
        onFilterChange={(event) => updateFilterdata(event)}
        pageable={{
          buttonCount: 4,
          pageSizes: [5, 10, 15, 20, 50, 100, 500, 1000, "All"],
          pageSizeValue: pageSizeValue,
        }}>
        {tableFileds.map(
          (item) =>
            showGridColumn(item) && (
              <GridColumn
                editor={item?.value}
                editable={item?.editable}
                filterable={item?.filterable ?? true}
                filter={item?.filter}
                field={item?.field}
                title={item?.title}
                width={item?.width}
                cell={item?.cell}
                filterCell={item?.filterCell}
              />
            )
        )}
      </Grid>
    </section>
  );
};

export default NotificationMessages;
