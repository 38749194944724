/* Auth */
export const LOGIN = "users/login";
export const FORGOT_PASSWORD = "users/ForgotPassword";
export const VALIDATE_RESET_CODE = "users/IsValidResetCode";
export const RESET_PASSWORD = "users/ResetPassword";

/* Home */
export const FETCH_DATAISSUES = "users/FetchDataIssues";
export const FETCH_DATAISSUES_FILTER = "cd/FetchDataIssues";
export const SAVE_DATAISSUES = "users/SaveDataIssues";

export const FetchUsers2 = "users/FetchUsers2";
export const FETCH_USERS_2 = "Users/CD/FetchUsers5";
export const FetchRecordStatuses = "misc/FetchRecordStatuses";
export const Delete_DataIssues = "users/DeleteDataIssues";
export const FetchUserTradeCertifications =
  "users/FetchUserTradeCertifications2";
export const DeleteTradeCertifications = "users/DeleteUserTradeCertifications";
export const SaveTradeCertification = "users/SaveUserTradeCertification";
export const SAVE_TRADE_CERTIFICATION = "users/SaveUserTradeCertification";
export const FETCH_ROLES = "misc/FetchRoles";
export const FETCH_TRADES = "misc/FetchTrades";

export const FETCH_USER_ADS_USAGES = "Users/FetchUserUsages";
export const DELETE_USER_ADS_USAGES = "Users/DeleteUserUsages";
export const SAVE_USERS_USAGES = "users/SaveUserUsages";
export const DELETE_USERS_USAGES = "users/DeleteUserUsages";

// Manufacturer
export const FETCH_MANUFACTURER = "companies/fetchcompanies";
export const SAVE_MANUFACTURER = "companies/savecompanies2";
export const DELETE_MANUFACTURER = "companies/DeleteCompanies";
export const FETCH_PRODUCTS = "misc/fetchproducts";
export const FETCH_PRODUCT_RATINGS = "misc/FetchProductRatings";
export const FETCH_PRODUCT_BARCODES = "misc/FetchProductBarcodes";
export const FETCH_PRODUCT_PICTURES = "misc/FetchProductPictures";

export const SAVE_PRODUCTS = "misc/saveproducts";
export const DELETE_PRODUCTS = "misc/deleteproducts2";
// Companies Api
export const FETCH_COMPANIES = "companies/fetchcompanies";
export const FETCH_COMPANIES_ADDRESSES = "companies/FetchCompanyAddresses";
export const FETCH_COMPANIES_PRODUCTS = "companies/FetchCompanyProducts";
export const FETCH_COMPANIES_TRADES = "companies/FetchCompanyTrades/";
export const SAVE_COMPANIES_TRADES = "companies/SaveCompanyTrades";
export const DELETE_COMPANY_TRADE = "Companies/DeleteCompanyTrades2/";

//User Api
export const FETCH_USERS = "users/FetchUsers2";
export const FETCH_USERS_LAST_60_DAYS = "Users/CD/FetchUsers_LastDays";
export const FETCH_USERS_LAST_ACCESS = "Users/CD/FetchUsers_LastAccess";
export const FETCH_USERS_LAST_MONTH_YEAR = "Users/CD/FetchUsers_LastMonthYear";
export const FETCH_USERS_LOGIN_BY_COUNTRY =
  "Users/CD/FetchUsers_LoginByCountry";
export const FETCH_ROLES_AUTH = "users/FetchRolesAuth";
export const FETCH_USER_QUESTION_ANSWER_TYPES =
  "users/FetchUserQuestionAnswerTypes";
export const FETCH_USER_ROLE_AUTH = "users/FetchUserRoleAuth";
export const FETCH_USER_DEVICES = "users/FetchUserDevices";

export const FETCH_EMPLOYMENT_STATUSES = "users/FetchEmploymentStatuses";
export const FETCH_CONTACT_US = "users/FetchContactUs";
export const FETCH_USER_USAGES = "users/FetchUserUsages";
export const FETCH_USERS_WORK_HISTORY = "users/FetchUsersWorkHistory";
export const SAVE_USERS_WORK_HISTORY = "users/SaveUsersWorkHistory";
export const FETCH_USERS_ADDRESSES = "users/FetchUserAddresses";

// Save user work history

export const SAVE_USER_WORK_HISTORY = "Users/SaveUsersWorkHistory";

export const FETCH_CONSTRUCTION_STATUSES = "misc/FetchConstructionStatuses";
export const FETCH_RECORD_STATUSES = "misc/FetchRecordStatuses";
export const FETCH_APPLICATIONS = "misc/FetchApplications"; //!
export const FETCH_COUNTRIES = "misc/FetchCountries";
export const FETCH_PROVINCES = "misc/FetchProvinces";
export const SAVE_USERS = "users/saveusers";
export const SAVE_USER_ROLE_AUTH = "users/SaveUserRoleAuth";
export const DELETE_USER_ROLE_AUTH = "users/DeleteUserRoleAuth";

// Client Api
export const FETCH_CLIENTS = "Client/FetchClients";

//Sites Api
export const FETCH_SITE_COMMPANY_ROLES = "sites/FetchSiteCompanyRoles";
export const FETCH_SITE_PROJECTS = "sites/FetchSitesProjects";

// Tickets Api
export const FETCH_TICKETS = "tickets/fetchticket";
export const FETCH_TICKET_TYPES = "tickets/fetchtickettypes";
export const SAVE_TICKET_TYPES = "tickets/saveticketTypes";
export const DELETE_TICKET_TYPES = "tickets/DeleteTicketTypes";

export const DELETE_TICKET = "tickets/deletetickets";
export const FETCH_TICKETS_SHARED_WITH = "tickets/fetchticketssharedwith";
export const SAVE_TICKETS = "tickets/SaveTicket2";
export const DELETE_TICKETS_SHARED_WITH = "Tickets/DeleteTicketsSharedWith";

//Users [Yash]
//Emails
export const FETCH_USER_EMAILS = "users/FetchUserEmails2";
export const DELETE_USER_EMAIL = "users/DeleteUserEmails";
export const SAVE_USER_EMAIL = "users/SaveUserEmails";

//Phone
export const FETCH_USER_PHONES = "users/FetchUserPhones2";
export const DELETE_USER_PHONE = "users/DeleteUserPhones";
export const SAVE_USER_PHONE = "users/SaveUserPhones";

//Questions / Quizzes
export const FETCH_USER_QUESTIONS = "Users/FetchUserQuizzes";
export const DELETE_USER_QUESTIONS = "Users/DeleteUserQuizzes";
//Answers
export const FETCH_USER_ANSWERS = "Users/FetchUserQuizAnswers";

//Chat
export const FETCH_CHAT_GROUPS = "chatgroups/fetchchatgroups";
export const SAVE_CHAT_GROUPS = "chatgroups/savechatgroups";
export const DELETE_CHAT_GROUPS = "chatgroups/deletechatgroups";

export const FETCH_CHAT_GROUPS_USERS = "chatgroups/fetchchatgroupusers";
export const SAVE_CHAT_GROUPS_USERS = "chatgroups/savechatgroupusers";
export const DELETE_CHAT_GROUPS_USERS = "chatgroups/deletechatgroupusers";

export const FETCH_CHAT_GROUPS_USERS_MESSAGES =
  "chatgroups/fetchchatgroupUserMessages";
export const FETCH_CHAT_GROUPS_MESSAGES = "chatgroups/fetchchatgroupMessages";
export const SAVE_CHAT_GROUPS_MESSAGES = "chatgroups/savechatgroupmessages";
export const DELETE_CHAT_GROUPS_MESSAGES = "chatgroups/deletechatgroupmessages";
export const SAVE_CHAT_GROUPS_USERS_MESSAGES =
  "chatgroups/savechatgroupusermessages";
export const DELETE_CHAT_GROUPS_USERS_MESSAGES =
  "chatgroups/deletechatgroupusermessages";
export const SAVE_CHAT_GROUPS_USERS_MESSAGES_PICTURE =
  "ChatGroups/SaveMessagePictures";
export const FETCH_BI_INFO_CHANNEL = "misc/FetchBIInfoChannel";
export const SAVE_PRODUCT_RATING = "misc/SaveProductRatings";
export const DELETE_PRODUCT_RATING = "misc/DeleteProductRatings";
export const SAVE_BI_INFO_CHANNEL = "Misc/CD/SaveBIInfoChannel";
export const DELETE_BI_INFO_CHANNEL = "Misc/DeleteBIInfoChannel";

//Sites
export const FETCH_SITES = "sites/FetchSites";
export const SAVE_SITE = "sites/SaveSites";
export const FETCH_SITE_NAMES = "sites/FetchSiteNames";
export const FETCH_SITES_POSTS_PICTURES = "sites/FetchSitesPostsPictures";
export const FETCH_SITE_PRODUCTS = "sites/FetchSiteProducts";
export const FETCH_USER_SITES_PROJECTS = "sites/FetchUserSitesProjects";
export const FETCH_JOP_POSTS = "sites/FetchJobPosts";
export const FETCH_SITES_TYPES = "sites/FetchSitesTypes";
export const SITE_DELETE = "sites/DeleteSites";
export const SAVE_POST_PITUTRES = "posts/cd/SavePostPicture";
export const FETCH_PROJECT_COMPANIES = "projects/FetchProjectCompanies";
export const SAVE_PROJECT_COMPANIES = "Projects/SaveProjectCompanies";
export const DELETE_PROJECT_COMPANIES = "Projects/DeleteProjectCompanies";

export const FETCH_PRODUCTS_POSTS_PICTURES = "misc/FetchProductsPostsPictures";

export const FETCH_PROJECT_PRODUCTS = "Projects/FetchProjectProducts";
export const SAVE_PROJECT_PRODUCTS = "Projects/SaveProjectProducts";
export const DELETE_PROJECT_PRODUCTS = "Projects/DeleteProjectProducts";

export const DELETE_USER_ANSWERS = "Users/DeleteUserAnswers";
//user_Experience
export const FETCH_USERS_WORKHISTORY = "users/fetchUsersWorkHistory";
export const DELETE_USER_WORKHISTORY = "users/deleteUsersWorkHistory";
export const SAVE_USER_WORKHISTORY = "users/saveUserWorkHistory";
export const FETCH_USERS_EXPERIENCES_PRODUCTS =
  "Sites/FetchUsersExperiencesProducts";
export const SAVE_USERS_EXPERIENCES_PRODUCTS =
  "Sites/SaveUsersExperiencesProducts";
export const DELETE_USER_EXPERIENCES_PRODUCTS =
  "Sites/DeleteUsersExperiencesProducts";
//contact-events
export const FETCH_CONTACT_EVENT_STATUSES = "users/FetchContactEventStatuses2";
export const FETCH_CONTACT_EVENT = "users/FetchContactEvents2";
export const DELETE_CONTACT_EVENTS = "users/DeleteContactEvents";
export const SAVE_CONTACT_EVENTS = "users/SaveContactEvents";
export const SAVE_USER_COMPANIES = "users/SaveUserCompanies";

//contact-add

export const SAVE_USER_ADDRESSES = "users/SaveUserAddresses";
export const DELETE_USER_ADDRESSES = "users/DeleteUserAddresses";

//contact-Trades
export const FETCH_USERS_TRADES = "users/FetchUserTrades";
export const SAVE_USERS_TRADES = "users/SaveUserTrades";
export const DELETE_USER_TRADES = "users/DeleteUserTrades";

//FetchResumesSharedWidth
export const FETCH_RESUMES_SHARED_WITH = "users/FetchResumesSharedWidth";
export const SAVE_RESUMES_SHARED_WITH = "users/SaveResumesSharedWidth";
export const DELETE_RESUMES_SHARED_WITH = "users/DeleteResumesSharedWidth";

//Post
export const FETCH_POSTS = "posts/fetchposts";
export const SAVE_POSTS = "posts/cd/saveposts";
export const DELETE_POSTS = "posts/deleteposts";
export const DELETE_POSTS_PICTURES = "Posts/DeletePostsPictures";

//PostComments
export const FETCH_POSTS_COMMENTS = "posts/fetchpostcomments";
export const SAVE_POSTS_COMMENTS = "posts/cd/savepostcomment";
export const DELETE_POSTS_COMMENTS = "posts/deletepostscomments";
//FollowEntities
export const FETCH_FOLLOW_ENTITIES = "misc/FetchFollowEntities";
export const SAVE_FOLLOW_ENTITIES = "misc/SaveFollowEntities";
export const DELETE_FOLLOW_ENTITIES = "misc/DeleteFollowEntities";
//TimeSheets
export const FETCH_TIMESHEETS = "Users/FetchTimeSheets";
export const DELETE_TIMESHEETS = "Users/DeleteTimeSheets";
export const SAVE_TIMESHEETS = "Users/SaveTimeSheets";

//Expenses
export const FETCH_EXPENSES = "Users/FetchExpenses";
export const SAVE_EXPENSES = "Users/SaveExpenses";
export const DELETE_EXPENSES = "Users/DeleteExpenses";
//FetchUsersResumes
export const FETCH_USERS_RESUMES = "Users/FetchUsersResumes";
export const DELETE_USERS_RESUMES = "Users/DeleteUsersResumes";
//FetchGoals
export const FETCH_GOALS = "Users/FetchGoals";
export const SAVE_GOALS = "Users/SaveGoals";
export const DELETE_GOALS = "Users/DeleteGoals";
//FetchUserCompanies
export const FETCH_USERS_COMPANIES = "users/FetchUserCompanies";
export const SAVE_USERS_COMPANIES = "companies/savecompanies2";
export const DELETE_USERS_COMPANIES = "users/DeleteUserCompanies";
//FetchUserSitesProjects
export const FETCH_USERS_SITES_PROJECTS = "sites/FetchUserSitesProjects";
export const DELETE_USERS_SITES_PROJECTS = "sites/DeleteUserSitesProjects";
export const SAVE_USERS_SITES_PROJECTS = "sites/SaveUserSitesProjects";
export const FETCH_PROJECT_COMPANIE_S = "Projects/FetchProjectCompanies2";

// Store
export const FETCH_USERS_PURCHASES = "store/FetchUserPurchases";
export const SAVE_USERS_PURCHASES = "store/SaveUserPurchases";
export const DELETE_USERS_PURCHASES = "store/DeleteUserPurchases";

// Rewards
export const FETCH_USERS_REWARDS_ACTIVITIES = "Users/GetUserRewardActivities2";
export const SAVE_REWARDS_ACTIVITIES = "misc/SaveRewardActivities";

export const DELETE_REWARDS_ACTIVITIES = "misc/DeleteRewardActivities";
export const DELETE_USERS_REWARDS_ACTIVITIES =
  "Users/DeleteUserRewardActivities";

export const SAVE_USER_REWARDS_ACTIVITIES = "Users/SaveUserRewardActivities";
export const FETCH_USERS_REWARDS_ACTIVITIE = "USERS/FetchUserRewardActivities";
//miscellane

export const SAVE_ROLE = "misc/saveRole";
export const DELETE_ROLE = "misc/deleteroles";

export const SAVE_TRADES = "misc/CD/SaveTradeAndPicture";
export const DELETE_TRADES = "misc/deletetrades";

export const FETCH_TRADES_NAME = "misc/FetchTradeNames";
export const SAVE_TRADES_NAMES = "misc/SaveTradeNames";
export const DELETE_TRADES_NAMES = "misc/DeleteTradeNames";

export const SAVE_USER_TRADES_EXPERIENCES = "Users/SaveUserTradesExperiences";
export const SAVE_USER_ROLES_EXPERIENCES = "Users/SaveUserRolesExperiences";
export const FETCH_RELETED_TRADES = "misc/FetchRelatedTrades";
export const SAVE_RELETED_TRADES = "misc/SaveRelatedTrades";
export const DELETE_RELETED_TRADES = "misc/DeleteRelatedTrades";

export const FETCH_ADS = "misc/FetchAds";
export const SAVE_ADS = "misc/SaveAds";
export const DELETE_ADS = "misc/DeleteAds";

//Quizzers
export const FETCH_QUIZZES = "Users/FetchQuizzes";
export const SAVE_QUIZZES = "Users/SaveQuizzes";
export const DELETE_QUIZZES = "Users/DeleteQuizzes";

export const FETCH_ITEMS = "MISC/FetchItems";
export const SAVE_ITEMS = "Misc/SaveItems2";
export const DELETE_ITEMS = "Misc/DeleteItems";

export const GET_ITEMS_PICTURES = "misc/getitempictures";

export const FETCH_STORE_ITEMS = "store/FetchStoreItems";
export const FETCH_STORE_ACTION_TYPES = "store/FetchStoreActionTypes";
export const SAVE_STORE_ITEMS = "store/SaveStoreItems";
export const DELETE_STORE_ITEMS = "store/DeleteStoreItems";

export const FETCH_ITEMS_PITURES = "Misc/FetchItemPictures";
export const SAVE_ITEMS_PITURES = "MISC/SaveItemPicture";
export const DELETE_ITEMS_PITURES = "Misc/DeleteItemPictures";

export const SAVE_STORE_ACTION_TYPES = "store/SaveStoreActionTypes";
export const DELETE_STORE_ACTION_TYPES = "store/DeleteStoreActionTypes";

export const FETCH_REWARD_ACTIVITIES = "misc/FetchRewardActivities";
//
export const SAVE_COMPANY_PRODUCTS = "Companies/SaveCompanyProducts";
export const DELETE_COMPANIES_COMPANIES = "companies/deletecompanies";
export const SAVE_COMPANIES_ADDRESSESS = "companies/SaveCompanyAddresses2";
export const DELETE_COMPANIES_ADDRESSESS = "companies/DeleteCompanyAddresses";

export const FETCH_COMPANY_PRODUCTS = "Companies/FetchCompanyProducts";
export const DELETE_COMPANIES_PRODUCTS = "Companies/DeleteCompanyProducts";

export const FECTH_ENTITY_ATTRIBUTES = "Users/FetchEntityAttributes";
export const SAVE_USER_DEVICES = "Users/SaveUserDevices";

//Entities
export const FETCH_ENTITIES = "USERS/FetchEntities";
export const SAVE_ENTITIES = "USERS/SaveEntities";
export const DELETE_ENTITIES = "USERS/DeleteEntities";
//Attributes
export const FETCH_ATTRIBUTES = "USERS/FetchAttributes";
export const DELETE_ATTRIBUTES = "USERS/DeleteAttributes";
export const SAVE_ATTRIBUTES = "USERS/SaveAttributes";

// entity - attributes
export const FETCH_ENTITY_ATTRIBUTES = "USERS/FetchEntityAttributes";
export const DELETE_ENTITY_ATTRIBUTES = "USERS/DeleteEntityAttributes";
export const SAVE_ENTITY_ATTRIBUTES = "USERS/SaveEntityAttributes";

export const FETCH_PROJECT_COMPANY_ROLES = "Projects/FetchProjectCompanyRoles";
export const SAVE_PROJECT_COMPANY_ROLES = "Projects/SaveProjectCompanyRoles";
export const DELETE_PROJECT_COMPANY_ROLES =
  "Projects/DeleteProjectCompanyRoles";
//!
export const GET_USER_RECORDS = `users/cd/GetUserRecords`;
export const GET_USERS_RECORDS = `users/CD/GetAllUsersRecords`;

export const DEACTIVATE_USER_ACCOUNT = "users/CD/DeactivateUserAccount";

export const FETCH_USERS_ROLES_EXPERIENCES = "Users/FetchUserRolesExperiences";
export const FETCH_USERS_TRADES_EXPERIENCES =
  "Users/FetchUserTradesExperiences";
export const DELETE_TRADES_EXPERIENCES = "Users/DeleteUserTradesExperiences";
export const DELETE_ROLES_EXPERIENCES = "Users/DeleteUserRolesExperiences";
//client
export const FETCH_ADS_CLIENTS = "Ads_Clients/Fetch_Ads_Clients";
export const SAVE_ADS_CLIENTS = "Ads_Clients/Save_Ads_Clients";
export const DELETE_ADS_CLIENTS = "Ads_Clients/Delete_Ads_Clients";
export const FETCH_DEVICES_ADS_CLIENTS = "Ads_Clients/FetchDevices_Ads_Clients";
export const FETCH_AUTH_ADS_CLIENTS = "Ads_Clients/FetchAuth_AdsClients";
export const SAVE_AUTH_ADS_CLIENTS = "Ads_Clients/SaveAuth_AdsClients";

// Marketing
export const FETCH_FEATURES = "Marketing/FetchFeatures";
export const DELETE_FEATURES = "Marketing/DeleteFeatures";
export const SAVE_FEATURES = "Marketing/SaveFeaturesAndPicture";
export const SAVE_FEATURES_PITURE = "	Marketing/SaveFeaturesPicture";

export const FETCH_FAQ = "Marketing/FetchFAQ";
export const SAVE_FAQ = "Marketing/SaveFAQ";
export const DELETE_FAQ = "Marketing/DeleteFAQ";

//Onbording
export const CREATE_NEW_ACCOUNT_3 = "Users/CD/V3/CreateAccount";
export const UPDATE_VERIFIED_DETAILS = "Users/cd/V1/UpdateVerifiedDetails";
export const SAVE_ONBOARDING_USER_EXPERIENCE =
  "Sites/SaveOnBoardingUserExperience";

//Choose sites
export const FECTH_SITE_DETAILS_BY_TYPE = "sites/SiteDetailsByType";

export const SAVE_USER_QUIZ_ANSWERS = "Users/SaveUserQuizAnswers";
//Notifiaction
export const FECTH_NOTIFICATION_LIST = "Notification/FetchNotificationList";
export const SAVE_NOTIFICATION_LIST = "Notification/SaveNotificationList";
export const DELETE_NOTIFICATION_LIST = "Notification/DeleteNotificationList";
export const FECTH_USER_NOTIFICATION_HISTORY =
  "Notification/CD/FetchNotificationHistories";

export const FECTH_USER_NOTIFICATION_HISTORY_ON_EYE =
  "Notification/CD/FetchNotificationHistoriesOnEye";

export const FECTH_NOTIFICATION_CATEGORY =
  "Notification/FetchNotificationCategories";

export const SAVE_NOTIFICATION_CATEGORY =
  "Notification/SaveNotificationCategories";

export const FECTH_NOTIFICATION_MESSAGES =
  "Notification/FetchNotificationMessages";
export const SEND_DEVICE_NOTIFICATION =
  "NOTIFICATION/SendDevicesPushNotification";

// Post Notifications
export const SAVE_POSTS_NOTIFICATION = "posts/CD/SavePosts";
export const SAVE_POSTS_NOTIFICATION_PICTURES = "posts/cd/savePostPicture";

// Products
export const FECTH_USER_PRODUCTS = "Users/FetchUserProducts";
export const FECTH_TRADE_PRODUCTS = "misc/fetchTradeProducts";
export const DELETE_TRADE_PRODUCTS = "Misc/DeleteTradeProducts2";
export const SAVE_TRADE_PRODUCTS = "misc/saveTradeProducts";

const googleApiKey = "AIzaSyDFLELJHL-t3S5DkTgHRPWVBNpg8uW76Kc";
export async function getAddress(coordinate) {
  return new Promise(function (resolve, reject) {
    let objAddress = {
      fullAddress: "",
      address: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      latitude: 0,
      longitude: 0,
    };
    let objRet = {
      objAddress: objAddress,
      message: "",
    };
    if (
      coordinate === null ||
      coordinate.latitude === null ||
      coordinate.longitude === null
    ) {
      objRet.message = "Something wrong with GPS coordinates !";
      resolve(objRet);
    }
    fetch(
      `https://maps.google.com/maps/api/geocode/json?latlng=${coordinate.latitude},${coordinate.longitude}&sensor=true&key=${googleApiKey}`,
      {
        method: "GET",
        compress: true,
        headers: {
          Accept: "application/json",
          // 'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.status !== "OK" && json.status !== "ZERO_RESULTS") {
          objRet.message = JSON.stringify(json.status);
          resolve(objRet);
        }
        let addressString = "",
          address = "",
          city = "",
          province = "",
          country = "",
          postalcode = "",
          lat = 0,
          long = 0;
        lat = coordinate.latitude;
        long = coordinate.longitude;
        objAddress.latitude = lat;
        objAddress.longitude = long;
        if (json.results.length > 0) {
          if (json.results[0].address_components.length > 0) {
            for (
              let i = 0;
              i < json.results[0].address_components.length;
              i++
            ) {
              let x = json.results[0].address_components[i];
              if (x.types[0] === "street_number") address += x.long_name + " ";
              if (x.types[0] === "route") address += x.long_name + " ";
              if (x.types[0] === "locality" || x.types[0] === "postal_town")
                city = x.long_name;
              if (x.types[0] === "administrative_area_level_1")
                province = x.short_name;
              if (x.types[0] === "country") country = x.long_name;
              if (x.types[0] === "postal_code" || x.types[1] === "postal_code")
                postalcode = x.long_name;
            }
            addressString = json.results[0].formatted_address;
            objAddress = {
              address: address,
              city: city,
              province: province,
              country: country,
              postalCode: postalcode,
              latitude: lat,
              longitude: long,
              fullAddress: addressString,
            };
            objRet.objAddress = objAddress;
          }
        }
        resolve(objRet);
      })
      .catch((error) => {
        reject({
          statusCode: 403,
          message: "Server not responding ! " + error.message,
        });
      });
  });
}
