import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FETCH_COMPANIES, FETCH_POSTS } from "../constant/Apipath";
import { PostRequestCall } from "../apicall/PostRequest";
import { IconComponent } from "./Icon";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import { AutoComplete } from "@progress/kendo-react-dropdowns";

const ManufacturerInput = (props) => {
  const debounceTimeout = useRef(null);
  const { loginData } = useSelector((state) => state.main);

  const [options, setOptions] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getList("");
  }, []);

  const getList = async (seacrhTerm) => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [{ name: seacrhTerm }, { manufacturer: true }],
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      };
      const res = await PostRequestCall(FETCH_COMPANIES, obj, loginData?.token);
      let resData = res?.data?.data ?? [];
      let data = resData.map((record) => ({
        id: record?.id,
        label: record?.name,
      }));
      setSearchLoader(false);
      setOptions(data ?? []);
    } catch (error) {
      setSearchLoader(false);
      console.log("Error while getting user list :: ", error);
    }
  };

  return (
    <>
      {props?.title && <Label className="form-heading">Manufacturer:</Label>}

      <div style={{ position: "relative" }}>
        <AutoComplete
          data={options}
          value={props?.value}
          className="field-ip"
          placeholder="Search for a manufacturer"
          onChange={async (event) => {
            const searchTerm = event.target.value;
            setSearchLoader(true);
            props?.setSelectedManufacturer({
              label: event.target.value,
              id: 0,
            });
            if (searchTerm) {
              setOpen(true);
              if (props?.open) {
                props?.open(true);
              }
              if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
              }
              debounceTimeout.current = setTimeout(() => {
                getList(event.target.value);
              }, 1000);
            } else {
              if (props?.open) {
                props?.open(false);
              }
              setOpen(false);
            }
          }}
          textField="label"
          opened={open}
          onBlur={() => {
            if (props?.open) {
              props?.open(false);
            }
            setOpen(false);
          }}
          itemRender={(li, itemProps) => {
            const data = itemProps?.dataItem;
            return (
              <div
                className="user-item"
                onClick={() => {
                  setSearchLoader(false);
                  props?.setSelectedManufacturer(data);
                  setOpen(false);
                  if (props?.open) {
                    props?.open(false);
                  }
                }}>
                <span>{data?.label}</span>
              </div>
            );
          }}
        />
        {props?.value?.toString()?.trim() === "" && (
          <div style={{ position: "absolute", top: "20%", right: "15px" }}>
            <IconComponent size={15} iconName={"Search"} color="#B5B5B5" />
          </div>
        )}
        {searchLoader && props?.value?.toString()?.trim() !== "" && (
          <div
            style={{
              position: "absolute",
              top: "15%",
              right: "2%",
              cursor: "pointer",
              zIndex: 999,
              backgroundColor: "white",
            }}>
            <Loader size={"small"} style={{ color: "#083DB8" }} />
          </div>
        )}
      </div>
    </>
  );
};

export default ManufacturerInput;
