import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_POSTS_COMMENTS,
  FETCH_POSTS_COMMENTS,
  SAVE_POSTS_COMMENTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import UserSearchInput from "../../common/UserSearchInput";
import PostIdSearchInput from "../../common/PostIdSearchInput";
import RecordStatusInput from "../../common/RecordStatusInput";

const AddNewPostComment = () => {
  const { dataId, userId, postId, commentId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = commentId === "add-post-comment";

  const [postComments, setPostComments] = useState({
    userName: "",
    userId: userId ?? 0,
    comment: "",
    appId: "",
    postId: postId ?? 0,
    pictureUrl: null,
    recordStatus: "New",
    recordStatusId: 1,
    alreadyFileName: null,
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [footerInfo, setFooterInfo] = useState({});

  useEffect(() => {
    if (!isAddForm) {
      getPostCommentDetails();
    }
  }, []);

  const getPostCommentDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: commentId }] };
      const fetchPostComments = await PostRequestCall(
        FETCH_POSTS_COMMENTS,
        obj,
        loginData?.token
      );
      let resData = fetchPostComments?.data?.data?.[0] ?? {};
      setPostComments((preValue) => ({
        ...preValue,
        userName: resData?.userName ?? "",
        userId: resData?.userId ?? 0,
        postId: resData?.postId?.toString() ?? "",
        appId: resData?.appId?.toString() ?? "",
        comment: resData?.comment ?? "",
        pictureUrl: resData?.pictureUrl ?? null,
        alreadyFileName: resData?.pictureUrl ?? null,
        recordStatus: resData?.recordStatus ?? "New",
        recordStatusId: resData?.recordStatusId ?? 1,
      }));
      let footerObj = {
        dateCreated: resData?.dateCreated ?? null,
        modifiedByUser: resData?.modifiedByUser ?? "",
        dateModified: resData?.dateModified ?? "",
        createdByUser: resData?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    } catch (error) {
      console.log("Error while getting post comment details :: ", error);
    }
  };

  const saveCurrentData = async () => {
    if (postComments?.userId === 0) {
      toast.error("Please select a valid user", {
        position: "top-right",
      });
      return;
    }

    if (postComments?.postId === 0) {
      toast.error("Please select a valid post ", {
        position: "top-right",
      });
      return;
    }
    if (postComments?.comment === "") {
      toast.error("Please enter a valid post comment", {
        position: "top-right",
      });
      return;
    }

    setShowLoader(true);
    const fd = new FormData();
    fd.append("Id", isAddForm ? 0 : commentId);
    fd.append("userId", postComments?.userId);
    fd.append("PostId", postComments?.postId);
    fd.append("AppId", postComments?.appId);
    fd.append("Comment", postComments?.comment);
    // fd.append("PictureUrl", postComments?.pictureUrl || "");

    if (postComments?.pictureUrl) {
      if (postComments?.pictureUrl !== postComments?.alreadyFileName) {
        fd.append("files", postComments?.pictureUrl);
      }
    } else {
      fd.append("ForceRemovePicture", true);
    }

    fd.append("RecordStatusId", postComments?.recordStatusId || 1);
    fd.append(
      "ModifiedBy",
      isAddForm ? postComments?.userId : loginData?.userId
    );
    // if (isAddForm) {
    //   fd.append("createdBy", postComments?.userId);
    // }

    let getResponse = await PostRequestCall(
      SAVE_POSTS_COMMENTS,
      fd,
      loginData?.token,
      "formData"
    );
    if (getResponse?.data?.status === false) {
      setShowLoader(false);
      toast.error(
        getResponse?.data?.data?.[0]?.message ??
        "Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
    } else {
      toast.success(`Data ${isAddForm ? "added" : "updated"} successfully`, {
        position: "top-right",
      });
      window.history.back();
      setShowLoader(false);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = [
        {
          Id: Number(dataId),
          ModifiedBy: loginData?.userId,
        },
      ];
      const obj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: deleteObj,
      };
      return PostRequestCall(DELETE_POSTS_COMMENTS, obj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  const onInputChange = (e) => {
    let inputValue = e.target.value;
    setPostComments({
      ...postComments,
      [e.target.name]: inputValue,
    });
    setIsFiledEdited(true);
  };

  const onSelectProfile = (e) => {
    setIsFiledEdited(true);
    e.preventDefault();
    document.getElementById("user-profile").click();
  };

  const isCheck = (pictureUrl) => {
    if (Object.keys(pictureUrl)?.length === 0) {
      return URL.createObjectURL(pictureUrl);
    } else {
      return pictureUrl;
    }
  };

  const onImageChange = (e) => {
    setIsFiledEdited(true);
    if (e?.target?.files?.length > 0) {
      setPostComments({
        ...postComments,
        pictureUrl: e.target.files[0],
      });
    }
    e.target.value = "";
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_95">
        <AddPageLayout
          dataId={commentId}
          addForm={isAddForm}
          showFooter={!isAddForm}
          footerInfo={footerInfo}
          isFieldEdited={isFieldEdited}
          recordText={"Post comment ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}>
          <div style={{ height: "100%" }}>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <UserSearchInput
                      value={postComments?.userName}
                      setSelectedUser={({ label, id }) => {
                        setIsFiledEdited(true);
                        setPostComments((preValue) => ({
                          ...preValue,
                          userName: label,
                          userId: id,
                        }));
                      }}
                      disabled={userId}
                      userId={userId}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <PostIdSearchInput
                      value={postComments?.postId}
                      setSelectedPostId={({ label, id, appId }) => {
                        setIsFiledEdited(true);
                        setPostComments((preValue) => ({
                          ...preValue,
                          postId: label,
                          appId: appId,
                        }));
                      }}
                      disabled={postId}
                      postId={postId}
                      setAddId={(appID) => {
                        setPostComments((preValue) => ({
                          ...preValue,
                          appId: appID,
                        }));
                      }}
                    />
                  </GridLayoutItem>
                  {/* <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">Post ID:</Label>
                    <Input
                      type="text"
                      name="appId"
                      value={postComments?.postId}
                      disabled
                    />
                  </GridLayoutItem> */}
                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <Label className="form-heading">App ID:</Label>
                    <Input
                      type="text"
                      name="appId"
                      value={postComments?.appId}
                      disabled
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <Label className="form-heading"> Select Image:</Label>
                    <div className="k-d-flex" style={{ gap: "15px" }}>
                      <input
                        type="file"
                        hidden
                        accept=".png,.jpg"
                        id="user-profile"
                        style={{ display: "none" }}
                        onChange={(e) => onImageChange(e)}
                      />
                      {postComments?.pictureUrl ? (
                        <div className="k-d-flex">
                          <div style={{ position: "relative" }}>
                            <img
                              className="user-img"
                              src={isCheck(postComments?.pictureUrl)}
                              alt="Ad"
                            />
                            <div className="close-icon">
                              <IconComponent
                                onClick={() => {
                                  setPostComments((preValue) => ({
                                    ...preValue,
                                    pictureUrl: null,
                                  }));
                                }}
                                color={"white"}
                                iconName={"X"}
                                size={15}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <GridLayoutItem
                            col={2}
                            row={1}
                            className="form-inner">
                            <div
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #d3d3d3",
                                padding: "5px",
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: "4px",
                                gap: "10px",
                              }}
                              onClick={onSelectProfile}>
                              <IconComponent iconName={"Image"} size={20} />
                              <span className="add-file-text">
                                Choose image
                              </span>
                            </div>
                          </GridLayoutItem>
                        </>
                      )}
                    </div>
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <div className="form-group">
                      <Label className="form-heading">Comment:</Label>
                      <div>
                        <TextArea
                          type="text"
                          id="comment"
                          name="comment"
                          value={postComments?.comment}
                          className="field-ip"
                          onChange={onInputChange}
                          rows={4}
                        />
                      </div>
                    </div>
                  </GridLayoutItem>

                  {!isAddForm && (
                    <GridLayoutItem col={3} row={2} className="form-inner">
                      <RecordStatusInput
                        recordStatusId={postComments?.recordStatusId}
                        recordStatus={postComments?.recordStatus}
                        onChange={({ recordStatus, recordStatusId }) => {
                          setIsFiledEdited(true);
                          setPostComments((preValue) => ({
                            ...preValue,
                            recordStatus,
                            recordStatusId,
                          }));
                        }}
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default AddNewPostComment;
