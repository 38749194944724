// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
// import { useSelector } from "react-redux";
// import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
// import { Label } from "@progress/kendo-react-labels";
// import { toast } from "react-toastify";
// import { Loader } from "@progress/kendo-react-indicators";
// import { Form } from "@progress/kendo-react-form";
// import { DELETE_USER_WORKHISTORY, FETCH_USERS_WORK_HISTORY } from "../constant/Apipath";
// import UserSiteProjectSearchInput from "../common/UserSiteProjectSearchInput";
// import { PostRequestCall } from "../apicall/PostRequest";
// import UserSearchInput from "../common/UserSearchInput";
// import RecordStatusInput from "../common/RecordStatusInput";
// import AddPageLayout from "../addpagelayout/AddPageLayout";
// import CompnaySearchInput from "../common/CompanySearchInput";
// import ProjectCompanyRoleSearchInput from "../common/ProjectCompanyRoleSearchInput";
// import RoleSearchInput from "../common/RoleSearchInput";
// import TradeSearchInput from "../common/TradeSearchInput";

// const Adduserworkhistory = () => {
//   const { dataId, subMenu, userId } = useParams();
//   const { loginData } = useSelector((state) => state.main);
//   const isAddForm = dataId === "add-user-work-history";

//   const [workHistory, setWorkHistory] = useState({
//     userName: "",
//     userId: userId ?? 0,
//     startDate: null,
//     endDate: null,
//     projectId: 0,
//     projectName: "",
//     companyId: 0,
//     companyName: "",
//     projectCompanyRoleId: 0,
//     projectCompanyRoleName: "",
//     roleId: 0,
//     roleName: "",
//     tradeId: 0,
//     tradeName: "",
//     present: false,
//     includeInResume: false,
//     description: "",
//     products: [],
//     recordStatusId: 1,
//     recordStatus: "New",
//   });

//   const [showLoader, setShowLoader] = useState(false);
//   const [isFieldEdited, setIsFiledEdited] = useState(false);

//   useEffect(() => {
//     if (!isAddForm) {
//       getUserWorkHistoryDetails();
//     }
//   }, []);

//   const getUserWorkHistoryDetails = async () => {
//     let obj = { SearchList: [{ Id: dataId }] };

//     const result = await PostRequestCall(FETCH_USERS_WORK_HISTORY, obj, loginData?.token);
//     let data = result?.data?.data?.[0] ?? null;
//     setWorkHistory((prevData) => ({
//       ...prevData,
//       userName: data?.userName ?? "",
//       userId: data?.userId ?? userId ?? 0,
//       startDate: data?.startDate ? new Date(data?.startDate) : null,
//       endDate: data?.endDate ? new Date(data?.endDate) : null,
//       projectId: data?.projectId ?? 0,
//       projectName: data?.projectName ?? "",
//       companyId: data?.companyId ?? 0,
//       companyName: data?.companyName ?? "",
//       projectCompanyRoleId: data?.projectCompanyRoleId ?? 0,
//       projectCompanyRoleName: data?.projectCompanyRoleName ?? "",
//       roleId: data?.roleId ?? 0,
//       roleName: data?.roleName ?? "",
//       tradeId: data?.tradeId ?? 0,
//       tradeName: data?.tradeName ?? "",
//       present: data?.present ?? false,
//       includeInResume: data?.includeInResume ?? false,
//       description: data?.description ?? "",
//       recordStatusId: data?.recordStatusId ?? 1,
//       recordStatus: data?.recordStatus ?? "New",
//     }));
//   };

//   const onInputChange = (e, type) => {
//     let inputValue = e.target.value;
//     setIsFiledEdited(true);
//     setWorkHistory({ ...workHistory, [e.target.name]: inputValue });
//   };


//   const saveCurrentData = async () => {

//     // if (post?.userId === 0) {
//     //   return toast.error("Please select a valid user", { position: "top-right" });
//     // }
//     // if (post?.appId === 0) {
//     //   return toast.error("Please select a valid app", { position: "top-right" });
//     // }

//     // if (post.message?.trim() === "") {
//     //   return toast.error("Please enter a valid message", { position: "top-right" });
//     // }


//     // const obj = {
//     //   UserId: loginData?.userId,
//     //   ReturnRecordId: true,
//     //   ReturnRecordError: true,
//     //   SaveList: [
//     //     {
//     //       Id: isAddForm ? 0 : postId,
//     //       FormatTypeId: post.formatTypeId || 1,
//     //       PostedByUserId: post?.userId,
//     //       AppId: post?.appId ?? 0,
//     //       RecordStatusId: post?.recordStatusId || 1,
//     //       AppRecordId: post?.appRecordId || 0,
//     //       Message: post?.message,
//     //       ModifiedBy: isAddForm ? post?.userId : loginData?.userId,
//     //     },
//     //   ],
//     // };

//     // setShowLoader(true);

//     // try {
//     //   const result = await PostRequestCall(SAVE_POSTS, obj, loginData.token);

//     //   if (result.data.status === false) {
//     //     toast.error(
//     //       result?.data?.message ??
//     //       "Something went wrong. Please try again later.",
//     //       { position: "top-right" }
//     //     );
//     //     setShowLoader(false);
//     //   } else {
//     //     toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
//     //       position: "top-right",
//     //     });
//     //     if (userId) {
//     //       navigate(`/user/accounts/users/${userId}/user-post`)
//     //     } else {
//     //       navigate('/user/post/posts')
//     //     }
//     //   }
//     // } catch (error) {
//     //   setShowLoader(false);
//     //   toast.error("Something went wrong. Please try again later.", {
//     //     position: "top-right",
//     //   });
//     //   console.error("Error saving data:", error);
//     // }
//   };

//   const onDeleteClick = async () => {
//     try {
//       setShowLoader(true);
//       const deleteObj = {
//         ReturnRecordError: true,
//         ReturnRecordId: false,
//         UserId: loginData.userId,
//         DeleteList: [
//           {
//             Id: dataId,
//             ModifiedBy: loginData.userId,
//           },
//         ],
//       };
//       return PostRequestCall(DELETE_USER_WORKHISTORY, deleteObj, loginData?.token)
//         .then((res) => {
//           setShowLoader(false);
//           if (res?.data?.status) {
//             toast.success("Data deleted successfully", {
//               position: "top-right",
//             });
//             window.history.back();
//           } else {
//             toast.error(
//               res?.data?.message ??
//               "Something went wrong. Please try again later.",
//               {
//                 position: "top-right",
//               }
//             );
//           }
//         })
//         .catch((error) => {
//           setShowLoader(false);
//           toast.error("Something went wrong. Please try again later.", {
//             position: "top-right",
//           });
//           console.log("Error while deleting a record :: ", error);
//         });
//     } catch (error) {
//       setShowLoader(false);
//       toast.error("Something went wrong. Please try again later.", {
//         position: "top-right",
//       });
//       console.log("Error while deleting a record :: ", error);
//     }
//   };


//   if (showLoader) {
//     return (
//       <Loader
//         type="converging-spinner"
//         className="kendo-spinner"
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           minHeight: "500px",
//           alignItems: "center",
//         }}
//       />
//     );
//   }

//   return (
//     <div className="add_form_height_95">
//       <AddPageLayout
//         dataId={dataId}
//         addForm={isAddForm}
//         showFooter={false}
//         isFieldEdited={isFieldEdited}
//         footerInfo={{}}
//         recordText={"User Work History"}
//         saveCurrentData={saveCurrentData}
//         onDeleteClick={onDeleteClick}>
//         <div style={{ width: "100%" }}>
//           <Form
//             render={() => (
//               <GridLayout
//                 rows={[
//                   { height: 70 },
//                   { height: 70 },
//                   { height: 70 },
//                   { height: 70 },
//                   { height: 30 },
//                 ]}
//                 cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
//                 gap={{ rows: 30, cols: 35 }}
//                 style={{ padding: "16px" }}>
//                 <GridLayoutItem col={1} row={1} className="form-inner">
//                   <UserSearchInput
//                     value={workHistory?.userName}
//                     setSelectedUser={({ label, id }) => {
//                       setIsFiledEdited(true);
//                       setWorkHistory({
//                         ...workHistory,
//                         userName: label,
//                         userId: id,
//                       });
//                     }}
//                     disabled={!isAddForm || userId}
//                     userId={userId}
//                   />
//                 </GridLayoutItem>
//                 <GridLayoutItem col={2} row={1} className="form-inner">
//                   <Label className="form-heading">Start Date:</Label>
//                   <DatePicker
//                     id="startDate"
//                     value={workHistory?.startDate}
//                     name="startDate"
//                     format={"dd/MM/yyyy"}
//                     className="field-ip"
//                     onChange={onInputChange}
//                   />
//                 </GridLayoutItem>

//                 <GridLayoutItem col={3} row={1} className="form-inner">
//                   {!workHistory?.present && (
//                     <>
//                       <Label className="form-heading">End Date:</Label>
//                       <DatePicker
//                         id="endDate"
//                         value={workHistory?.endDate}
//                         name="endDate"
//                         format={"dd/MM/yyyy"}
//                         className="field-ip"
//                         onChange={onInputChange}
//                       />
//                     </>
//                   )}
//                 </GridLayoutItem>

//                 <GridLayoutItem
//                   col={1}
//                   row={2}
//                   className="form-inner">
//                   <UserSiteProjectSearchInput
//                     value={workHistory?.projectName?.toString()}
//                     userId={workHistory?.userId}
//                     setSelectedProject={({ label, id }) => {
//                       setIsFiledEdited(true);
//                       setWorkHistory({
//                         ...workHistory,
//                         projectName: label,
//                         projectId: id,
//                       });
//                     }}
//                   />
//                 </GridLayoutItem>

//                 <GridLayoutItem col={2} row={2} className="form-inner">
//                   <CompnaySearchInput
//                     value={workHistory?.companyName}
//                     setSelectedCompany={({ label, id }) => {
//                       setIsFiledEdited(true);
//                       setWorkHistory((preValue) => ({
//                         ...preValue,
//                         companyName: label,
//                         companyId: id,
//                       }));
//                     }}
//                   />
//                 </GridLayoutItem>

//                 <GridLayoutItem col={3} row={2} className="form-inner">
//                   <ProjectCompanyRoleSearchInput
//                     value={workHistory?.projectCompanyRoleName}
//                     setSelectedProjectCompanyRole={({ label, id }) => {
//                       setIsFiledEdited(true);
//                       setWorkHistory((preValue) => ({
//                         ...preValue,
//                         projectCompanyRoleName: label,
//                         projectCompanyId: id,
//                       }));
//                     }}
//                   />
//                 </GridLayoutItem>

//                 <GridLayoutItem col={1} row={3} className="form-inner">
//                   <RoleSearchInput
//                     value={workHistory?.roleName}
//                     setSelectedRole={({ label, id }) => {
//                       setIsFiledEdited(true);
//                       setWorkHistory((preValue) => ({
//                         ...preValue,
//                         roleName: label,
//                         roleId: id,
//                       }));
//                     }}
//                   />
//                 </GridLayoutItem>

//                 <GridLayoutItem col={2} row={3} className="form-inner">
//                   <TradeSearchInput
//                     value={workHistory?.tradeName}
//                     setSelectedTrade={({ label, id }) => {
//                       setIsFiledEdited(true);
//                       setWorkHistory((preValue) => ({
//                         ...preValue,
//                         tradeName: label,
//                         tradeId: id,
//                       }));
//                     }}
//                   />
//                 </GridLayoutItem>

//                 {!isAddForm || subMenu ? (
//                   <GridLayoutItem col={5} row={2} className="form-inner">
//                     <RecordStatusInput
//                       recordStatusId={workHistory?.recordStatusId}
//                       recordStatus={workHistory?.recordStatus}
//                       onChange={({ recordStatus, recordStatusId }) => {
//                         setIsFiledEdited(true);
//                         setWorkHistory((preValue) => ({
//                           ...preValue,
//                           recordStatus,
//                           recordStatusId,
//                         }));
//                       }}
//                     />
//                   </GridLayoutItem>
//                 ) : (
//                   <></>
//                 )}
//               </GridLayout>
//             )}
//           />
//         </div>
//       </AddPageLayout>
//     </div>
//   );
// };

// export default Adduserworkhistory;



import React, { useEffect, useState } from "react";
import KendoButton from "../common/KendoButton";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { AutoComplete, MultiSelect } from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { Form } from "@progress/kendo-react-form";
import { PostRequestCall } from "../apicall/PostRequest";
import { toast } from "react-toastify";
import {
  DELETE_USER_WORKHISTORY,
  FETCH_COMPANIES,
  FETCH_PRODUCTS,
  FETCH_PROJECT_COMPANY_ROLES,
  FETCH_ROLES,
  FETCH_TRADES,
  FETCH_USERS_WORK_HISTORY,
  FETCH_USER_SITES_PROJECTS,
  FetchUsers2,
  SAVE_USER_WORK_HISTORY,
} from "../constant/Apipath";
import { useSelector } from "react-redux";
import { Switch, TextArea } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../common/Modal";
import moment from "moment";

const Adduserworkhistory = () => {
  const { dataId, subMenu, userId } = useParams();
  const navigate = useNavigate();

  const { loginData } = useSelector((state) => state.main);

  const [userOptions, setUseroptions] = useState([]);
  const [selectedUser, setSelecteduser] = useState(null);

  const [projectOptions, setProjectoptions] = useState([]);
  const [selectProject, setSelectproject] = useState(null);

  const [companyRoleOptions, setCompanyroleOptions] = useState([]);
  const [selectCompanyrole, setSelectCompanyrole] = useState(null);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectCompany, setSelectCompany] = useState(null);

  const [roleOptions, setRoleoptions] = useState([]);
  const [selectRole, setSelectrole] = useState(null);

  const [tradeOptions, setTradeoptions] = useState([]);
  const [selectTrade, setSelecttrade] = useState(null);

  const [productOption, setProductOptions] = useState([]);
  const [selectProducts, setSelectProducts] = useState([]);
  const [filterProducts, setFilterproducts] = useState("");

  const [isFieldEdited, setIsFiledEdited] = useState(false);

  const [openUser, setOpenuser] = useState(false);
  const [openProject, setOpenproject] = useState(false);
  const [openCompany, setOpencompany] = useState(false);
  const [openCompanyrole, setOpencompanyrole] = useState(false);
  const [openRole, setOpenrole] = useState(false);
  const [openTrade, setOpentrade] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [workHistory, setWorkhistory] = useState({
    id: 0,
    startDate: new Date(),
    endDate: new Date(),
    present: false,
    includeInResume: false,
    description: "",
  });

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });

  useEffect(() => {
    getProductdata(false);
    if (dataId !== "add-user-work-history") {
      // At the time of user work history edit
      fetchUserExperience();
    }
    if (userId && dataId === "add-user-work-history") {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (selectedUser !== null && typeof selectedUser?.value === "undefined") {
      if (selectedUser !== "") {
        getUsers(false);
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectProject !== null && typeof selectProject?.value === "undefined") {
      if (selectProject !== "") {
        getProjectlist(false);
      }
    }
  }, [selectProject]);

  useEffect(() => {
    if (selectCompany !== null && typeof selectCompany?.value === "undefined") {
      if (selectCompany !== "") {
        getCompanylist(false);
      }
    }
  }, [selectCompany]);

  useEffect(() => {
    if (
      selectCompanyrole !== null &&
      typeof selectCompanyrole?.value === "undefined"
    ) {
      if (selectCompanyrole !== "") {
        getCompanyrole(false);
      }
    }
  }, [selectCompanyrole]);

  useEffect(() => {
    if (selectRole !== null && typeof selectRole?.value === "undefined") {
      if (selectRole !== "") {
        getRoleList(false);
      }
    }
  }, [selectRole]);

  useEffect(() => {
    if (selectTrade !== null && typeof selectTrade?.value === "undefined") {
      if (selectTrade !== "") {
        getTradelist(false);
      }
    }
  }, [selectTrade]);

  useEffect(() => {
    if (filterProducts !== "") {
      getProductdata(true);
    }
  }, [filterProducts]);

  const getUserDetails = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(FetchUsers2, data, loginData?.token);
      setSelecteduser({ value: userId, label: result?.data?.data?.[0]?.name });
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  };

  const fetchUserExperience = async () => {
    try {
      let getObject = {
        SearchList: [{ id: dataId }],
      };
      const getResult = await PostRequestCall(
        FETCH_USERS_WORK_HISTORY,
        getObject,
        loginData?.token
      );
      if (getResult?.status === 201) {
        if (getResult?.data?.data?.length > 0) {
          let getUserwork = getResult?.data?.data[0];
          setWorkhistory({
            ...workHistory,
            startDate: getUserwork?.startDate
              ? new Date(getUserwork?.startDate)
              : null,
            endDate:
              getUserwork?.endDate === null
                ? null
                : new Date(getUserwork?.endDate),
            includeInResume: getUserwork?.includeInResume,
            present: getUserwork?.present,
            description: getUserwork?.description,
          });
          setSelecteduser({
            value: getUserwork?.userId,
            label: getUserwork?.userName,
          });
          setSelectproject({
            value: getUserwork?.projectId,
            label: getUserwork?.projectName,
          });
          setSelectCompany({
            value: getUserwork?.companyId,
            label: getUserwork?.companyName,
          });
          setSelectCompanyrole({
            value: getUserwork?.projectCompanyRoleId,
            label: getUserwork?.projectCompanyRoleName,
          });
          setSelectrole({
            value: getUserwork?.roleId,
            label: getUserwork?.roleName,
          });
          setSelecttrade({
            value: getUserwork?.tradeId,
            label: getUserwork?.tradeName,
          });
          let getProductlist = getUserwork?.experienceProductList.map(
            (item) => {
              return { value: item?.productId, label: item?.productName };
            }
          );
          setSelectProducts(getProductlist ?? []);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const getUsers = async (onFocus) => {
    try {
      const getData = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: selectedUser }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FetchUsers2,
        getData,
        loginData?.token
      );
      if (getResult?.data?.data?.length > 0) {
        const userOptions = getResult?.data?.data.map((user) => ({
          value: user.id,
          label: `${user?.firstName} ${user?.lastName}`,
        }));
        setUseroptions(userOptions);
        setOpenuser(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getProjectlist = async (onFocus) => {
    try {
      let obj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
        SearchList: onFocus
          ? [{ UserId: selectedUser?.value }]
          : [{ UserId: selectedUser?.value, projectName: selectProject }],
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
      };
      const fetchSiteProjectListRes = await PostRequestCall(
        FETCH_USER_SITES_PROJECTS,
        obj,
        loginData?.token
      );
      if (fetchSiteProjectListRes?.data?.data?.length > 0) {
        const getList = fetchSiteProjectListRes?.data?.data.map((project) => ({
          value: project?.id,
          label: project?.projectName,
        }));
        setProjectoptions(getList);
        setOpenproject(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCompanylist = async (onFocus) => {
    try {
      let companyObj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: selectCompany }],
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        IncludeRecordNr: true,
      };
      const companyRes = await PostRequestCall(
        FETCH_COMPANIES,
        companyObj,
        loginData?.token
      );
      let companyData = companyRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setCompanyOptions(companyData ?? []);
      setOpencompany(true);
    } catch (error) {
      console.log("Error while getting company list :: ", error);
    }
  };

  const getCompanyrole = async (onFocus) => {
    try {
      let getObject = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData?.userId,
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: onFocus ? [] : [{ name: selectCompanyrole }],
        SortList: [],
      };
      let getList = await PostRequestCall(
        FETCH_PROJECT_COMPANY_ROLES,
        getObject,
        loginData?.token
      );
      let companyRole = getList?.data?.data?.map((role) => ({
        value: role?.id,
        label: role?.name,
      }));
      setCompanyroleOptions(companyRole?.length > 0 ? companyRole : []);
      setOpencompanyrole(true);
    } catch (error) { }
  };

  const getRoleList = async (onFocus) => {
    try {
      let roleObj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        SearchList: onFocus ? [] : [{ name: selectRole }],
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      };
      const roleObjsRes = await PostRequestCall(
        FETCH_ROLES,
        roleObj,
        loginData?.token
      );
      let data = roleObjsRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setRoleoptions(data ?? []);
      setOpenrole(true);
    } catch (error) {
      console.log("Error while getting role list :: ", error);
    }
  };

  const getTradelist = async (onFocus) => {
    try {
      var getData = JSON.stringify({
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        SearchList: onFocus ? [] : [{ name: selectTrade }],
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FETCH_TRADES,
        getData,
        loginData?.token
      );
      let updatedData = getResult?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setTradeoptions(updatedData ?? []);
      setOpentrade(true);
    } catch (error) {
      console.error("Error fetching Trade:", error);
    }
  };

  const getProductdata = async (onFocus, searchTerm) => {
    try {
      const obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: searchTerm }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FETCH_PRODUCTS,
        obj,
        loginData?.token
      );
      if (getResult?.data?.data?.length > 0) {
        const list = getResult?.data?.data.map((item) => ({
          value: item.id,
          label: item?.name,
        }));

        if (selectProducts?.length > 0) {
          const filteredProductOptions = list.filter(
            (data) => !ifProductSelected(data)
          );
          setProductOptions(filteredProductOptions);
        } else {
          setProductOptions(list);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const ifProductSelected = (data) => {
    return selectProducts?.some(
      (selectedProduct) => selectedProduct?.value === data?.value
    );
  };
  const onInputChange = (e) => {
    setWorkhistory({
      ...workHistory,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event) => {
    setSelecteduser(event?.target?.value);
  };

  const onProjectchange = (event) => {
    setSelectproject(event?.target?.value);
  };

  const onCompanychange = (event) => {
    setSelectCompany(event?.target?.value);
  };

  const onCompanyrolechange = (event) => {
    setSelectCompanyrole(event?.target?.value);
  };

  const onRolechange = (event) => {
    setSelectrole(event?.target?.value);
  };

  const onTradechange = (event) => {
    setSelecttrade(event?.target?.value);
  };

  const onProductchange = (event) => {
    const newValue = event?.value;
    setSelectProducts(newValue);
  };

  const onProductfilterchange = (event) => {
    setFilterproducts(event.filter.value);
  };

  const userSelect = async (user) => {
    setOpenuser(false);
    setSelecteduser(user);
  };

  const projectSelect = (project) => {
    setSelectproject(project);
    setOpenproject(false);
  };

  const companySelect = (company) => {
    setSelectCompany(company);
    setOpencompany(false);
  };

  const onCompanyroleSelect = (companyRole) => {
    setSelectCompanyrole(companyRole);
    setOpencompanyrole(false);
  };

  const roleSelect = (role) => {
    setSelectrole(role);
    setOpenrole(false);
  };

  const tradeSelect = (trade) => {
    setSelecttrade(trade);
    setOpentrade(false);
  };

  const productsSelect = (products) => {
    setSelectProducts(products);
  };

  const onKeyget = (e) => {
    console.log("e-------->", e);
    if (e.key === "Enter") {
      console.log("Enter pressed :: ");
    } else {
      console.log("Not pressed Enter key");
    }
  };

  const onUserRender = (li, itemProps) => {
    const user = itemProps?.dataItem;
    return (
      <div
        className="user-item"
        onClick={() => userSelect(user)}
        onKeyDown={(e) => onKeyget(e)}>
        <span>{user?.label}</span>
      </div>
    );
  };

  const onProjectRender = (li, itemProps) => {
    const project = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => projectSelect(project)}>
        <span>{project?.label}</span>
      </div>
    );
  };

  const onCompanyRender = (li, itemProps) => {
    const company = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => companySelect(company)}>
        <span>{company?.label}</span>
      </div>
    );
  };

  const onCompanyroleRender = (li, itemProps) => {
    const companyRole = itemProps.dataItem;
    return (
      <div
        className="user-item"
        onClick={() => onCompanyroleSelect(companyRole)}>
        <span>{companyRole?.label}</span>
      </div>
    );
  };

  const onRolerender = (li, itemProps) => {
    const role = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => roleSelect(role)}>
        <span>{role?.label}</span>
      </div>
    );
  };

  const onTraderender = (li, itemProps) => {
    const trade = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => tradeSelect(trade)}>
        <span>{trade?.label}</span>
      </div>
    );
  };

  const formatDateForSavingData = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date.getDate().toString().padStart(2, "0");
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  };

  const saveCurrentdata = async () => {
    if (
      selectedUser?.value === undefined ||
      selectProject?.value === undefined ||
      selectCompany?.value === undefined ||
      selectCompanyrole?.value === undefined
    ) {
      toast.error(
        "Please Select Fields: User, Project, Company and Company Role.",
        {
          position: "top-right",
        }
      );
      return;
    }

    setShowLoader(true);
    let getProducts = selectProducts?.map((item) => item?.value).join(",");
    let getObject = {
      ReturnRecordId: true,
      ReturnRecordError: true,
      UserId: 8,
      SaveList: [
        {
          id: dataId !== "add-user-work-history" ? dataId : 0,
          userId: selectedUser?.value,
          startDate: formatDateForSavingData(workHistory?.startDate),
          endDate: workHistory?.present
            ? ""
            : formatDateForSavingData(workHistory?.endDate),
          present: workHistory?.present,
          includeInResume: workHistory?.includeInResume,
          projectId: selectProject?.value,
          description: workHistory?.description,
          projectCompanyRoleId: selectCompanyrole?.value,
          companyId: selectCompany?.value,
          roleId: selectRole?.value,
          tradeId: selectTrade?.value,
          isFollowing: dataId !== "add-user-work-history" ? false : true,
          productIdList: getProducts,
        },
      ],
    };
    let getResponse = await PostRequestCall(
      SAVE_USER_WORK_HISTORY,
      getObject,
      loginData?.token
    );
    setShowLoader(false);
    if (getResponse?.status === 201) {
      toast.success(
        `Data ${dataId !== "add-user-work-history" ? "updated" : "added"
        } successfully`,
        {
          position: "top-right",
        }
      );
      window.history.back();
    }
  };

  const onModalClose = () => {
    window.history.back();
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      saveCurrentdata();
    } else {
      return null;
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: [{ Id: Number(dataId), ModifiedBy: loginData?.userId }],
      };

      PostRequestCall(DELETE_USER_WORKHISTORY, deleteObj, loginData?.token)
        .then(async (res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch(async (error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <section className="add-user">
        <>
          <section className="user-information">
            <div className="k-d-flex k-justify-content-between">
              <div className="k-d-flex k-align-items-center">
                {dataId !== "add-user-work-history" && (
                  <span class="record-id-text">
                    User Work History: {dataId}
                  </span>
                )}
              </div>
              <div
                className="k-d-flex k-justify-content-end k-p-2"
                style={{ gap: "10px" }}>
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  themeColor={"primary"}
                  buttonName="Save"
                  onClick={saveCurrentdata}
                />
                {dataId !== "add-user-work-history" && (
                  <KendoButton
                    size={16}
                    className=""
                    onClick={showDeleteConfirmation}
                    buttonName="Delete"
                    themeColor={"secondary"}
                  />
                )}
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  buttonName="Cancel"
                  onClick={onCancelClick}
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #f5f5f5",
                width: "100%",
              }}></div>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 80,
                    },
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 25,
                    cols: 35,
                  }}
                  style={{ padding: "25px 16px", gap: "20px 35px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">User :</Label>
                    <AutoComplete
                      data={userOptions}
                      onFocus={() => getUsers(true)}
                      onClose={() => setOpenuser(false)}
                      onBlur={() => setOpenuser(false)}
                      opened={openUser}
                      value={selectedUser ? selectedUser?.label : ""}
                      className="field-ip"
                      itemRender={onUserRender}
                      onChange={(e) => handleChange(e)}
                      textField="label"
                      placeholder="Search for users..."
                      disabled={dataId !== "add-user-work-history" || subMenu}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">Start Date :</Label>
                    <DatePicker
                      id="startDate"
                      value={workHistory?.startDate}
                      name="startDate"
                      format={"dd/MM/yyyy"}
                      className="field-ip"
                      onChange={onInputChange}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={1} className="form-inner">
                    {!workHistory?.present && (
                      <>
                        <Label className="form-heading">End Date :</Label>
                        <DatePicker
                          id="endDate"
                          value={workHistory?.endDate}
                          name="endDate"
                          format={"dd/MM/yyyy"}
                          className="field-ip"
                          onChange={onInputChange}
                        />
                        <Error></Error>
                      </>
                    )}
                  </GridLayoutItem>
                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <Label className="form-heading">Project :</Label>
                    <AutoComplete
                      data={projectOptions}
                      onFocus={() => getProjectlist(true)}
                      onClose={() => setOpenproject(false)}
                      onBlur={() => setOpenproject(false)}
                      opened={openProject}
                      value={selectProject ? selectProject?.label : ""}
                      itemRender={onProjectRender}
                      className="field-ip"
                      onChange={onProjectchange}
                      textField="label"
                      placeholder="Select project..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <Label className="form-heading">Company :</Label>
                    <AutoComplete
                      data={companyOptions}
                      onFocus={() => getCompanylist(true)}
                      onClose={() => setOpencompany(false)}
                      onBlur={() => setOpencompany(false)}
                      opened={openCompany}
                      value={selectCompany ? selectCompany?.label : ""}
                      className="field-ip"
                      itemRender={onCompanyRender}
                      onChange={onCompanychange}
                      textField="label"
                      placeholder="Select comapny..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={2} className="form-inner">
                    <Label className="form-heading">
                      Project Company Role :
                    </Label>
                    <AutoComplete
                      data={companyRoleOptions}
                      onFocus={() => getCompanyrole(true)}
                      onClose={() => setOpencompanyrole(false)}
                      onBlur={() => setOpencompanyrole(false)}
                      opened={openCompanyrole}
                      value={selectCompanyrole ? selectCompanyrole?.label : ""}
                      className="field-ip"
                      itemRender={onCompanyroleRender}
                      onChange={onCompanyrolechange}
                      textField="label"
                      placeholder="Select project company role..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={1} row={3} className="form-inner">
                    <Label className="form-heading">Role :</Label>
                    <AutoComplete
                      data={roleOptions}
                      onFocus={() => getRoleList(true)}
                      onClose={() => setOpenrole(false)}
                      onBlur={() => setOpenrole(false)}
                      opened={openRole}
                      value={selectRole ? selectRole?.label : ""}
                      className="field-ip"
                      itemRender={onRolerender}
                      onChange={onRolechange}
                      textField="label"
                      placeholder="Select role..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={3} className="form-inner">
                    <Label className="form-heading">Trade :</Label>
                    <AutoComplete
                      data={tradeOptions}
                      onFocus={() => getTradelist(true)}
                      onClose={() => setOpentrade(false)}
                      onBlur={() => setOpentrade(false)}
                      opened={openTrade}
                      value={selectTrade ? selectTrade?.label : ""}
                      itemRender={onTraderender}
                      className="field-ip"
                      onChange={onTradechange}
                      textField="label"
                      placeholder="Select trade..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={3} className="form-inner">
                    <Label className="form-heading">Products :</Label>
                    <MultiSelect
                      data={productOption}
                      onOpen={() => getProductdata(false)}
                      onChange={onProductchange}
                      value={selectProducts}
                      onFilterChange={onProductfilterchange}
                      filter={filterProducts}
                      className="field-ip"
                      placeholder="Please select..."
                      textField="label"
                      style={{
                        maxHeight: "80px",
                        overflowY: "auto",
                      }}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem
                    col={1}
                    row={4}
                    className="form-inner k-d-flex k-flex-column">
                    <Label className="form-heading">Include In Resume :</Label>
                    <Switch
                      size={"small"}
                      checked={workHistory?.includeInResume}
                      name="includeInResume"
                      onChange={onInputChange}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem
                    col={2}
                    row={4}
                    className="form-inner k-d-flex k-flex-column">
                    <Label className="form-heading">Present :</Label>
                    <Switch
                      size={"small"}
                      checked={workHistory?.present}
                      name="present"
                      onChange={onInputChange}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={4} className="form-inner">
                    <Label className="form-heading">Description :</Label>
                    <TextArea
                      type="text"
                      id="description"
                      name="description"
                      value={workHistory?.description}
                      className="field-ip"
                      onChange={onInputChange}
                      rows={4}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={1} row={6} className="form-inner">
                    <div className="k-d-flex" style={{ gap: "10px" }}>
                      <KendoButton
                        iconClass=""
                        size={16}
                        themeColor={"primary"}
                        buttonName="Save"
                        onClick={saveCurrentdata}
                      />
                      {dataId !== "add-user-work-history" && (
                        <KendoButton
                          size={16}
                          className=""
                          onClick={showDeleteConfirmation}
                          buttonName="Delete"
                          themeColor={"secondary"}
                        />
                      )}
                      <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        buttonName="Cancel"
                        onClick={onCancelClick}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </section>
        </>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </>
  );
};

export default Adduserworkhistory;
