import React, { useEffect, useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { IconComponent } from "../../common/Icon";
import { Loader } from "@progress/kendo-react-indicators";
import { useNavigate } from "react-router-dom";
import { Chip } from "@progress/kendo-react-buttons";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import KendoButton from "../../common/KendoButton";
import {
  DELETE_TRADE_PRODUCTS,
  FETCH_TRADES,
  SAVE_PRODUCTS,
  SAVE_TRADE_PRODUCTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import RecordStatusInput from "../../common/RecordStatusInput";
import { toast } from "react-toastify";
import ManufacturerInput from "../../common/ManufacturerInput";

const ProductProfileSection = ({
  productDetails,
  tradeList,
  callUpdatedProdcutData,
}) => {
  let navigate = useNavigate();
  const { loginData } = useSelector((state) => state.main);
  const [productEditValues, setProductEditValues] = useState({
    name: "",
    webSite: "",
    info: "",
    recordStatus: "",
    recordStatusId: "",
    manufacturerName: "",
    manufacturerId: 0,
  });

  const [editTrades, setEditTrades] = useState([]);
  const [isProductFiledEdited, setIsProductFiledEdited] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tradeOptions, setTradeOptions] = useState([]);

  useEffect(() => {
    getTradeList();
  }, []);

  useEffect(() => {
    if (showEditForm) {
      resetValuesForEditProduct();
    }
  }, [showEditForm]);

  const resetValuesForEditProduct = () => {
    setProductEditValues({
      name: productDetails?.name,
      webSite: productDetails?.productWebSite,
      info: productDetails?.info ?? "",
      recordStatus: productDetails?.recordStatus,
      recordStatusId: productDetails?.recordStatusId,
      manufacturerId: productDetails?.manufacturerId,
      manufacturerName: productDetails?.manufacturerName,
    });
    let data = tradeList?.map((record) => ({
      id: record?.tradeId,
      label: record?.tradeName,
    }));

    setEditTrades(data ?? []);
    setIsProductFiledEdited(false);
    setSubmitLoading(false);
  };

  const getTradeList = async (seacrhTerm) => {
    try {
      let tradeObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: seacrhTerm ?? "",
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      };
      const tradeObjRes = await PostRequestCall(
        FETCH_TRADES,
        tradeObj,
        loginData?.token
      );
      let data = tradeObjRes?.data?.data?.map((record) => ({
        id: record?.id,
        label: record?.name,
      }));
      setTradeOptions(data ?? []);
    } catch (error) {
      console.log("Error while getting trade list :: ", error);
    }
  };

  const formatDate = (dateString) => {
    if (dateString) {
      const options = { month: "short", day: "numeric", year: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    } else {
      return null;
    }
  };

  const onFilterChangeOfTrade = (e) => {
    getTradeList(e?.filter?.value);
  };

  const onInputChange = (e) => {
    setIsProductFiledEdited(true);
    setProductEditValues({
      ...productEditValues,
      [e.target.name]: e.target.value,
    });
  };

  const onInfoInputChange = (e) => {
    setIsProductFiledEdited(true);
    let inputValue = e.target.value;
    setProductEditValues({
      ...productEditValues,
      [e.target.name]: inputValue,
    });
    setIsProductFiledEdited(true);
  };

  const onCancelClick = () => {
    setShowEditForm(false);
  };

  const validateURL = (url) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const wwwUrlRegex = /^www\.[^\s/$.?#].[^\s]*$/i;
    if (url.trim() === "") return false;
    return urlRegex.test(url.trim()) || wwwUrlRegex.test(url.trim());
  };

  const checkWantToSaveProductData = async () => {
    if (productEditValues?.name?.trim() === "") {
      toast.error("Please enter a valid company name.", {
        position: "top-right",
      });
      return false;
    }

    if (productEditValues?.manufacturerId === 0) {
      toast.error("Please select a valid manufacturer", {
        position: "top-right",
      });
      return;
    }

    return true;
  };

  const checkTradeListChanges = (existingList, updatedList) => {
    let updatedExistingList = [...existingList]?.map((item) => {
      return { id: item?.tradeId, label: item?.tradeName };
    });
    const addedTrades = updatedList.filter(
      (updated) =>
        !updatedExistingList.some((existing) => existing.id === updated.id)
    );
    const deletedTrades = updatedExistingList.filter(
      (existing) => !updatedList.some((updated) => updated.id === existing.id)
    );
    return { addedTrades, deletedTrades };
  };

  const saveData = async (api, saveObject) => {
    try {
      await PostRequestCall(api, saveObject, loginData?.token);
    } catch (error) {
      console.error(`Error while saving data to ${api} :: `, error);
      setSubmitLoading(false);
      showEditForm(false);
      return null;
    }
  };

  const saveProductDetails = async () => {
    const saveObject = {
      userId: Number(loginData?.userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: productDetails?.id,
          Name: productEditValues?.name,
          ManufacturerId: productEditValues?.manufacturerId,
          Website: productEditValues?.webSite,
          info: productEditValues?.info,
          RecordStatusId: productEditValues?.recordStatusId || 1,
          ModifiedBy: Number(loginData?.userId),
        },
      ],
    };

    await saveData(SAVE_PRODUCTS, saveObject);
  };

  const saveUpdatedTradeList = async (newlyAddedTrades) => {
    const SaveList = newlyAddedTrades.map((item) => ({
      Id: 0,
      ProductId: productDetails?.id,
      TradeId: item.id,
      ModifiedBy: Number(loginData?.userId),
    }));
    const saveObject = {
      userId: Number(loginData?.userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList,
    };
    await saveData(SAVE_TRADE_PRODUCTS, saveObject);
  };

  const deleteProductTradeList = async (deleteTradeList) => {
    const DeleteList = deleteTradeList?.map((item) => ({
      ProductId: productDetails?.id,
      TradeId: item.id,
    }));
    const saveObject = {
      userId: Number(loginData?.userId),
      ReturnRecordError: true,
      SoftDelete: true,
      DeleteList,
    };
    await saveData(DELETE_TRADE_PRODUCTS, saveObject);
  };

  const onSaveClick = async (e) => {
    try {
      e.preventDefault();

      let updateCompanyDetails = false;
      let updateTradeDetails = false;
      let deleteTradeList = false;

      if (isProductFiledEdited) {
        updateCompanyDetails = await checkWantToSaveProductData();
        if (!updateCompanyDetails) return null;
      }

      const { addedTrades: newlyAddedTrades, deletedTrades: deletedTradeList } =
        checkTradeListChanges(tradeList, editTrades);
      if (newlyAddedTrades.length > 0) updateTradeDetails = true;
      if (deletedTradeList.length > 0) deleteTradeList = true;

      setSubmitLoading(true);

      // Save or delete data based on changes
      if (updateCompanyDetails) await saveProductDetails();
      if (updateTradeDetails) await saveUpdatedTradeList(newlyAddedTrades);
      if (deleteTradeList) await deleteProductTradeList(deletedTradeList);

      callUpdatedProdcutData(
        updateCompanyDetails,
        updateTradeDetails || deleteTradeList
      );
      setShowEditForm(false);
    } catch (error) {
      console.log("Error while updating company details :: ", error);
      setSubmitLoading(false);
      showEditForm(false);
    }
  };

  const renderEditForm = () => {
    return (
      <>
        <div className="ce_container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <div style={{ width: "60%" }}>
              <Input
                name="name"
                placeholder="Enter a valid company name"
                value={productEditValues?.name}
                className="field-ip"
                onChange={onInputChange}
              />
            </div>
            <div
              style={{
                width: "40%",
                display: "flex",
                justifyContent: "end",
                gap: "10px",
              }}>
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
              {submitLoading ? (
                <div className="action-btn-loader">
                  <Loader type={"pulsing"} themeColor="white" size="small" />
                </div>
              ) : (
                <KendoButton
                  iconClass=""
                  size={16}
                  className="action-btn"
                  onClick={onSaveClick}
                  buttonName="Save"
                />
              )}
            </div>
          </div>

          <div className="ce_edit_container" style={{ alignItems: "start" }}>
            <div className="ce_edit_label">
              <p>Manufacturer</p>
            </div>
            <div className="ce_edit_input">
              <ManufacturerInput
                title={false}
                value={productEditValues?.manufacturerName}
                setSelectedManufacturer={({ label, id }) => {
                  setIsProductFiledEdited(true);
                  setProductEditValues((preValue) => ({
                    ...preValue,
                    manufacturerName: label,
                    manufacturerId: id,
                  }));
                }}
              />
            </div>
          </div>
          <div className="ce_edit_container" style={{ alignItems: "start" }}>
            <div className="ce_edit_label">
              <p>Trade(s)</p>
            </div>
            <div className="ce_edit_input">
              <MultiSelect
                data={tradeOptions}
                value={editTrades}
                onChange={(e) => {
                  setEditTrades(e.value);
                }}
                filterable={true}
                onFilterChange={onFilterChangeOfTrade}
                placeholder="Please select trade..."
                textField="label"
                style={{ height: "100px", overflow: "auto" }}
                className="ce_trade_input"
              />
            </div>
          </div>
          <div className="ce_edit_container">
            <div className="ce_edit_label">
              <p>Website</p>
            </div>
            <div className="ce_edit_input">
              <Input
                name="webSite"
                placeholder="Enter a valid website url"
                value={productEditValues?.webSite}
                className="field-ip"
                onChange={onInputChange}
              />
            </div>
          </div>
          <div className="ce_edit_container">
            <div className="ce_edit_label">
              <p>Info</p>
            </div>
            <div className="ce_edit_input">
              <TextArea
                type="text"
                id="info"
                name="info"
                value={productEditValues?.info}
                className="field-ip"
                onChange={onInfoInputChange}
                rows={4}
              />
            </div>
          </div>
          <div className="ce_edit_container">
            <div className="ce_edit_label">
              <p>Record Status</p>
            </div>
            <div className="ce_edit_input">
              <RecordStatusInput
                hideLabel={true}
                noExtraWidth={true}
                recordStatusId={productEditValues?.recordStatusId}
                recordStatus={productEditValues?.recordStatus}
                onChange={({ recordStatus, recordStatusId }) => {
                  setIsProductFiledEdited(true);
                  setProductEditValues((preValue) => ({
                    ...preValue,
                    recordStatus,
                    recordStatusId,
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderProductDetails = () => {
    return (
      <>
        <div
          style={{ width: "100%", marginTop: "35px", overflow: "hidden" }}
          className="about_user_report_conatiner">
          <div className="user_report_about_card">
            <div className="company_title_container">
              <h4>{productDetails?.name}</h4>
              <p
                onClick={() => {
                  setShowEditForm(true);
                }}>
                Edit
              </p>
            </div>
            <div
              style={{
                // display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
                flexWrap: "wrap",
              }}>
              <div className="company_description_sub_container">
                <p className="company_des_label">Manufacturer</p>

                {productDetails?.manufacturerName?.trim() === "" ?
                  (
                    <p className="company_des_value">
                      ---
                    </p>
                  )
                  :
                  (
                    <Chip
                      text={productDetails?.manufacturerName}
                      type="info"
                      style={{ padding: "2px", lineHeight: "15px" }}
                    />
                  )
                }
              </div>
              <div style={{ marginTop: "10px" }}>
                <div className="company_description_sub_container">
                  <p className="company_des_label">Trades</p>
                  {
                    tradeList?.length === 0 ?
                      (
                        <p className="company_des_value">
                          ---
                        </p>
                      )
                      :
                      (
                        <div
                          style={{
                            gap: "10px",
                            display: "flex",
                            flexWrap: "wrap",
                          }}>
                          {tradeList?.map((trade, index) => (
                            <Chip
                              key={index}
                              text={trade?.tradeName}
                              type="info"
                              style={{
                                padding: "2px",
                                lineHeight: "20px",
                              }}
                            />
                          ))}
                        </div>
                      )
                  }

                </div>
              </div>
            </div>
            <div>
              <div style={{ marginTop: "10px" }}>
                <div className="company_description_sub_container">
                  <p className="company_des_label">Website</p>
                  <p className="company_des_value rust_text">
                    {productDetails?.productWebSite !== ""
                      ? productDetails?.productWebSite
                      : "---"}
                  </p>
                </div>
                <div className="company_description_sub_container">
                  <p className="company_des_label">Info</p>
                  <p className="company_des_value">
                    {productDetails?.info !== "" ? productDetails?.info : "---"}
                  </p>
                </div>
                <div className="company_description_sub_container">
                  <p className="company_des_label">Record Status</p>
                  <p className="company_des_value">
                    {productDetails?.recordStatus ?? "--"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="user_report_description_card">
            <ul className="account-details">
              <p style={{ fontSize: "13px" }}>
                Last modified by {productDetails?.modifiedByUser ?? "---"} on{" "}
                {formatDate(productDetails?.dateModified ?? null)}
              </p>
              <p style={{ fontSize: "13px" }}>
                Created by {productDetails?.createdByUser ?? "---"} on{" "}
                {formatDate(productDetails?.dateCreated ?? null)}
              </p>
            </ul>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Card
        style={{
          padding: "10px 10px",
          height: "89vh",
          position: "relative",
          // overflow: "scroll",
          width: "98%",
        }}>
        <div
          style={{
            // padding: "5px",
            fontWeight: "bold",
            color: "#116fa5",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "10px",
            position: "fixed",
            // marginTop: '20px'
          }}>
          <IconComponent
            iconName={"ArrowLeft"}
            size={15}
            color="#116fa5"
            onClick={() => {
              navigate("/products/products");
            }}
          />
          <p
            style={{
              fontSize: "14px",
              cursor: "pointer",
              color: "#116fa5",
              fontWeight: "500",
            }}
            onClick={() => {
              navigate("/products/products");
            }}>
            Back to all products
          </p>
        </div>

        {showEditForm ? renderEditForm() : renderProductDetails()}
      </Card>
    </>
  );
};

export default ProductProfileSection;
